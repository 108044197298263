import { listCandidates } from '../../services/api/candidates';
import { LIST_CANDIDATES, LIST_CANDIDATES_LOADING_ERROR } from './actionTypes';
import store from '../store';

export const getListCandidates = () => {
  listCandidates()
    .then((res) => {
      store.dispatch({
        type: LIST_CANDIDATES,
        payload: res,
      });
      store.dispatch({
        type: LIST_CANDIDATES_LOADING_ERROR,
        payload: false,
      });
    })
    .catch((err) => {
      store.dispatch({
        type: LIST_CANDIDATES_LOADING_ERROR,
        payload: true,
      });
      console.error(err);
      throw new Error(err);
    });
};
