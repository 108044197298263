import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@yardstik/core.components';
import { GraphQLClient, ClientContext } from 'graphql-hooks';
import packageInfo from '../package.json';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from './contexts/auth0/auth0-context';
import store from './redux/store';
import App from './App';
import './index.css';

datadogLogs.init({
  applicationId: '741286e9-ce0e-4720-a5f2-0d5434dcf3f3',
  clientToken: 'pubcdfa08d0a6e86e383e5db8faec9b06e3',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: process.env.REACT_APP_DD_ENV,
  version: packageInfo.version,
  service: 'core-profile'
});

datadogRum.init({
  applicationId: '741286e9-ce0e-4720-a5f2-0d5434dcf3f3',
  clientToken: 'pubcdfa08d0a6e86e383e5db8faec9b06e3',
  site: 'datadoghq.com',
  service: 'core-profile',
  env: process.env.REACT_APP_DD_ENV,
  version: packageInfo.version,
  sampleRate: Number(process.env.REACT_APP_DD_SAMPLE_RATE) || 100,
  sessionReplaySampleRate: Number(process.env.REACT_APP_DD_REPLAY_SAMPLE_RATE) || 100,
  trackResources: true,
  trackLongTasks: true,
  trackInteractions: true,
  trackFrustrations: true,
  useCrossSiteSessionCookie: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [/https:\/\/(?!login).*\.yardstik\.com/, /https:\/\/(?!login).*\.yardstik-staging\.com/]
});

datadogRum.startSessionReplayRecording();

const client = new GraphQLClient({
  url: `${process.env.REACT_APP_API_URL}/candidate-profile/graphql`
});

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider>
      <ClientContext.Provider value={client}>
        <Provider store={store}>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </Provider>
      </ClientContext.Provider>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
