import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Monitor from '../Monitor';
import { ICandidateMonitors } from '../CandidateDetails/candidateUtils';
import * as S from './style';

export type MonitorListProps = {
  candidateId: string;
  monitors: ICandidateMonitors[];
};
const MonitorList = (props: MonitorListProps): JSX.Element => {
  const { candidateId = '', monitors = [] } = props;
  return (
    <div data-testid={'monitorList'}>
      <S.SectionTitle>
        Monitors{' '}
        <Tooltip title="Below is a list of your active, or previously active, monitors." placement="right">
          <IconButton aria-label="delete">
            <HelpOutlineIcon color={'action'} />{' '}
          </IconButton>
        </Tooltip>
      </S.SectionTitle>
      {monitors.length > 0 &&
        monitors.map((monitor, index: number) => {
          return <Monitor monitor={monitor} key={index} candidateId={candidateId} />;
        })}
    </div>
  );
};

export default MonitorList;
