import SupportContact from '../../SupportContact';
import { useReportView } from '../index.context';

export function Support() {
  const { report, candidate, setOpenReportProblemModal } = useReportView();

  const disputable = report?.disputable ?? false;
  const accountName = report?.account?.account_name;
  const supportEmailAddress = report?.account?.support_email_address;

  return (
    <SupportContact
      customerName={accountName}
      showReportErrorButton={disputable}
      customerEmail={supportEmailAddress}
      supportMailSubject={`Questions about report: <${report.id}> - ${candidate?.full_name}`}
      reportErrorCallback={() => setOpenReportProblemModal(true)}
    />
  );
}
