import {get} from 'lodash';

export const defaultErrorMessage = 'There were problems processing your document.';

// const getError = (isValid: boolean, glareFree: boolean, inFocus: boolean) => {
const getError = (isValid: boolean) => {
  if (isValid) {
    return '';
  }
  // disabled for now
  // if (!glareFree) {
  //   return 'Image has too much glare.'
  // }
  // if (!inFocus) {
  //   return 'Image is out of focus.' 
  // }
  return defaultErrorMessage;
};

type ValidDocType = {
  isValid: boolean,
  error: string,
}

const isValidDocument = (input: any, regexString?: string):ValidDocType => {
  try {
    const error = get(input, '[0].error');
    // return early if error found
    if (error) {
      return {
        isValid: false,
        error,
      };
    }
    const documentType = get(input, '[0].model.type');
    const regex = new RegExp(regexString || '');
    const documentPassedRegex = regex.test(documentType);
    // disable these for now
    //
    // const isGlareFree = (get(input, '[0].features.properties.isGlareFree') === 'Yes')
    // const isInFocus = (get(input, '[0].features.properties.isInFocus') === 'Yes')
    
    // const confidence = get(input, '[0].model.confidence', 0)
    // const isConfident = confidence >= .80;
    // has to pass regex test
    // and a confidence of .80 or more.
    // image needs to be glare free and be in focus
    // const isValid = documentPassedRegex && isConfident && isGlareFree && isInFocus
    const isValid = documentPassedRegex;
    return {
      isValid,
      // error: getError(isValid, isGlareFree, isInFocus)
      error: getError(isValid)
    };  
  } catch (error) {
    return {
      isValid: false,
      error: defaultErrorMessage
    };
  }
  
};

export default isValidDocument;
