import { useSelector } from 'react-redux';
import { ReportType } from '@yardstik/core.components';

import { RootState } from '../../../../redux/store';

type Report = {
  identity_verification_id: string | null;
  info_request: {
    id: string;
    status: string;
  } | null;
} & ReportType;

export function useScreening() {
  const report = useSelector((state: RootState) => state.reports.reportDetail ?? {}) as Report;

  const featureFlagsReportScreeningsSearchDetails = useSelector(
    (state: RootState) =>
      state.launchDarkly.byAccountId['profile-screenings-search-details-rollout-enabled']
        .report_screenings_search_details_flags ?? {}
  );

  const { candidate, report_url: reportUrl = '', report_screenings: reportScreenings = [] } = report;

  const isScreenDetailsEnabled = (screeningName = '') =>
    featureFlagsReportScreeningsSearchDetails[screeningName] !== false;

  const hasScreenings = report && reportScreenings && reportScreenings.length > 0;

  return {
    candidate,
    reportUrl,
    hasScreenings,
    reportScreenings,
    isScreenDetailsEnabled
  };
}
