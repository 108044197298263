import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef, useState, Component } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Link as RouterLink, LinkProps as RouterLinkProps, useParams } from 'react-router-dom';
import { Omit } from '@material-ui/types';
import { get, uniq } from 'lodash';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import { AlertSnackbar, Loader, FormElements } from '@yardstik/core.components';
import CandidateApply from '../CandidateApply';
import { reOrderPayFirst } from '../../redux/candidateApply/formSections/actions';
import { setCurrentStep } from '../../redux/candidateApply/actions';
import { setCandidateApplicationLoading } from '../../redux/candidateApply/controls/actions';
import { removeImageFromForm } from '../../redux/candidateApply/formValues/actions';
import { CandidateToSend } from '../CandidateApply/candidateApplyInterfaces';
import { getApplicationData } from './../../redux/candidateApply/actions';
import { skipToStep } from '../CandidateApply/candidateApplyUtilsBeta';
import { updateCandidateReportData } from '../../services/api/candidates';
import { stripePayment } from '../../services/api/stripe';
import { processFiles } from '../../services/api/reports';
import useFileUpload from './useFileUpload';
import DocumentProcessing from './documentProcessing';
import store from '../../redux/store';
import {
  updateToken,
  updateTopic,
  setResetCallbacks as setResetCallbacksAction,
  setResetPayloads,
  setProcessErrors as setProcessErrorsAction,
  setDocumentRegexTest,
  setPatchUrl,
  setCandidate,
  setFormName,
  setLoading
} from '../../redux/documentVerify/documentVerifySlice';
import usePrevious from '../../utils/usePrevious';
import CandidateApplyFeedbackPage from '../CandidateApplyFeedbackPage';
import './styles.scss';
import { updateReportStep } from '../../services/api/reports';
import { updateCandidate } from '../../services/api/candidates';

import { useAuth0 } from '../../contexts/auth0/auth0-context';
import { useAuthHelper } from '../../hooks/auth-helper/useAuthHelper';
import { getCandidateApplyApiPath } from '../../services/auth/authHelpers';

import { useSendVerificationEmail } from '../../services/auth/hooks/useSendVerificationEmail';
import { useSendPasscode } from '../../services/auth/hooks/useSendPasscode';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string');

const useStyles = makeStyles({
  root: {
    '& .MuiSnackbar-root .MuiAlert-message': {
      paddingRight: '16px'
    },
    '& .MuiSnackbar-root .MuiButtonBase-root': {
      position: 'absolute',
      top: '2px',
      right: '2px'
    }
  }
});

const getStep = (name: string) => {
  try {
    const steps = get(store.getState(), 'candidate_application.formSections.steps');
    if (steps && steps.length) {
      const step = steps.find((s: any) => {
        const formName = get(s, 'formName');
        return formName === name;
      });
      return step;
    }
    return {};
  } catch (error) {
    return {};
  }
};

type ApplicationType = {
  account_id?: string;
  paid?: boolean;
  pii?: boolean;
  paid_by?: 'account' | 'candidate';
  account?: {
    account_name?: string;
  };
};

const InitialReportApply = (props: any) => {
  const { applyWithAuthentication = false, applyWithAuth0 = false, payFirst = false, applyWithSMS = false } = props;

  // Get auth0 context
  const { isAuthenticated } = useAuth0();
  const { isAuthenicationError, handleAuthenicationError } = useAuthHelper();

  const { sendVerificationEmail } = useSendVerificationEmail();
  const { sendPasscode } = useSendPasscode();

  const [searchParams, setSearchParams] = useState({
    account_package_id: '',
    report_id: ''
  });
  const search = get(props, 'history.location.search', '');

  const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
  const imagesProcessed = useRef(false);
  const candidateResponse = useRef<any>(undefined);
  const candidateResponseReturned = useRef<any>(undefined);
  const classes = useStyles(props);

  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [additionalDataWithPii, setAdditionalDataWithPii] = useState<object>({});

  //getting search params and setting to local state
  useEffect(() => {
    const parsedSearchParams = search ? queryString.parse(search) : {};
    setSearchParams(parsedSearchParams);
  }, [search]);

  const params = get(props, 'match.params', {});
  const { candidate_id: candidateId = '' } = params;
  const { account_package_id: accountPackageId = '', report_id: reportId = '' } = searchParams;
  const pageLoading = useSelector(state => get(state, 'controls.loading', false));
  const accountPackageName = useSelector(state =>
    get(state, 'candidate_application.application.account_package.name', '')
  );
  const application = useSelector(state => get(state, 'candidate_application.application', {})) as ApplicationType;
  const formSections = useSelector(state => get(state, 'candidate_application.formSections', {}));
  const currentStep = useSelector(state => get(state, 'candidate_application.controls.currentStep', 0));
  const savedStep = useSelector(state =>
    get(state, 'candidate_application.application.report.application_data.current_step')
  );
  const screenings = useSelector(state =>
    get(state, 'candidate_application.application.workflow_steps.screenings', [])
  );
  const candidateEmail = useSelector(state => get(state, 'candidate_application.candidate.email', ''));
  const candidatePhoneVerified = useSelector(state => get(state, 'candidate_application.candidate.phone_verified', ''));
  const candidateEmailVerified = useSelector(state => get(state, 'candidate_application.candidate.email_verified', ''));
  const steps = useSelector(state => get(formSections, 'steps', []));
  const isLoading = useSelector(state => get(state, 'documentVerify.isLoading', false));
  const loadingText = useSelector(state => get(state, 'documentVerify.loadingText', ''));
  const { account_id: accountId = '', paid = false, pii = false, paid_by = 'account' } = application;
  const accountName = application?.account?.account_name || '';
  const dispatch = useDispatch();
  const documentStatus = useSelector(state => get(state, 'documentVerify.status', ''));
  const previousDocumentStatus = usePrevious(documentStatus);

  // Only pass the report_id in the payload if it exists and the user in on the intake steps
  useEffect(() => {
    const additionalData = {
      report_id: reportId
    };

    if (savedStep && reportId) {
      setAdditionalDataWithPii(additionalData);
    }
  }, [savedStep, reportId]);

  // if in auth0 flow, the phone or email has been verified to login
  // update the candidate to lock the applicable field
  useEffect(() => {
    if (isAuthenticated) {
      if (applyWithSMS && !candidatePhoneVerified) {
        try {
          updateCandidate(
            candidateId,
            { phone_verified: true, skip_middle_name_validation: true },
            dispatch,
            additionalDataWithPii
          );
        } catch (err) {
          throw err;
        }
      }
      if (!applyWithSMS && !candidateEmailVerified) {
        try {
          updateCandidate(
            candidateId,
            { email_verified: true, skip_middle_name_validation: true },
            dispatch,
            additionalDataWithPii
          );
        } catch (err) {
          throw err;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add pageViews for GoogleAnalytics for each step in form
  useEffect(() => {
    const stepName = get(steps[currentStep], 'formName', '');
    ReactGA.pageview(`candidate-apply/${stepName}`);
  }, [currentStep, steps]);

  // Add accountId for GoogleAnalytics for tracking purposes
  useEffect(() => {
    if (accountId) {
      ReactGA.set({ dimension1: accountId });
    }
  }, [accountId]);

  // Add accountName for GoogleAnalytics for tracking purposes
  useEffect(() => {
    if (accountName) {
      ReactGA.set({ dimension2: accountName });
    }
  }, [accountName]);

  // Add accountPackageName for GoogleAnalytics for tracking purposes
  useEffect(() => {
    if (accountPackageName) {
      ReactGA.set({ dimension3: accountPackageName });
    }
  }, [accountPackageName]);

  //Set applyRoute based on flow
  const applyRoute = getCandidateApplyApiPath(isAuthenticated, candidateId, reportId);

  // creates redirect link to send to courses component if there is training to complete
  const coursesLink = `/candidates/${candidateId}/courses`;

  const redirectLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
    <RouterLink ref={ref} to={coursesLink} {...props} />
  ));

  const fetchApplicationData = useCallback(async () => {
    if (reportId && candidateId && accountPackageId) {
      const applicationData = {
        report_id: reportId,
        accountPackageId: accountPackageId,
        isService: true,
        payFirst: payFirst,
        isAdditionalInfo: false,
        skipEmailVerification: (applyWithAuth0 || isAuthenticated) && !applyWithSMS
      };
      try {
        await getApplicationData(applicationData, dispatch, applyRoute);
      } catch (err) {
        if ((err as { type: string })?.type === 'IntakeTreatmentError') {
          setLoadingError(true);
          return;
        }

        if (isAuthenicationError(candidateId)) {
          handleAuthenicationError();
        }

        setLoadingError(true);
      }
    }
  }, [
    reportId,
    candidateId,
    accountPackageId,
    payFirst,
    applyWithAuth0,
    isAuthenticated,
    applyWithSMS,
    dispatch,
    applyRoute,
    isAuthenicationError,
    handleAuthenicationError
  ]);

  useEffect(() => {
    fetchApplicationData();
  }, [
    candidateId,
    reportId,
    accountPackageId,
    payFirst,
    applyWithAuth0,
    isAuthenticated,
    applyWithSMS,
    dispatch,
    applyRoute,
    fetchApplicationData
  ]);

  // Skip payment if you are pay first and have already paid
  // Only runs for auth0 workflow because otherwise this will occur when twilio is submitted
  useEffect(() => {
    if (applyWithAuth0 && payFirst && paid) {
      skipToStep(-1, steps, paid, pii, paid_by, screenings, dispatch);
    }
  }, [applyWithAuth0, steps, paid, pii, paid_by, payFirst, screenings, dispatch]);

  useEffect(() => {
    if (savedStep) {
      setCurrentStep(savedStep, [...steps], dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedStep]);

  useEffect(() => {
    if (payFirst) {
      reOrderPayFirst(formSections, dispatch, applyWithAuth0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payFirst]);

  const sendVerificationEmailToUser = async (accountId: string, email: string) => {
    try {
      await sendVerificationEmail(accountId, email);
    } catch (err) {
      throw err;
    }
  };

  const sendPasscodeToAuthServer = async (accountId: string, email: string, code: string) => {
    try {
      await sendPasscode(accountId, email, code);

      // If in SMS auth route for the first time (i.e., no candidate email), submit verified email to backend
      if (applyWithSMS && !candidateEmail) {
        try {
          dispatch({ type: 'SET_CANDIDATE_DATA', payload: { email: email } });
          await updateCandidate(
            candidateId,
            {
              email: email,
              email_verified: true,
              skip_middle_name_validation: true
            },
            dispatch,
            additionalDataWithPii
          );
        } catch (err) {
          throw err;
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const setResetCallbacks = (formName: string, fieldNames: string[]) => {
    let callbacks: any = {};
    const payloads: any = [];
    if (fieldNames && fieldNames.length && fieldNames.length > 0) {
      fieldNames.forEach((fieldName: string) => {
        callbacks = {
          ...callbacks,
          [fieldName]: () => removeImageFromForm(dispatch, fieldName, formName)
        };
        payloads.push({
          formName,
          fieldName
        });
      });
      dispatch(setResetCallbacksAction(callbacks));
      dispatch(setFormName(formName));
      dispatch(setResetPayloads(payloads));
    }
  };

  const submitReportData = async (candidateToSend: CandidateToSend, patchURL: string, formName: string) => {
    const step = getStep(formName);
    const toProcessImages = get(step, 'data_collection.publish_images_to_process');
    // reset candidate responses so we can (re-)submit
    candidateResponseReturned.current = false;

    if (!toProcessImages) {
      // use authenticated route if using auth0 flow.
      // streamline this code once all candidates are on auth0 flow.
      if (applyWithAuth0 || applyWithAuthentication) {
        setCandidateApplicationLoading(true, dispatch);
        setCandidateApplicationLoading(true, dispatch);
        return await updateCandidate(candidateId, candidateToSend, dispatch, additionalDataWithPii)
          .then(res => {
            setCandidateApplicationLoading(false, dispatch);
            return res;
          })
          .catch(err => {
            setCandidateApplicationLoading(false, dispatch);
            throw err;
          });
      } else {
        return await updateCandidateReportData(patchURL, candidateToSend, dispatch, additionalDataWithPii)
          .then(res => {
            setCandidateApplicationLoading(false, dispatch);
            return res;
          })
          .catch(err => {
            setCandidateApplicationLoading(false, dispatch);
            throw err;
          });
      }
    } else {
      // do image processing
      dispatch(setLoading({ isLoading: true, loadingText: 'processing...' }));
      dispatch(setPatchUrl(patchURL));
      dispatch(setCandidate(candidateToSend));
      const documentTest = get(step, 'data_collection.document_test');
      const filesObj = get(candidateToSend, `additional_data.${formName}.files`);
      const filesKeys = Object.keys(filesObj);
      const file_references: string[] = [];
      setResetCallbacks(formName, filesKeys);

      try {
        filesKeys.forEach(fk => {
          const files = filesObj[fk].map((item: any) => item.file_reference);
          files.forEach((f: any) => {
            file_references.push(f);
          });
        });
      } catch (error) {}

      const payload = {
        file_references: uniq(file_references),
        candidate_id: candidateId,
        screening_id: get(step, 'screening_id')
      };
      dispatch(setDocumentRegexTest(documentTest));
      const processResponse = await processFiles(reportId, payload);
      // set the token and topic to initiate websocket communication
      dispatch(updateTopic(processResponse.topic));
      dispatch(updateToken(processResponse.token));

      // this promise needs to wait for
      // - processFiles to complete. This then sets token and topic for documentProcessing.
      // - websockets to get a response from microservice
      // - candidateApply response
      return new Promise(function(resolve, reject) {
        (function waitForResponse() {
          if (candidateResponse.current && !candidateResponseReturned.current) {
            candidateResponseReturned.current = true;
            return resolve(candidateResponse.current);
          }
          const timer = setTimeout(waitForResponse, 1000);
        })();
      });
    }
  };
  const storedPatchUrl = useSelector(state => get(state, 'documentVerify.candidateApplyPatchUrl', ''));
  const storedCandidate = useSelector(state => get(state, 'documentVerify.candidate', {}));
  // Watch for success from websockets. When that happens we'll submit the cadidate data.
  useEffect(() => {
    if (previousDocumentStatus !== 'success' && documentStatus === 'success') {
      const asyncOp = async () => {
        // use authenticated route if using auth0 flow.
        // streamline this code once all candidates are on auth0 flow.
        if (applyWithAuth0 || applyWithAuthentication) {
          await updateCandidate(candidateId, storedCandidate, dispatch, additionalDataWithPii)
            .then(res => {
              dispatch(setLoading({ isLoading: false, loadingText: '' }));
              candidateResponse.current = res;
            })
            .catch(err => {
              dispatch(setLoading({ isLoading: false, loadingText: '' }));
              throw err;
            });
        } else {
          await updateCandidateReportData(storedPatchUrl, storedCandidate, dispatch, additionalDataWithPii)
            .then(res => {
              dispatch(setLoading({ isLoading: false, loadingText: '' }));
              candidateResponse.current = res;
            })
            .catch(err => {
              dispatch(setLoading({ isLoading: false, loadingText: '' }));
              throw err;
            });
        }
      };
      asyncOp();
    }
    return () => {
      candidateResponse.current = null;
    };
  }, [documentStatus, previousDocumentStatus]);

  const submitNextStep = async (currentStep: number, formSteps: Array<FormElements>) => {
    const currentFormStep = formSteps[currentStep];
    const { formName, innerIndex } = currentFormStep;
    if (isAuthenticated) {
      return await updateReportStep({ candidateId, reportId, stepName: formName, stepInnerIndex: innerIndex })
        .then((res: any) => {})
        .catch((err: any) => {
          throw err;
        });
    }
  };

  const { onFileUpload, processErrors, setProcessErrors } = useFileUpload(dispatch, reportId);

  const connectPaymentService = () => {
    return stripePayment(reportId, candidateId)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  if (pageLoading) {
    return <Loader logo="https://yardstik-assets.s3.amazonaws.com/logos/yardstik-black.svg" spinnerColor="primary" />;
  }

  if (loadingError) {
    return <CandidateApplyFeedbackPage />;
  }

  return (
    <div className={`initialReportApply ${classes.root}`}>
      <CandidateApply
        isAdditionalInfo={false}
        reportId={reportId}
        candidateId={candidateId}
        accountPackageId={accountPackageId}
        connectPaymentService={connectPaymentService}
        stripeKey={stripeKey}
        submitForm={submitReportData}
        onFileUpload={onFileUpload}
        sendVerificationEmail={sendVerificationEmailToUser}
        submitVerificationEmailCode={sendPasscodeToAuthServer}
        redirectLink={redirectLink}
        skipEmailVerification={applyWithAuth0}
        submitNextStep={(currentStep: number, formSteps: any) => submitNextStep(currentStep, formSteps)}
        refetchApplyData={fetchApplicationData}
        useOnNextStep={!isAuthenticated}
      />
      <DocumentProcessing />
      <AlertSnackbar
        alertVariant="filled"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        notification={{ text: processErrors, severity: 'error' }}
        setNotification={() => {
          setProcessErrors('');
        }}
      />
      {isLoading && <Loader spinnerColor="primary" text={loadingText} overlay={true} />}
    </div>
  );
};

export default withLDConsumer()(InitialReportApply);
