import { AnyAction } from 'redux';
import { SET_CANDIDATE_ACCOUNT_DATA } from '../actionTypes';

export type AccountData = {
  account_id?: string,
  account_name?: string,
  support_email_address?: string,
  support_phone?: string,
}; 

const initialState: AccountData = {};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const accountReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case SET_CANDIDATE_ACCOUNT_DATA:
    return {
      ...state,
      ...action.payload,
    };
  default:
    return state;
  }
};

export default accountReducer;
