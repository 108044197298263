
const isLocalStorageEnabled = () => {
  try {
    const key = `__storage__test`;
    localStorage.setItem(key, 'true');
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const setLocalStorage = (key:string, value: any): void => {
  if(!isLocalStorageEnabled()){
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key:string): void => {
  if(!isLocalStorageEnabled()){
    return;
  }

  localStorage.removeItem(key);
};

export const getLocalStorage = (key:string): any => {
  if(!isLocalStorageEnabled()){
    return;
  }
  
  const data =  localStorage.getItem(key);
  const parsedData = data?JSON.parse(data):null;

  return parsedData;
};

export const updateLocalStorage = (key:string, value: any): void => {
  if(!isLocalStorageEnabled()){
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));  
};
