import React from 'react';
import { useInfoRequestAlert } from './index.hook';
import { InfoRequestSuccessAlert } from './InfoRequestSuccessAlert';
import { InfoRequestWarningAlert } from './InfoRequestWarningAlert';

export function InfoRequestAlert() {
  const {
    candidateId,
    infoRequestId,
    shouldShowInfoRequestSuccessAlert,
    shouldShowInfoRequestWarningAlert
  } = useInfoRequestAlert();

  if (shouldShowInfoRequestSuccessAlert) return <InfoRequestSuccessAlert />;

  if (shouldShowInfoRequestWarningAlert)
    return <InfoRequestWarningAlert candidateId={candidateId} infoRequestId={infoRequestId} />;

  return <React.Fragment />;
}
