import api from './index';

export type Question = {
  id: string;
  content: any;
  answers: any[]
}
export type Course = {
  name: string;
  total_points: number;
  passing_score: number;
  content: {
    retry: {
      limit_retry: boolean;
      number_attempts: number;
    },
    video_url: string;
  };
  questions: Question[];
}

export type CandidateCourse = {
	candidate_course_id: string;
	status: string;
  score: number;
	completed_at: string | null;
	course: Course;
};

export const getCoursesResults = (candidateId: string, reportId: string) => api
  .get(`/candidates/${candidateId}/courses/${reportId}`)
  .then((response) => response.data);

export const submitCourseAnswers = (candidateCourseId: string, submission: any) => api
  .patch(`candidate_courses/${candidateCourseId}/answers`, submission)
  .then((response) => response.data);
