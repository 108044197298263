/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SET_ADC_VALUES, INCREMENT_ADC_TRIGGER } from '../actionTypes';

type ADCType = {
  [n: string]: unknown;
};

export const setAdcValues = (adc: ADCType, dispatch: any) => {
  dispatch({ type: SET_ADC_VALUES, payload: adc });
};

export const incrementAdcTrigger = (dispatch: any) => {
  dispatch({ type: INCREMENT_ADC_TRIGGER });
};

export default setAdcValues;
