import api from './index';

export const getLegalDocs  = async () => api
  .get(`legal_documents?document_system_names[]=candidate_update`)
  .then((response) => response.data);

export const getReportLegalDocs = (accountId: string, reportId: string) => api
  .get(`/accounts/${accountId}/${reportId}/legal_documents`)
  .then((res) => res);

export const getReportViewDocuments = async (candidateId: string, reportId: string) => api
  .get(`/candidate-profile/candidates/${candidateId}/reports/${reportId}/signed-documents`)
  .then((res) => res.data);
