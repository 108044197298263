/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice } from '@reduxjs/toolkit';

export type FeedbackAccount = {
  account_name: string;
  id: string;
  logo: string;
};
export type FeedbackState = {
  pageInfo: {
    title: string;
    description: string;
    httpStatusText: string;
  };
  account: FeedbackAccount;
};

const initialState: FeedbackState = {
  pageInfo: {
    title: '',
    description: '',
    httpStatusText: ''
  },
  account: {
    account_name: '',
    id: '',
    logo: ''
  }
};

const feedbackSlice = createSlice({
  name: 'Feedback',
  initialState,
  reducers: {
    setFeedback: (state, action) => {
      state.pageInfo = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    }
  }
});

export const { setAccount, setFeedback } = feedbackSlice.actions;
export default feedbackSlice.reducer;
