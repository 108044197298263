export interface IRecordHitItem {
  type: string,
  label: string,
  order: number,
  presenter?: {
    alert: string,
    adverse_action_description?: boolean,
    adverse_action_date?: boolean,
  },
  value?: string,
}

export type IRecordHit = IRecordHitItem[];

export type IRecordHits = IRecordHit[];

export interface ICandidateAddresses {
  id: string;
  current: boolean;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface ICandidateReportScreenings {
  id: string;
  status: string;
  screening: any;
}

export interface ICandidateReports {
  id: string;
  completedAt?: string;
  createdAt: string;
  status: string;
  submittedAt?: string;
  accountPackage?: any;
  reportScreenings?: Array<ICandidateReportScreenings>;
}

export interface IMonitorRecords {
  id: string;
  createdAt: string;
  status: string;
  decision: string;
  disputable?: boolean;
  results: any;
  adverseAction: {
    id: string;
    status: string;
  } | null;
}

export interface ICandidateMonitors {
  id: string;
  availableActions: any;
  enabled: boolean;
  status: string;
  createdAt: string;
  updatedAt: string;
  compliantUntil: string | null;
  permissiblePurpose: {
    id: string;
    name: string;
  };
  accountPackage: {
    id: string;
    name: string;
  };
  screening: {
    id: string;
    name: string;
    label: string;
  };
  records: IMonitorRecords[];
}

export interface ICandidateInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  driverLicenseNumber?: string;
  personType?: string;
  personSubType?: string;
  availableActions?: any;
  addresses?: Array<ICandidateAddresses>;
  reports: Array<ICandidateReports>;
  monitors: Array<ICandidateMonitors>;
  account?: {
    accountName?: string;
    supportEmailAddress?: string;
  }
}

export const getQuery = (id: string | number): string => {
  return `{
    candidates(where: [{field: id, operator: equal, value: "${id}"}]) {
      totalCount
      totalPages
      edges {
        cursor
        node {
          account {
            id
            accountName
            supportEmailAddress
          }
          id
          firstName
          lastName
          email
          phone
          driverLicenseNumber
          personType
          personSubType
          availableActions
          addresses {
            id
            current
            line1
            line2
            city
            state
            zipCode
          }
          reports {
            id
            completedAt
            createdAt
            status
            submittedAt
            accountPackage {
              name
            }
            reportScreenings {
              id
              status
              screening {
                name
              }
            }
          }
          monitors {
            id
            availableActions
            enabled
            status
            createdAt
            updatedAt
            compliantUntil
            permissiblePurpose {
              id
              name
            }
            accountPackage {
              id
              name
            }
            screening {
              id
              name
              label
            }
            records {
              id
              createdAt
              status
              decision
              results
              disputable
              adverseAction {
                id
                status
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`;
};
