import store from '../store';
import { SET_AUTH_USER, SET_TOKEN } from '../controls/actionTypes';

export const addAuthUser = (user: any) => {
  store.dispatch({
    type: SET_AUTH_USER,
    payload: user
  });
};

export const setToken = (token: string) => {
  store.dispatch({
    type: SET_TOKEN,
    payload: token
  });
};
