import React from 'react';
import {StepFormLogoFooter } from '@yardstik/core.components';
import './style.scss';

type Props = {
  text?: string;
}

const LoadingError = (props: Props) => {
  const {text = "Error loading page information."} = props;
  return (
    <div className='errorMessage__box--loading'>
      <div className='errorMessage__text--loading'>
        {text}
        <StepFormLogoFooter/>
      </div>
    </div>
  );
};

export default LoadingError;
