import { useSelector } from 'react-redux';
import { ReportType } from '@yardstik/core.components';

import { RootState } from '../../../../redux/store';

type Report = {
  identity_verification_id: string | null;
  info_request: {
    id: string;
    status: string;
  } | null;
} & ReportType;

export function useInfoRequestAlert() {
  const report = useSelector((state: RootState) => state.reports.reportDetail ?? {}) as Report;

  const candidateId = report.candidate.id;
  const infoRequestId = report?.info_request?.id ?? '';
  const shouldShowInfoRequestSuccessAlert = report?.info_request?.status === 'processing';

  const shouldShowInfoRequestWarningAlert =
    report?.status === 'info_requested' && report?.info_request?.status === 'pending';

  return {
    candidateId,
    infoRequestId,
    shouldShowInfoRequestWarningAlert,
    shouldShowInfoRequestSuccessAlert
  };
}
