import React, { useState, useCallback, useEffect } from 'react';
import {
  Modal,
  DefaultFieldProps,
  StepForm,
} from '@yardstik/core.components';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { parseCandidateSubmit } from '../../CandidateApply/candidateApplyUtilsBeta';
import { updateScreeningUploadFiles } from '../../../redux/screeningUpload/actions';
import { updateCandidateReportData } from '../../../services/api/candidates';
import { updateInfoRequestedScreeningField } from '../../../redux/screeningUpload/reducer';
import { ReportScreeningUploadModalProps } from './reportScreeningUploadModalInterface';
import { setControlsError } from '../../../redux/controls/actions';
import { useAuth0 } from '../../../contexts/auth0/auth0-context';
import { getCandidatePatchApiPath  } from '../../../services/auth/authHelpers';


function ReportViewScreeningUploadModal(props: ReportScreeningUploadModalProps): JSX.Element {
  const dispatch = useDispatch();
 
  const { screening_id } = useParams<{ screening_id: string }>();

  const { modalOpen, candidateId, reportId, screeningName, setModalOpen, modalError } = props;


  const { isAuthenticated } = useAuth0();
  //Set patchURL based on if the user is authenicated 
  const  patchURL = getCandidatePatchApiPath(isAuthenticated, candidateId);

  const screeningUpload = useSelector(state => get(state, 'screening_upload', {}));
  const legalDocuments = useSelector(state => get(state, 'reports.reportDetail.workflow_steps.legal_documents', []));
  const auth = useSelector(state => get(state, 'auth', {})) as any;
  const formValues = useSelector(state => get(state, 'screening_upload.formValues', {}));

  const legalDocsScreeningValues = get(formValues, 'legalDocs', {});
  const [formStep, setFormStep] = useState(0);
  const [fileObjects, setFileObjects] = useState<any[]>([]);

  useEffect(() => {
    const infoRequestedFiles = get(formValues, `${screeningName}.files.info_requested_files`, []);
    const infoRequestedFileObjects = infoRequestedFiles.map((infoRequestedFile: Object) => {
      const currentFile = { ...infoRequestedFile };
      return currentFile;
    });
    setFileObjects(infoRequestedFileObjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  // initiate formSteps with the fileUpload component
  const formSteps = [
    {
      formName: screeningName,
      components: [
        {
          name: 'info_requested_files',
          type: 'file_upload',
          label: 'Upload files',
          order: 0,
          height: 'dense',
          required: true,
          fileObjects
        }
      ]
    }
  ] as any[];

  if (legalDocuments.length) {
    legalDocuments.map((legalDocument, index) => {
      const { type = '', title = '', components = [] } = legalDocument;

      const isLastItem = index === legalDocuments.length - 1;
      // only set request signature to true if it is the last legalDocuments group
      const requestSignature = !!isLastItem;

      formSteps.push({
        formName: type,
        innerIndex: index,
        components,
        sectionTitle: title,
        requestSignature
      });
    });
  }

  const handleOnBlur = useCallback(
    (value: any, props: DefaultFieldProps) => {
      dispatch(
        updateInfoRequestedScreeningField({
          screeningName: props.formName,
          screeningFieldName: props.name,
          screeningFieldValue: value
        })
      );
    },
    [dispatch]
  );

  const handleAddImage = async (props: DefaultFieldProps, files: any[]) => {
    try {
      updateScreeningUploadFiles(
        {
          formName: props.formName,
          fieldName: props.name,
          files,
          action: 'add',
          dispatch,
          candidateId,
          reportId,
          screeningName: props.formName,
          resource_id: screening_id,
          resource_type: 'ReportScreening',
        }
      );
    } catch (error) {
      const fileName = files[0]?.name || '';
      dispatch(
        setControlsError({
          status: 'error',
          message: `Error removing media ${fileName}`
        })
      );
    }
  };

  const handleRemoveImage = async (props: DefaultFieldProps, file: any) => {
    const mediaId = file.mediaId || file.file_reference;
    try {
      updateScreeningUploadFiles({
        formName: props.formName,
        fieldName: props.name,
        mediaId,
        action: 'remove',
        dispatch,
        candidateId,
        reportId,
        screeningName: props.formName,
        resource_id: screening_id,
        resource_type: 'ReportScreening'
      });
    } catch (error) {
      dispatch(
        setControlsError({
          status: 'error',
          message: `Error removing media ${file?.file?.name || ''}`
        })
      );
    }
  };

  const submitForm = useCallback(
    (formValues: { values: any; formName: string }) => {
      const { formName = '' } = formValues;
      const candidateToSend = parseCandidateSubmit(formName, {
        ...screeningUpload,
        application: {
          report_id: reportId,
          workflow_steps: { legal_documents: legalDocuments }
        },
        candidate: { email: auth.email }
      });

      return updateCandidateReportData(patchURL, candidateToSend, dispatch)
        .then(res => {
          setFormStep(formStep + 1);
          return res;
        })
        .catch(err => {
          throw err;
        });
    },
    [auth.email, candidateId, dispatch, formStep, reportId, screeningUpload]
  );

  return (
    <Modal
      maxWidth="sm"
      primaryAction={{
        color: 'primary',
        disabled: !modalError && formStep !== 2,
        onClick: () => {
          setModalOpen(false);
        },
        text: 'Close'
      }}
      title="Info Requested"
      open={modalOpen}
    >
      <div>
        {modalError && (
          <div className="infoRequestedModal__instructions">
            {modalError ? modalError : 'Please upload the documents requested'}
          </div>
        )}
        {!modalError && (
          <StepForm
            candidateEmail={auth?.email || ''}
            onNextStep={submitForm}
            showBackground={false}
            currentStep={formStep}
            onSubmit={submitForm}
            submitButtonText="Next"
            values={formValues}
            secondaryAction={() => setFormStep(formStep - 1)}
            secondaryButtonText="Back"
            signatureValues={{
              first_name: get(legalDocsScreeningValues, 'first_name', ''),
              middle_name: get(legalDocsScreeningValues, 'middle_name', '')
                ? get(legalDocsScreeningValues, 'middle_name', '')
                : '',
              last_name: get(legalDocsScreeningValues, 'last_name', '')
            }}
            updateAppLevelFormData={handleOnBlur}
            onAddImage={handleAddImage}
            onRemoveImage={handleRemoveImage}
            showFooter={false}
            formSteps={[
              ...formSteps,
              {
                formName: 'success',
                formTitle: 'Information Submitted',
                formBody: ` Thank you for completing the information and authorization needed to begin processing your background
                                screening. <br />
                                Upon completion of the screening process, you will receive an additional email with a link to your
                                background check should you want to have a copy for your records. <br />`,
                components: []
              }
            ]}
          />
        )}
      </div>
    </Modal>
  );
}

export default ReportViewScreeningUploadModal;
