import React from 'react';
import { Loader } from '@yardstik/core.components';
import { useAuth0 } from '../contexts/auth0/auth0-context';
import CandidateListing from './CandidateListing';

function CandidateHome(): JSX.Element {
  const { isLoading, apiToken } = useAuth0();

  if (isLoading) {
    return (
      <Loader
        logo='https://yardstik-assets.s3.amazonaws.com/logos/yardstik-black.svg'
        spinnerColor='primary'
      />
    );
  }
  return (
    <div>
      <span className="MuiPaper-root">
        {/*  Only renders component when apiToken is set; user not set until token added to api */}
        {apiToken && <CandidateListing />}
      </span>
    </div>
  );

}

export default CandidateHome;
