/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '../../store';
import { decrementStep } from '../controls/actions';

const initialState: any = {};

const formSectionsSlice = createSlice({
  name: 'FormSections',
  initialState,
  reducers: {
    setFormSectionsSteps: (state, action) => {
      return action.payload;
    },

    setStepFiles(state, action) {
      const { stepIndex, componentName, mediaId } = action.payload;
      const currentStepFiles = state.steps[stepIndex].files || {};
      const componentFiles = currentStepFiles[componentName] || [];

      state.steps[stepIndex].files = {
        ...currentStepFiles,
        [componentName]: [...componentFiles, { mediaId }]
      };
    },

    setStepComponentFiles(state, action) {
      const { stepIndex, componentIndex, updatedFileObjects } = action.payload;
      state.steps[stepIndex].components[componentIndex].fileObjects = updatedFileObjects;
    },

    setStepComponentAdditionalProps(state, action) {
      const { stepIndex, componentIndex, additionalProps } = action.payload;
      const currentComponentProps = state.steps[stepIndex].components[componentIndex];
      state.steps[stepIndex].components[componentIndex] = {
        ...currentComponentProps,
        ...additionalProps
      };
    },

    removeStepComponentFile(state, action) {
      const { componentName, mediaId, stepName } = action.payload;
      const stepIndex = state.steps.findIndex(step => step.name === stepName);
      const stepData = state.steps[stepIndex] || {};
      const componentIndex = stepData.components?.findIndex(component => component.name === componentName);

      if (componentIndex !== -1) {
        const { fileObjects = [] } = stepData.components[componentIndex];
        const remainingFileObjects = fileObjects.filter(fileObject => fileObject.mediaId !== mediaId);
        state.steps[stepIndex].components[componentIndex].fileObjects = remainingFileObjects;
      }
      return state;
    }
  }
});

export const {
  removeStepComponentFile,
  setFormSectionsSteps,
  setStepComponentFiles,
  setStepComponentAdditionalProps,
  setStepFiles
} = formSectionsSlice.actions;
export default formSectionsSlice.reducer;

export function setComponentFile(payload: any): AppThunk {
  return function(dispatch: AppDispatch, getState) {
    const state = getState();
    const { formSections } = state.candidate_application;
    const { componentName, stepName, newFile = {}, additionalProps } = payload;
    const { mediaId = '' } = newFile;
    const stepIndex = formSections.steps.findIndex(step => step.name === stepName);
    const stepData = formSections.steps[stepIndex] || {};
    const componentIndex = stepData.components?.findIndex(component => component.name === componentName);

    if (componentIndex === -1) {
      return;
    }

    const { fileObjects = [] } = stepData.components[componentIndex];

    const updatedFileObjects = [...fileObjects, newFile];
    dispatch(setStepComponentFiles({ stepIndex, componentIndex, updatedFileObjects }));
    dispatch(setStepFiles({ stepIndex, componentName, mediaId }));

    if (additionalProps) {
      dispatch(setStepComponentAdditionalProps({ stepIndex, componentIndex, additionalProps }));
    }
  };
}

/**
 * REMOVE when NON_AUTH0_FLOW is not used anymore
 * This thunk method was created to fix an issue of skipping steps on non-auth0-flow
 * When the non_auth0_flow is not used this can be removed and
 * the the setFormSectionsSteps method should be renamed back to setFormSections
 * @param payload
 * @returns void
 */
export function setFormSections(payload: any): AppThunk {
  return function(dispatch: AppDispatch, getState) {
    const state = getState();
    const { formSections, controls } = state.candidate_application;
    const { currentStep } = controls;
    const { steps: oldSteps = [] } = formSections;
    const { steps: newSteps } = payload;

    const newStepsNames = newSteps.map(step => step.formName);

    let removedStepIndex;

    oldSteps.forEach((step, index) => {
      if (!newStepsNames.includes(step.formName)) {
        removedStepIndex = index;
      }
    });

    if (removedStepIndex && currentStep === removedStepIndex) {
      decrementStep(dispatch);
    }

    dispatch(setFormSectionsSteps(payload));
  };
}
