import { useState, useCallback } from "react";
import { emailCode } from '../../api/auth';

import { useAuth0 } from '../../../contexts/auth0/auth0-context';
import { attachAuthToken } from '../../api';
import {  setToken } from '../../../redux/auth/actions';
import { authServiceLogIn } from '../authTokenService';

import { validateToken } from '../jwt';

export type TsendPasscode = {
  status: Number;
  statusText: String;
  data: any;
  error: any;
  loading: Boolean;
  sendPasscode: Function;
  passcodeVerified: Boolean;
};



export const useSendPasscode = ():TsendPasscode => {

  const [status, setStatus] = useState<Number>(0);
  const [statusText, setStatusText] = useState<String>('');
  const [data, setData] = useState<any>({});
  const [passcodeVerified, setPasscodeVerified] = useState<boolean>(false);
  const [error, setError] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);

  // Get auth0 context
  const { setIsAuthenticated, setApiToken } = useAuth0();
  

  const sendPasscode =  useCallback(async (accountId: string, email: string, code: string) => {
    
    try {
      const authResponse = await emailCode(accountId, email, code);
      const responseStatus = authResponse?.status;
      const responseStatusText = authResponse?.statusText;
      const responseData = authResponse?.data;
      const accessToken = responseData?.access_token;

      if(responseStatus === 200 && accessToken){
       
         
        const isAuthTokenValid = validateToken(accessToken);

        if(isAuthTokenValid){
          setPasscodeVerified(true);
          authServiceLogIn(accessToken);
          attachAuthToken(accessToken);
          setApiToken(accessToken);
          setToken(accessToken);
          setIsAuthenticated(true);
          setLoading(false);
        }else{
          console.log('Not Valid JWT Access Token');
        }
        
      } else{

        console.log('No Access Token in API response Payload');
        return;
      }

      setStatus(responseStatus);
      setStatusText(responseStatusText);
      setData(responseData);
    
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
 
    }
  }, []);


  return { data, status, statusText, error, loading, sendPasscode, passcodeVerified };
};
