import React from 'react';
import { Box, Typography } from '@mui/material';
import { FieldComponent, Upload, Form as YSForm } from '@yardstik/core.components';

import { useInfoRequestForm } from './InfoRequestFormProvider';
import { UploadFieldDescription } from './UploadFieldDescription';

export function InfoRequestForm(): JSX.Element {
  const { form } = useInfoRequestForm();

  return (
    <Box
      noValidate
      component="form"
      onSubmit={form.onSubmit}
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <Box>
        {form.fieldsSections.map(groupField => (
          <Box mb={2} key={groupField.id}>
            <Box>
              <Typography
                variant="body1"
                color="grey.800"
                dangerouslySetInnerHTML={{ __html: groupField.description }}
                sx={{ display: 'block', '& p': { margin: 0 } }}
              />
            </Box>

            <Box mb={1}>
              <YSForm
                multiFormRef={form.ysFormRef}
                formName={groupField.id}
                components={
                  (groupField.fields.map(item => ({
                    ...item,
                    width: 'full'
                  })) as unknown) as FieldComponent[]
                }
              />
            </Box>
          </Box>
        ))}

        {form.fileUploadSections.map(groupField => (
          <Box mb={2} key={`fileUploadSection${groupField.id}`}>
            <Box mb={2}>
              <Typography
                variant="body1"
                color="grey.800"
                dangerouslySetInnerHTML={{ __html: groupField.description }}
              />
              {!groupField.wasCategorizedByInfoRequestTypeId && (
                <UploadFieldDescription
                  title={groupField.category === 'screening' ? groupField.report_screening.label : ''}
                  subTitle={groupField.label}
                />
              )}
            </Box>

            {groupField.file_upload?.map((field, index) => {
              const isLastItem = groupField.file_upload.length - 1 === index;

              const wasCategorizedByInfoRequestTypeAndIsLastItem =
                groupField.wasCategorizedByInfoRequestTypeId && isLastItem;

              const inputName = groupField.wasCategorizedByInfoRequestTypeId ? field.categoryName : field.name;

              return (
                <React.Fragment key={index}>
                  {groupField.wasCategorizedByInfoRequestTypeId && (
                    <Box mb={1}>
                      <UploadFieldDescription title={field.categoryLabel} subTitle={groupField.label} />
                    </Box>
                  )}

                  <Box mb={wasCategorizedByInfoRequestTypeAndIsLastItem ? 1 : 2}>
                    <Upload
                      required
                      maxFileSize={10}
                      name={inputName}
                      error={!!form.fileError[inputName]}
                      helperText={form.fileError[inputName]}
                      allowedFileTypes={['image/png', 'image/jpeg', 'application/pdf']}
                      onRender={args =>
                        form.onChangeFiles({
                          infoRequestedId: field.infoRequestedId || groupField.info_requested_id,
                          documentType: field.document_type,
                          ...args
                        })
                      }
                      onChange={args =>
                        form.onChangeFiles({
                          infoRequestedId: field.infoRequestedId || groupField.info_requested_id,
                          documentType: field.document_type,
                          ...args
                        })
                      }
                      button={{
                        text: field.label,
                        variant: 'text'
                      }}
                    />
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
