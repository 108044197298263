/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
  INCREMENT_CANDIDATE_APPLICATION_STEP,
  DECREMENT_CANDIDATE_APPLICATION_STEP,
  SET_CANDIDATE_APPLICATION_STEP,
  SET_CANDIDATE_APPLICATION_LOADING,
  SET_CANDIDATE_APPLICATION_STATUS
} from '../actionTypes';
//TODO: remove 'window.scrollTo' Dom side effects should be executed in components
export const incrementStep = (dispatch: any) => {
  window.scrollTo(0, 0);
  dispatch({ type: INCREMENT_CANDIDATE_APPLICATION_STEP });
};

export const decrementStep = (dispatch: any) => {
  window.scrollTo(0, 0);
  dispatch({ type: DECREMENT_CANDIDATE_APPLICATION_STEP });
};

export const setStep = (step: number, dispatch: any) => {
  window.scrollTo(0, 0);
  dispatch({ type: SET_CANDIDATE_APPLICATION_STEP, payload: step });
};

export const setCandidateApplicationLoading = (
  value: boolean,
  dispatch: any
) => {
  window.scrollTo(0, 0);
  dispatch({ type: SET_CANDIDATE_APPLICATION_LOADING, payload: value });
};

export const setStatus = (status: string, dispatch: any) => {
  dispatch({ type: SET_CANDIDATE_APPLICATION_STATUS, payload: status });
};
