const getAutofillMappingByName = (input:any) => {
  try {
    return input.reduce((accValue:any, currentValue:any) => {
      return { ...accValue,  [currentValue.name]: currentValue.autofillMapping};
    }, {});
  } catch (error) {
    return input;    
  }
};

export default getAutofillMappingByName;
