import { get } from 'lodash';
import api from './index';
import { setControlsError } from "../../redux/controls/actions";

interface IObjectKeys {
  [key: string]: any;
}

export interface  ReportProblemData extends IObjectKeys {
  reason: string,
  disputed_information: string,
  report_id: string,
  files?: Array<object>,
}

export const createDispute  = async (candidateId: string, payload: any) => api
  .post(`/candidate-profile/candidates/${candidateId}/disputes`, payload)
  .then((response) => {
    setControlsError({status:'success', message: 'Your dispute has been sent. You will be contacted via your indicated method of communication.'});
    return response.data;
  })
  .catch((err) => {
    const errorMessage = get(err, 'response.data.errors', 'Unable to create dispute');
    setControlsError({status:'error', message: errorMessage});
    throw err;
  });
