import { Theme } from '@yardstik/core.components';
import { styled } from '@mui/material';

export const SectionTitle = styled('div')({
  fontFamily: Theme.fonts.primary,
  fontSize: Theme.fontSizes['x-large'],
  fontWeight: Theme.fontWeights.heavy,
  color: Theme.colors['gray--80'],
  marginTop: '12px',
  marginBottom: '12px',
});
