const removeKey = (obj: object, keyString) => {
  try {
    const objKeys = Object.keys(obj);
    const output = {};

    objKeys.forEach((key) => {
      const newKey = key.replace(keyString, '');
      output[newKey] = obj[key];
    });
    return output;
  } catch (error) {
    return obj;
  }
};

export default removeKey;
