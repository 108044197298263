/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react';

const reportProblemContext = createContext({
  reportProblemCallback: (_monitorRecordId: string) => {},
  setForceExpand: (expand: boolean) => {},
  forceExpand: false
});

export default reportProblemContext;
