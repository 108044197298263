import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { StatusChip, Timeline, TimelineItem } from '@yardstik/core.components';
import { Box, Grid } from '@material-ui/core';
import { ICandidateReports } from './candidateUtils';
import * as S from './style';

const ReportList = ({
  reports = [],
  candidateId = ''
}: {
  reports: Array<ICandidateReports>;
  candidateId: string;
}): JSX.Element => {
  const history = useHistory();

  const [reportItems, setReportItems] = useState<Array<TimelineItem>>([]);

  const goToReport = reportId => history.push(`/candidates/${candidateId}/reports/${reportId}`);

  useEffect(() => {
    if (reports.length) {
      const getItemMarkup = (description = '', status = '') => {
        return (
          <Grid container alignItems="center">
            <Grid item md={8} sm={12} xs={12}>
              {!!description ? description : <S.ErrorText>Account Package not found for this report</S.ErrorText>}
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box justifyContent="flex-end" display="flex">
                <StatusChip category="report" status={status} />
              </Box>
            </Grid>
          </Grid>
        );
      };
      const composedReports = reports.map(reportItem => {
        const { id, completedAt, createdAt, submittedAt, status } = reportItem;
        const accountPackage = reportItem.accountPackage || {};
        const fallbackStatusDate = !!submittedAt ? submittedAt : createdAt;
        const topicTitle = !!completedAt ? completedAt : fallbackStatusDate;

        const fallbackStatus = !!submittedAt ? 'ordered' : 'created';
        const topicSubtitle = !!completedAt ? 'completed' : fallbackStatus;
        const item = {
          topicTitle: moment(topicTitle).format('MM/DD/YYYY'),
          topicSubtitle,
          topicContent: getItemMarkup(accountPackage.name, status),
          clickAction: () => goToReport(id)
        };
        return item;
      });
      setReportItems(composedReports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  return (
    <>
      <Timeline items={reportItems} />
    </>
  );
};

export default ReportList;
