import { useEffect } from 'react';

declare global {
  interface Window {
    gtag: any;
    dataLayer: unknown[];
  }
}

export interface GTagEvent {
  action: string
  category?: string
  label?: string
  value?: string
}

type TuseGoogleAnalytics = {
  logAnalyticsEvent: any
  logAnalyticsPageView: any
}


//measurementId

export const useGoogleAnalytics = (measurementId: string):TuseGoogleAnalytics => {

  const isRunningLocally = process.env.NODE_ENV !== 'production';

  useEffect(() => {
    if (!window.gtag) return;
    window.dataLayer = window.dataLayer || [];

    window.gtag('js', new Date());

    window.gtag('config', measurementId);
  }, [measurementId]);


  // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
  const logAnalyticsPageView = (url: string, analyticsId: string):void => {
    if (isRunningLocally) return;
    if (!window.gtag) console.error('G Tag is not truthy');
    window.gtag('config', analyticsId, {
      page_path: url
    });
  };

  /** Function used to log specified events configured in the Google Analytics console */
  const logAnalyticsEvent = ({ action, category, label, value }: GTagEvent):void => {
    if (isRunningLocally) return;
    if (!window.gtag) console.error('G Tag is not truthy');
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  };


  return {
    logAnalyticsEvent,
    logAnalyticsPageView: (url: string) => logAnalyticsPageView(url, measurementId)
  };
 
};
