import { isEmpty, get } from 'lodash';
import moment from 'moment';

const getFieldValuesToADC = (formName: string, adcFields: any, stepNamesInOrder: any, formValues: any, currentMapping: any) => {
  try {
    const mappingToParsed = currentMapping.map((cm: any) => {
      const toField = get(cm, 'toField');
      const toSplit = toField.split('.');
      const fromField = get(cm, 'fromField');
      const trimHyphen = get(cm, 'trimHyphen');
      const formatDate = get(cm, 'formatDate');
      const formatAddress = get(cm, 'formatAddress');
  
      return {
        step: toSplit[0],
        fieldName: toSplit[1],
        fromField,
        trimHyphen,
        formatDate,
        formatAddress,
      };
    });
    // populate toMap from adcFields
    const mappingWithAdc = mappingToParsed.map((mapping: any) => {
      const { fromField, trimHyphen, formatDate, formatAddress, ...rest } = mapping;
  
      // If the field is an address, there are multiple from fields
      if (formatAddress) {
        const line1 = get(adcFields, fromField[0], null);
        const city = get(adcFields, fromField[1], null);
        const state = get(adcFields, fromField[2], null);
        const zip_code = get(adcFields, fromField[3], null);
        if (line1 || city || state || zip_code) {
          const value = {
            line1: line1,
            line2: null,
            city: city,
            state: state,
            zip_code: zip_code,
          };
          return { ...rest, value: [value] };
        }
        return;
      }
  
      let value: string = get(adcFields, fromField);
      if (trimHyphen && value) {
        value = value.replace(/-/g, '');
      }
      // we can add other moment converstions here for different formats
      if (formatDate && value) {
        if (moment(value, 'YYYY-MM-DD').isValid()) {
          value = moment(value).format("YYYY-MM-DD");
        }
      }
      if (value) {
        return { ...rest, value: value };
      }
    }).filter((x: any) => {
      // filter out ADC values that relate to past steps 
      // we don't want to override user input, or submit data that hasn't been reviewed by a candidate
      const stepToADC = x?.step || null;
      // error handling in case a value is missing
      if (!stepToADC || !formName) {
        return false;
      }
      const indexOfCurrentStep = stepNamesInOrder.indexOf(formName);
      const indexOfStepToADC = stepNamesInOrder.indexOf(stepToADC);
      return indexOfCurrentStep < indexOfStepToADC;
    }).filter((x: any) => {
      // Filter out ADC values for formFields that already have values 
      // We don't want to override user input or pre-filed fields from csv uploads
      const formStep = get(formValues, x.step, {});
      const currentFormValue = get(formStep, x.fieldName, null);
  
      // If the field has a value of [ {object} ], then we do want to ADC
      // This is applicable to the address field
      if (Array.isArray(currentFormValue) && currentFormValue.length === 1) {
        const item = currentFormValue[0];
        if (typeof item === 'object' && !Array.isArray(item)) {
          return true;
        }
      }
  
      // Otherwise, if the field already has a value, then we don't want to ADC
      if (currentFormValue) {
        return false;
      } else {
        return true;
      }
    }).map((x: any) => {
      const formStep = get(formValues, x.step, {});
      const currentFormValue = get(formStep, x.fieldName, null);
  
      // If the field has a value of [{object}], we want to get any existing keys to combine with the ADC values
      // If there are multiple objects in the array, then we don't know which two to combine, so skip this logic
      // This is applicable to the address object
      if (Array.isArray(currentFormValue) && currentFormValue.length === 1) {
        const fieldValuesObject = { ...currentFormValue[0] };
        if (typeof fieldValuesObject === 'object' && !Array.isArray(fieldValuesObject) && !isEmpty(fieldValuesObject)) {
          const ADCvalue = x.value[0] || {};
          for (const key in ADCvalue) {
            if (!fieldValuesObject.hasOwnProperty(key) || fieldValuesObject[key] === '' || fieldValuesObject[key] === null || fieldValuesObject[key] === undefined) {
              fieldValuesObject[key] = ADCvalue[key];
            }
          }
          return { ...x, value: [fieldValuesObject] };
        }
      }
      return x;
    });
  
  
    return mappingWithAdc;  
  } catch (error) {
    return [];
  }
};

export default getFieldValuesToADC;
