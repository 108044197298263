import { API_BASE_CANDIDATE_PATH, API_BASE_AUTH_CANDIDATE_PATH, API_BASE_INVITATIONS_PATH } from "../constants";

//Set API canidate path based on if the user is authenicated 
export const getCandidateBaseApiPath  = (isAuthenitcated: boolean):string =>{

  if(isAuthenitcated){

    return `${API_BASE_AUTH_CANDIDATE_PATH}`;
  }else if(!isAuthenitcated) {

    return API_BASE_CANDIDATE_PATH;
  }else{
    
    return API_BASE_CANDIDATE_PATH;
  }
};

export const getCandidatePatchApiPath  = (isAuthenitcated: boolean, candidateId: string):string =>{

  if(isAuthenitcated){

    return `${API_BASE_AUTH_CANDIDATE_PATH}/candidates/${candidateId}`;
  }else if(!isAuthenitcated) {

    return `${API_BASE_CANDIDATE_PATH}/${candidateId}/submit`;
  }else{
    
    return API_BASE_CANDIDATE_PATH;
  }
};

export const getCandidateApplyApiPath  = (isAuthenitcated: boolean, candidateId: string, reportId: string):string =>{

  if(isAuthenitcated){

    return `${API_BASE_AUTH_CANDIDATE_PATH}/candidates/${candidateId}/reports/${reportId}/apply/`;
  }else if(!isAuthenitcated) {

    return `${API_BASE_CANDIDATE_PATH}/${candidateId}/apply/${reportId}`;
  }else{
    
    return `${API_BASE_CANDIDATE_PATH}/${candidateId}/apply/${reportId}`;
  }
};


export const getinvitationsPatchApiPath  = (isAuthenitcated: boolean, invitationId: string):string =>{

  if(isAuthenitcated){

    return `${API_BASE_INVITATIONS_PATH}/${invitationId}/submit`;
  }else if(!isAuthenitcated) {

    return `${API_BASE_INVITATIONS_PATH}/${invitationId}/submit`;
  }else{
    
    return  `${API_BASE_INVITATIONS_PATH}/${invitationId}/submit`;
  }
};
