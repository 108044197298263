import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '../store';

const initialState: any = {
  screeningId: '',
  candidateId: '',
  formValues: {},
  legalDocuments: {},
};

const screeningUploadSlice = createSlice({
  name: 'ScreeningUpload',
  initialState,
  reducers: {
    setInfoRequestedScreeningFiles: (state, action) => {
      const { formName, screeningUpdatedFiles } = action.payload;
      const screeningFormValues = state.formValues[formName] || {};

      state.formValues[formName] = {
        ...screeningFormValues,
        files: screeningUpdatedFiles,
      };
    },

    updateInfoRequestedScreeningData: (state, action) => {
      const { screeningName, screeningData } = action.payload;
      state.formValues[screeningName] = screeningData;
    },

    updateInfoRequestedScreeningField: (state, action) => {
      const { screeningFieldName, screeningName, screeningFieldValue } = action.payload;
      const screeningData = state.formValues[screeningName] || {};
      state.formValues[screeningName] = {
        ...screeningData,
        [screeningFieldName]: screeningFieldValue,
      };
    },
  },
});

export const {
  setInfoRequestedScreeningFiles,
  updateInfoRequestedScreeningData,
  updateInfoRequestedScreeningField,
} = screeningUploadSlice.actions;

export default screeningUploadSlice.reducer;

export function updateInfoRequestedScreeningFiles(payload: any): AppThunk {
  return function (dispatch: AppDispatch, getState) {
    const state = getState();
    const { action: fileAction, fieldName, formName, file, mediaId } = payload;

    const screeningFormValues = state.screening_upload?.formValues[formName] || {};
    const screeningFormValuesClone = { ...screeningFormValues };
    const screeningFormValuesFiles = screeningFormValuesClone.files || {};
    let updatedFiles = screeningFormValuesFiles[fieldName] || [];

    if (fileAction === 'add') {
      updatedFiles = [...updatedFiles, file];
    }

    if (fileAction === 'remove') {
      updatedFiles = updatedFiles.filter((currentFile) => currentFile.file_reference !== mediaId);
    }

    const screeningUpdatedFiles = {
      ...screeningFormValuesFiles,
      [fieldName]: updatedFiles,
    };
    dispatch(setInfoRequestedScreeningFiles({ formName, screeningUpdatedFiles }));
  };
}


