import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const initialState = {
  token: '',
  topic: '',
  fileReferences: {},
  resetCallbacks: {},
  processErrors: '',
  documentRegexTest: '',
  isLoading: false,
  loadingText: '',
  resetPayloads: [],
  status: '',
  candidateApplyPatchUrl: '',
  candidate: {},
  formName: '',
  autofillMappings: {},
};

const documentVerify = createSlice({
  name: 'documentVerify',
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateTopic: (state, action) => {
      state.topic = action.payload;
    },
    setFileReferences: (state, action) => {
      state.fileReferences = action.payload;
    },
    setDocumentRegexTest: (state, action) => {
      state.documentRegexTest = action.payload;
    },
    setResetCallbacks: (state, action) => {
      state.resetCallbacks = action.payload;
    },
    setProcessErrors: (state, action) => {
      state.processErrors = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = get(action, 'payload.isLoading', false);
      state.loadingText = get(action, 'payload.loadingText', '');
    },
    setResetPayloads: (state, action) => {
      state.resetPayloads = action.payload;
    },
    setProccessingStatus: (state, action) => {
      state.status = action.payload;
    },
    setPatchUrl: (state, action) => {
      state.candidateApplyPatchUrl = action.payload;
    },
    setCandidate: (state, action) => {
      state.candidate = action.payload;
    },
    setFormName: (state, action) => {
      state.formName = action.payload;
    },
    setAutofillMappings: (state, action) => {
      state.autofillMappings = action.payload;
    }
  },
});

export const { updateToken, updateTopic, setFileReferences, setResetCallbacks, setProcessErrors, setDocumentRegexTest, setLoading, setResetPayloads,setProccessingStatus, setPatchUrl, setCandidate, setFormName, setAutofillMappings } = documentVerify.actions;
export default documentVerify.reducer;
