/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  SET_CANDIDATE_APPLICATION_DATA,
  SET_CANDIDATE_APPLICATION_PAID,
  SET_PAYMENT_INFO_LOADING
} from '../actionTypes';

const initialState = {
  paymentInfoLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case SET_CANDIDATE_APPLICATION_DATA:
    return {
      ...state,
      ...action.payload,
    };
  case SET_CANDIDATE_APPLICATION_PAID:
    return {
      ...state,
      paid: action.payload,
    };
  case SET_PAYMENT_INFO_LOADING:
    return {
      ...state,
      paymentInfoLoading: action.payload,
    };
  default:
    return state;
  }
};
