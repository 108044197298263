import { datadogLogs } from '@datadog/browser-logs';

import store from '../../redux/store';

import { reportsApi } from '../../redux/reports/requests';
import { identityVerificationApi } from '../../redux/identityVerification/requests';

type Args = {
  reportId: string;
  attempt?: number;
  candidateId: string;
};

export async function identityVerificationStatusPolling(args: Args) {
  const { reportId, attempt = 1, candidateId } = args;

  const TOTAL_ATTEMPTS = 10;
  datadogLogs.logger.info(`idv_polling: Polling for IDV status. Attempt ${attempt}`);

  const { getReport } = reportsApi.endpoints;
  const { getIdentityVerification } = identityVerificationApi.endpoints;

  await store.dispatch(getReport.initiate({ candidateId, reportId }));
  const { data } = getReport.select({ candidateId, reportId })(store.getState());

  if (data?.identity_verification_id) {
    await store.dispatch(
      getIdentityVerification.initiate({
        candidateId,
        id: data.identity_verification_id
      })
    );

    const { data: identityVerificationData } = getIdentityVerification.select({
      candidateId,
      id: data.identity_verification_id
    })(store.getState());

    if (identityVerificationData?.status === 'pass') {
      datadogLogs.logger.info('idv_polling: IDV is passed. Finishing the pooling process.');

      return;
    }
  }

  if (attempt === TOTAL_ATTEMPTS) {
    datadogLogs.logger.info(
      `idv_polling: It was not possible to confirm if IDV was passed after ${TOTAL_ATTEMPTS} attempts. Moving the candidate to the next step.`
    );

    return;
  }

  datadogLogs.logger.info('idv_polling: Waiting 3 seconds before next attempt.');

  await new Promise(resolve => setTimeout(resolve, 3000));

  args.attempt = attempt + 1;

  return identityVerificationStatusPolling(args);
}
