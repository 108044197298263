import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Alert, Header, StatusChip, identityVerificationReportViewMapper } from '@yardstik/core.components';

import { useGetIdentityVerificationQuery } from '../../../redux/identityVerification/requests';
import { RootState } from '../../../redux/store';

export function IdentityVerification() {
  const { report, candidateId, identityVerificationId } = useSelector((state: RootState) => ({
    report: state.reports.reportDetail,
    candidateId: state.reports.reportDetail.candidate.id ?? '',
    identityVerificationId: state.reports.reportDetail.identity_verification_id ?? ''
  }));

  const { data } = useGetIdentityVerificationQuery(
    { id: identityVerificationId, candidateId },
    { skip: !identityVerificationId || !candidateId }
  );

  const mappedResults = useMemo(() => {
    if (data) return identityVerificationReportViewMapper(data);
  }, [data]);

  const shouldShowAlert = useMemo(() => {
    return data?.status === 'fail' && report.status === 'created';
  }, [data, report]);

  const isNonFcraReport = report.governance_type === 'non_fcra';

  if (!identityVerificationId || !data) return <React.Fragment />;

  return (
    <Box mt={6}>
      <Box sx={{ mb: '12px' }}>
        <Header.Root>
          <Header.Head>
            <Header.Title title="Identity Verification" />
            <Header.Statuses>
              <StatusChip status={mappedResults?.sectionHeader?.status as string} category="identity_verification" />
              {mappedResults?.sectionHeader?.subStatus && (
                <StatusChip
                  sx={{ cursor: 'pointer' }}
                  category="identity_verification"
                  status={mappedResults?.sectionHeader?.subStatus}
                  tooltip={`Identity verification failure was ignored, allowing you to continue with your report screenings`}
                />
              )}
            </Header.Statuses>
          </Header.Head>

          {!isNonFcraReport && (
            <Header.Footer>
              <Header.Description description="These preliminary checks run prior to the rest of the report" />
            </Header.Footer>
          )}
        </Header.Root>
      </Box>

      {shouldShowAlert && (
        <Alert sx={{ marginBottom: '12px' }} severity="info" variant="standard">
          If you'd like to learn more about your results, get in touch with {report.account?.account_name} at{' '}
          <a style={{ color: 'inherit' }} href={`mailto:${report.account?.support_email_address}`}>
            {report.account?.support_email_address}
          </a>
          .
        </Alert>
      )}
    </Box>
  );
}
