import {get} from 'lodash';

const getADCFields = (input:any) => {
  const objectsKeys = Object.keys(input);
  let output = {};
  objectsKeys.forEach(objKey => {
    let fieldsValues = {};
    const fields = get(input, `${objKey}[0].fields`);
    const fieldsKeys = Object.keys(fields);
    fieldsKeys.forEach(fieldsKey => {
      const fieldValue = get(input, `${objKey}[0].fields.${fieldsKey}.value`);
      fieldsValues = {...fieldsValues, [fieldsKey]: fieldValue};
    });
    output = {...output, ...fieldsValues };
  });
  return output;
};

export default getADCFields;
