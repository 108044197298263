import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClientContext } from 'graphql-hooks';
import { ResponsiveHeader } from '@yardstik/core.components';
import { Route } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { useAuth0 } from '../contexts/auth0/auth0-context';

const PrivateRoute = ({ component: Component, path }: any): JSX.Element => {
  const { apiToken, isLoading, loginWithRedirect, user, isAuthenticated, logout } = useAuth0();
  const candidateList = useSelector((state: { reports: any; candidates: any }) => state.candidates.candidates);

  const [userName, setUserName] = useState<{
    full_name: string;
  }>({
    full_name: ''
  });

  const [hasAuthHeader, setHasAuthHeader] = useState(false);

  // set auth token for graphql-hooks context
  const client = useContext(ClientContext);
  useEffect(() => {
    if (client && apiToken) {
      client.setHeader('Authorization', `Bearer ${apiToken}`);
      setHasAuthHeader(true);
    }
  }, [client, apiToken]);

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    // New authorizationParams during migration to auth0 2
    loginWithRedirect({
      authorizationParams: {
        appState: { targetUrl: '/' },
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`
      }
    });
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  // setting user email on datadog
  useEffect(() => {
    if (isAuthenticated) {
      datadogRum.setUser({
        email: user?.email
      });
    }
  }, [isAuthenticated, user]);

  // Once the candidate list is obtained, the user name of the first candidate will be set.
  // Note: Logic assumes that a 'user' will have only one name, so looking at the first candidate is fine
  useEffect(() => {
    if (candidateList && Array.isArray(candidateList) && candidateList.length >= 1) {
      const { full_name = '' } = candidateList[0];
      setUserName({
        full_name
      });
    }
  }, [candidateList]);

  const render = (props: any) => (isAuthenticated === true && hasAuthHeader ? <Component {...props} /> : null);

  const handleLogout = () => {
    return logout({
      logoutParams: {
        returnTo: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}`
      }
    });
  };

  return (
    <>
      <ResponsiveHeader
        title="Profile"
        userData={{
          fullName: userName.full_name
        }}
        handleLogout={handleLogout}
      />
      <Route path={path} render={render} />
    </>
  );
};

export default PrivateRoute;
