import api from './index';

export const stripePayment = async (reportId: string, candidateId: string) => {
  const payload = {
    report_id: reportId,
    candidate_id: candidateId,
  };
  return api
    .post(`/transactions`, payload)
    .then((res) => (res)).catch((err) => {
      throw err;
    });
};
