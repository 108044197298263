import api from './index';

export const emailVerification = async (accountId:string, email:string) => api
  .post(`/accounts/${accountId}/send`, {
    to: email,
    channel: 'email',
  })
  .then((response) => {
    return response;
  })
  .catch((err) => {
    throw err;
  });


export const emailCode = async (accountId: string, email: string, code: string) => api
  .post(`/accounts/${accountId}/verify`, {
    to: email,
    code,
  })
  .then((response) => {
    return response;
  })
  .catch((err) => {
    throw err;
  });

