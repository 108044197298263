export interface CustomError extends Error {
  data: {
    detail: string;
  }
}

export const setErrorMessage = (err: any, defaultMessage: string): string => {
  let errorMessage: string;
  if (err?.data?.detail) {
    errorMessage = err.data.detail;
  } else {
    errorMessage = defaultMessage;
  }
  return errorMessage;
};
