import React from 'react';
import { Alert } from '@yardstik/core.components';
import Snackbar from '@material-ui/core/Snackbar';
import {setControlsError} from '../../redux/controls/actions';
import {AlertProps} from './alertSnackbarInterface';

const AlertSnackbar = (props:{error: AlertProps}): JSX.Element => {
  const {
    error
  } = props;
  return(
    <Snackbar open autoHideDuration={6000} onClose={() => setControlsError({status: '', message: ''})}>
      <Alert severity={error.status}>{error.message}</Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
