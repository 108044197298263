import React from 'react';
import { Box, Fade } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Alert as YSAlert, Button } from '@yardstik/core.components';

import { useGetInfoRequestQuery } from '../../../../../redux/infoRequest/requests';

import { InfoRequestModal } from './InfoRequestModal';
import { InfoRequestFormProvider } from './InfoRequestFormProvider';

type InfoRequestAlertType = {
  candidateId: string;
  infoRequestId: string;
};

export function InfoRequestWarningAlert(props: InfoRequestAlertType): JSX.Element {
  const { data } = useGetInfoRequestQuery(props);

  const [open, setOpen] = React.useState(false);

  const removeDuplicates = arr => {
    const seenLabels = new Set();

    return arr.filter(item => {
      if (!seenLabels.has(item.label)) {
        seenLabels.add(item.label);

        return true;
      }

      return false;
    });
  };

  const uniqueInfoRequestTypes = removeDuplicates(data?.info_request_types || []);

  if (!uniqueInfoRequestTypes.length) return <React.Fragment />;

  return (
    <Fade in={!!uniqueInfoRequestTypes.length}>
      <div>
        <YSAlert
          severity="warning"
          variant="standard"
          icon={<ReportProblemIcon />}
          sx={{ my: '12px', alignItems: 'center' }}
          action={
            <Button
              size="full"
              type="button"
              variant="text"
              color="warning"
              text="Provide Info"
              onClick={() => setOpen(true)}
            />
          }
        >
          We were unable to verify some of your information. To get your report moving again, provide your
          <Box component="ul" sx={{ pl: '28px', m: 0 }}>
            {!!uniqueInfoRequestTypes.length &&
              uniqueInfoRequestTypes.map((type, index) => (
                <Box component="li" key={index}>
                  {type.label}
                </Box>
              ))}
          </Box>
        </YSAlert>

        {data && (
          <InfoRequestFormProvider
            data={data}
            modal={{
              isOpen: open,
              onClose: (event, reason) => {
                if (reason === 'backdropClick') return;

                setOpen(false);
              }
            }}
          >
            <InfoRequestModal />
          </InfoRequestFormProvider>
        )}
      </div>
    </Fade>
  );
}
