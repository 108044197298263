import React, { useContext, useState, useMemo } from 'react';
import Accordion from '@material-ui/core/Accordion';
import { Box, Grid } from '@material-ui/core';
import { CardInfo, StatusChip } from '@yardstik/core.components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { upperCase } from 'lodash';
import moment from 'moment';
import RecordItem from '../RecordItem';
import * as S from './style';
import { ICandidateMonitors } from '../CandidateDetails/candidateUtils';
import CandidateDetailsContext from '../CandidateDetails/ReportProblem.context';

const NON_COMPLIANT_STATUS = 'non_compliant';

type MonitorProps = {
  monitor: ICandidateMonitors;
  candidateId: string;
  forceExpand?: boolean;
};
const Monitor = (props: MonitorProps): JSX.Element => {
  const { monitor } = props;
  const { forceExpand, setForceExpand } = useContext(CandidateDetailsContext);
  const {
    enabled = false,
    status = '',
    records = [],
    accountPackage: { name }
  } = monitor;

  // non-compliant screens start open for efficiency
  const [isActive, setIsActive] = useState(!!(status === NON_COMPLIANT_STATUS));

  const monitorInfo = useMemo(() => {
    const { createdAt = null, updatedAt = null, compliantUntil = null } = monitor;
    const info = [
      {
        label: 'Created At',
        value: createdAt ? moment(createdAt).format('MM/DD/YYYY') : '-'
      },
      {
        label: 'Updated At',
        value: updatedAt ? moment(createdAt).format('MM/DD/YYYY') : '-'
      }
    ];
    if (enabled) {
      info.push({
        label: 'Compliant Until',
        value: compliantUntil ? moment(compliantUntil).format('MM/DD/YYYY') : '-'
      });
    }
    info.push({
      label: 'Permissible Purpose',
      value: monitor?.permissiblePurpose?.name || ''
    });
    return info;
  }, [enabled, monitor]);

  const statusText = useMemo(() => {
    return upperCase(status);
  }, [status]);

  return (
    <>
      <S.MonitorContainer data-testid="monitorContainer">
        <Accordion expanded={isActive || forceExpand}>
          <S.AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={'1'}
            onClick={() => {
              setIsActive(!isActive);
              if (isActive) {
                setForceExpand(false);
              }
            }}
          >
            <Grid container data-testid="monitorAccordionSummary">
              <Grid item xs={12} sm={6} md={8}>
                <S.Title>
                  {enabled ? (
                    <S.MonitorName data-testid="monitorAccordionSummary__monitorName">{name}</S.MonitorName>
                  ) : (
                    <S.MonitorNameInactive>{name}</S.MonitorNameInactive>
                  )}
                </S.Title>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <S.ChipContainer>{enabled && <StatusChip category="monitor" status={status} />}</S.ChipContainer>
              </Grid>
            </Grid>
          </S.AccordionSummary>
          <S.MonitorAccordionDetails>
            <Box p={2}>
              <Box>
                <CardInfo fields={monitorInfo} showBoxShadow={false} />
              </Box>
              {!!records.length &&
                records.map((record, index) => (
                  <Box key={`record-item-${index}`}>
                    <RecordItem record={record} />
                  </Box>
                ))}
            </Box>
          </S.MonitorAccordionDetails>
        </Accordion>
      </S.MonitorContainer>
    </>
  );
};

export default Monitor;
