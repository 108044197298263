import { styled, Box, Typography } from '@mui/material';

export const Wrapper = styled(Box)({
  width: '100%',
  backgroundColor: '#F6F7F8',
  border: '1px solid #D9D9D9'
});

export const Title = styled(Typography)({
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'flexStart',
  paddingLeft: '6px',
  '@media(max-width: 600px)': {
    justifyContent: 'center',
    paddingBottom: '6px',
    paddingLeft: '0px'
  }
});

export const StatusChipContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '6px',
  '@media(max-width: 600px)': {
    justifyContent: 'center',
    paddingRight: '0px',
    paddingTop: '6px'
  }
});
