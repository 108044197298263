/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-case-declarations */
import { get, cloneDeep } from 'lodash';
import { AnyAction } from 'redux';
import {
  SET_CANDIDATE_FORM_VALUES,
  UPDATE_CANDIDATE_FORM_VALUE,
  ADD_CANDIDATE_FORM_REPEATER_SECTION,
  REMOVE_IMAGE_FROM_REPEATER_SECTION,
  UPDATE_CANDIDATE_FORM_REPEATER_VALUE,
  ADD_IMAGE_TO_SECTION,
  REMOVE_IMAGE_FROM_SECTION,
  ADD_IMAGE_TO_REPEATER_SECTION,
  REMOVE_CANDIDATE_FORM_REPEATER_SECTION,
  ADD_DESTORY_ADDRESS_ID,
  CLEAR_DESTORY_ADDRESS_ID,
} from '../actionTypes';

const initialState = {};

export default (state = initialState, action: AnyAction) => {
  const { payload = null } = action;

  // data to use when adding image to repeater section
  const propsSentRepeaterSectionName = get(
    payload,
    'propsSent.repeaterSectionName',
    ''
  );
  const propsSentFormId = get(payload, 'propsSent.formName', '');
  const propsSentFormData = state[propsSentFormId] || {};
  const propsSentFieldData = propsSentFormData[
    propsSentRepeaterSectionName
  ] || [{}];
  const propsSentRepeaterCloneFieldData = cloneDeep(propsSentFieldData);

  // data to use when adding image to repeater section
  const imageRepeaterSectionIndex = get(
    payload,
    'propsSent.repeaterSectionIndex',
    null
  );
  const imageRepeaterSectionName = get(
    payload,
    'propsSent.repeaterSectionName',
    ''
  );
  const imageFormId = get(payload, 'propsSent.formName', '');
  const imageRepeaterSectionData = get(
    state,
    `${imageFormId}.${imageRepeaterSectionName}`,
    [{}]
  );
  const imageFormData = state[imageFormId] || {};
  const repeaterSectionImagePath = `${imageRepeaterSectionName}[${imageRepeaterSectionIndex}]`;
  const removeImageFieldId = get(payload, 'propsSent.name', '');
  const clonedImageFileData = cloneDeep(get(imageFormData, 'files', {}));

  // remove sections variables
  const removeFormName = get(action, 'payload.formName', '');
  const removeFieldName = get(action, 'payload.fieldName', '');
  const removeSectionIndex = get(action, 'payload.index', null);
  const formToRemove = state[removeFormName] || {};

  switch (action.type) {
  case SET_CANDIDATE_FORM_VALUES:
    return {
      ...state,
      ...action.payload,
    };
  case UPDATE_CANDIDATE_FORM_VALUE:
    const {
      name,
      fieldValue,
      formName: updateFormId,
      fieldsToNull = [],
    } = action.payload;
      //nulling out fields that are no longer relevent based on show if value rules
    const nullFields =
        fieldsToNull.length > 0
          ? fieldsToNull.reduce((o, key) => ({ ...o, [key]: null }), {})
          : {};
    return {
      ...state,
      [updateFormId]: {
        ...state[updateFormId],
        [name]: fieldValue,
        ...nullFields,
      },
    };
  case ADD_IMAGE_TO_SECTION:
    const { propsSent = {}, file = {} } = action.payload;
    const addImageFormId = get(propsSent, 'formName', '');
    const addImageFieldId = get(propsSent, 'name', '');
    const addImageFormCurrentFiles = get(
      state,
      `${addImageFormId}.files`,
      {}
    );
    const addImageFieldValues = get(
      state,
      `${addImageFormId}.files.${addImageFieldId}`,
      []
    );
    return {
      ...state,
      [addImageFormId]: {
        ...state[addImageFormId],
        files: {
          ...addImageFormCurrentFiles,
          [addImageFieldId]: addImageFieldValues.concat(file),
        },
      },
    };
  case REMOVE_IMAGE_FROM_SECTION:
    const { propsSent: imagePropsSent = {}, mediaId = '' } = action.payload;
    const removeImageFieldValues = get(
      state,
      `${propsSentFormId}.files.${removeImageFieldId}`,
      []
    );
    const remainingFieldImages = removeImageFieldValues.filter((file) => {
      // const { file_name = '', url = '' } = file;
      // return file_name !== fileName && !url.includes(fileName);
      const { file_reference = '' } = file;
      return file_reference !== mediaId;
    });
    return {
      ...state,
      [propsSentFormId]: {
        ...state[propsSentFormId],
        files: {
          ...state[propsSentFormId].files,
          [removeImageFieldId]: remainingFieldImages,
        },
      },
    };
  case UPDATE_CANDIDATE_FORM_REPEATER_VALUE:
    const formName = get(action, 'payload.formName', '');
    const repeaterFieldName = get(action, 'payload.repeaterSectionName', '');
    const repeaterSectionIndex = get(
      action,
      'payload.repeaterSectionIndex',
      null
    );
    const updatedRepeaterFieldName = get(action, 'payload.name', '');
    const repeaterFieldsToNull = get(action, 'payload.fieldsToNull', []);
    const formData = state[formName] || {};
    const fieldData = formData[repeaterFieldName] || [{}];
    const cloneFieldData = cloneDeep(fieldData);
    cloneFieldData[repeaterSectionIndex][updatedRepeaterFieldName] =
        action.payload.fieldValue;
    repeaterFieldsToNull.map(
      (field) => (cloneFieldData[repeaterSectionIndex][field] = null)
    );
    return {
      ...state,
      [formName]: {
        ...state[formName],
        [repeaterFieldName]: [...cloneFieldData],
      },
    };

  case ADD_CANDIDATE_FORM_REPEATER_SECTION:
    const formValues = state[payload.formName] || {};
    const fieldValues = formValues[payload.fieldName] || [{}];
    return {
      ...state,
      [payload.formName]: {
        ...formValues,
        [payload.fieldName]: [...fieldValues, {}],
      },
    };

  case ADD_IMAGE_TO_REPEATER_SECTION:
    const currentFiles = get(
      clonedImageFileData,
      `${imageRepeaterSectionName}`,
      [[]]
    );
    const sectionIndexFiles = currentFiles[imageRepeaterSectionIndex] || [];
    currentFiles[imageRepeaterSectionIndex] = sectionIndexFiles.concat(
      get(action, 'payload.file', [])
    );
    const addImageRepeaterCurrentFiles = get(
      state,
      `${imageFormId}.files`,
      []
    );

    return {
      ...state,
      [imageFormId]: {
        ...state[imageFormId],
        [imageRepeaterSectionName]: imageRepeaterSectionData,
        files: {
          ...addImageRepeaterCurrentFiles,
          [imageRepeaterSectionName]: currentFiles,
        },
      },
    };

  case REMOVE_IMAGE_FROM_REPEATER_SECTION:
    // Get all the files from the entire repeater section
    const removeCurrentFiles = get(
      clonedImageFileData,
      `${imageRepeaterSectionName}`,
      []
    );

    // Get all the files for the relevent sub-section of the repeater section
    const removeSectionIndexFiles =
        removeCurrentFiles[imageRepeaterSectionIndex] || [];

    // Get the file name for the file that the user is removing
    const { fileName: fileNameToRemove = '' } = action.payload;

    // only do this if the files index for this section has image files in it
    if (removeSectionIndexFiles.length > 0) {
      removeCurrentFiles[imageRepeaterSectionIndex] =
          removeSectionIndexFiles.filter((file) => {
            const { file_name = '', url = '' } = file;
            return (
              file_name !== fileNameToRemove && !url.includes(fileNameToRemove)
            );
          });

      return {
        ...state,
        [imageFormId]: {
          ...state[imageFormId],
          files: {
            ...state[imageFormId].files,
            [imageRepeaterSectionName]: removeCurrentFiles,
          },
        },
      };
    }
    return state;
  case REMOVE_CANDIDATE_FORM_REPEATER_SECTION:
    const fieldToRemove = formToRemove[removeFieldName] || [];
    const cloneFieldRemoveData = cloneDeep(fieldToRemove);
    const removedSection = cloneFieldRemoveData.filter((field, index) => {
      return index !== removeSectionIndex;
    });
    return {
      ...state,
      [removeFormName]: {
        ...state[removeFormName],
        [removeFieldName]: [...removedSection],
      },
    };
  case ADD_DESTORY_ADDRESS_ID:
    const addressId = get(action, 'payload', '');
    const destroyAddresses = get(state, 'destroyAddresses', []);
    return {
      ...state,
      destroyAddresses: [...destroyAddresses, addressId],
    };
  case CLEAR_DESTORY_ADDRESS_ID:
    return {
      ...state,
      destroyAddresses: [],
    };
  default:
    return state;
  }
};
