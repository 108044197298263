import { Box } from '@mui/material';
import { CircularLogo, Heading } from '@yardstik/core.components';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Styles } from '../../redux/candidateApply/styles/reducer';
import * as S from './styles';

const CandidateApplyIDVerificationError = (): JSX.Element => {
  const stylesData = useSelector(state => get(state, 'candidate_application.styles', {})) as Styles;
  const title = 'Oops! Something went wrong';
  const supportMailSubject = "Driver's License capture failed";
  const supportEmail = 'support@yardstik.com';

  return (
    <S.Container data-testid="CandidateApplyIDVerificationError">
      {stylesData.logo && (
        <div className={'stepForm__logo'} data-testid="CandidateApplyIDVerificationError--logo">
          <CircularLogo logo={stylesData.logo} />
        </div>
      )}

      <Heading size={'title'} title={title} align={'center'} />

      <Box mb={5}>
        <S.BodyText variant="body1" align="center">
          We couldn’t complete your request. To continue, please reach out <br /> to Yardstik
          Support at &nbsp;
          <S.Link href={`mailto:${supportEmail}?subject=${supportMailSubject}`}>
            {supportEmail}
          </S.Link>
          .
        </S.BodyText>

        <br />

        <S.BodyText variant="body2" align="center">
          Our team is available Monday-Friday from 8am-5pm Central Time.
        </S.BodyText>
      </Box>
    </S.Container>
  );
};

export default CandidateApplyIDVerificationError;
