import { get } from 'lodash';
import { signedRemoveFile, signedUploadFile } from "../../services/api/fileUpload";
import { showCandidate } from "../../services/api/candidates";
import { updateInfoRequestedScreeningFiles, updateInfoRequestedScreeningData } from './reducer';

export interface updateScreeningUploadFilesProps {
  fieldName: string,
  formName: string,
  files?: Array<any>,
  mediaId?: string,
  action: 'add' | 'remove',
  dispatch: any,
  candidateId: string,
  reportId: string,
  screeningName: string,
  resource_id: string,
  resource_type: string,
}

export const updateScreeningUploadFiles = async (props: updateScreeningUploadFilesProps): Promise<any> => {
  const {
    action,
    dispatch,
    fieldName,
    formName,
    files = [],
    reportId,
    candidateId,
    mediaId = '',
    screeningName,
    resource_id,
    resource_type,
  } = props;

  if (action === 'add') {
    const { file, data } = files[0];
    const formData = new FormData();
    formData.set('file', file);
    formData.set('report_id', reportId);
    formData.set('category', screeningName);
    formData.set('data_classification', 'confidential_file');
    formData.set('screening_name', screeningName);
    formData.set('screening_file_group', fieldName);
    formData.set('resource_type', resource_type);
    formData.set('resource_id', resource_id);

    return signedUploadFile(candidateId, formData).then((res) => {
      dispatch(updateInfoRequestedScreeningFiles({
        fieldName,
        formName,
        file: {
          file_reference: res.id,
          file_name: file.name,
          mime_type: file.type,
          data,
          file,
        },
        action,
        mediaId,
      }));
      return res.id;
    }).catch((err) => {
      throw err;
    });
  } else {
    const payload = {
      report_id: reportId,
      screening_name: screeningName,
      screening_file_group: fieldName,
    };
    try {
      await signedRemoveFile({ candidateId, mediaId, payload });
      dispatch(updateInfoRequestedScreeningFiles({
        fieldName,
        formName,
        action,
        mediaId,
      }));
    } catch (error) {
      console.error('Error while trying to remove File', error);
    }
  }
};

export const getCandidateScreeningData = (candidateId: string, screeningName: string, dispatch: any): Promise<any> => {
  return showCandidate(candidateId).then((res) => {
    const additionalData = get(res, 'additional_data', {});
    const first_name = get(res, 'first_name', '');
    const last_name = get(res, 'last_name', '');
    const middle_name = get(res, 'middle_name', '') ? get(res, 'middle_name', '') : '';
    const screeningData = additionalData[screeningName] || {};

    dispatch(updateInfoRequestedScreeningData({ screeningName, screeningData }));

    dispatch(updateInfoRequestedScreeningData({
      screeningName: 'legalDocs', screeningData: { first_name, middle_name, last_name },
    }));
  }).catch((err) => {
    throw err;
  });
};

export default updateScreeningUploadFiles;
