import ReportProblem from '../../ReportProblem';
import { useReportView } from '../index.context';

export function ReportProblemModal() {
  const { fetchReportDetails, openReportProblemModal, setOpenReportProblemModal, report } = useReportView();

  const accountName = report.account?.account_name || '';
  const supportEmailAddress = report.account?.support_email_address || '';

  return (
    <ReportProblem
      accountName={accountName}
      isOpen={openReportProblemModal}
      refetchPageInfo={fetchReportDetails}
      accountSupportMail={supportEmailAddress}
      setModalOpen={isOpen => setOpenReportProblemModal(isOpen)}
    />
  );
}
