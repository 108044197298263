import { useEffect, useRef } from 'react';
import { Button, Alert as YSAlert } from '@yardstik/core.components';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';

import { InfoRequestForm } from './InfoRequestForm';
import { useInfoRequestForm } from './InfoRequestFormProvider';

export function InfoRequestModal(): JSX.Element {
  const theme = useTheme();

  const contentRef = useRef(null);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { modal, form } = useInfoRequestForm();

  const scrollToTop = () => {
    if (contentRef.current) (contentRef.current as HTMLElement).scrollTop = 0;
  };

  useEffect(() => {
    if (form.submitError) scrollToTop();
  }, [form]);

  return (
    <Dialog fullWidth open={modal.isOpen} fullScreen={isMobile} onClose={modal.onClose}>
      <DialogTitle id="alert-dialog-title">Provide Requested Information</DialogTitle>

      <DialogContent ref={contentRef}>
        {form.submitError && (
          <YSAlert severity="error" variant="standard" sx={{ my: '12px', alignItems: 'center' }}>
            Something went wrong. Try again and if you continue to see this message, reach out to{' '}
            <Box component="a" sx={{ color: 'inherit' }} href="mailto:support@yardstik.com">
              support@yardstik.com
            </Box>
          </YSAlert>
        )}
        <InfoRequestForm />
      </DialogContent>

      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button size="large" type="button" text="cancel" variant="text" color="secondary" onClick={modal.onClose} />
        <Button
          text="save"
          type="submit"
          sx={{ ml: 1 }}
          size="large"
          onClick={form.onSubmit}
          disabled={form.isLoading}
        />
      </DialogActions>
    </Dialog>
  );
}
