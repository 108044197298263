import {
  SET_CONTROLS_ERROR,
  SET_PAGE_LOADING
} from './actionTypes';
import store from '../store';

export const setControlsError = (error: {status:string, message: string}) => {
  store.dispatch({
    type: SET_CONTROLS_ERROR,
    payload: error,
  });
};

export const setPageLoading = (status: boolean) => {
  store.dispatch({
    type: SET_PAGE_LOADING,
    payload: status,
  });
};

