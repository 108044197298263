import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { removeImage } from '../../redux/candidateApply/formValues/actions';
import {uploadFile} from '../../services/api/fileUpload';
import { setProcessErrors as setProcessErrorsAction } from '../../redux/documentVerify/documentVerifySlice';

export const getRemoveImage = (dispatch:any, propsSent:any, fileName:string) => {
  try {
    const fileSent = get(propsSent, 'name');
    return () => {
      removeImage(dispatch, fileName, propsSent);
    };
  } catch (error) {
    return () => {};
  }
};

const useFileUpload = (dispatch:any, reportId:string) => {

  const rawProcessErrors = useSelector((state) => get(state, 'documentVerify.processErrors', []));
  const hasProcessErrors = rawProcessErrors && rawProcessErrors.length && rawProcessErrors.length;
  const processErrorsList = hasProcessErrors ? rawProcessErrors.map((pe:string) => (<li key={pe}>{pe}</li>)) : undefined;
  const processErrors = hasProcessErrors ? (<ul>{processErrorsList}</ul>) : undefined;

  const setProcessErrors = (errMsg: string) => {
    dispatch(setProcessErrorsAction(errMsg));
  };
  
  const onFileUpload = async(candidateId: string, files: any, propsSent: any) => {
    const uploadResponse = await uploadFile( candidateId, files);
    
    return uploadResponse;
  };

  return { onFileUpload, processErrors, setProcessErrors };
};

export default useFileUpload;
