/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux';
import { SET_CANDIDATE_LEGAL_DOCUMENTS } from '../actionTypes';

export type LegalDoc = {
  id: string,
  document_system_name: string,
  state: any,
  language: string,
  title: string,
  URL: string,
  render_as: string,
  updated_at: string,
  text: string,
}

export type LegalDocsState = {data: LegalDoc[]};

const initialState: LegalDocsState = {
  data: [],
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case SET_CANDIDATE_LEGAL_DOCUMENTS:
    return {
      ...state,
      data: action.payload,
    };
  default:
    return state;
  }
};
