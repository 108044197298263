
import { get } from 'lodash';
import { listReports, showReport, getCourses } from '../../services/api/reports';
import { getReportViewDocuments } from '../../services/api/legalDocs';
import { setControlsError } from '../controls/actions';
import { LIST_REPORTS, LIST_REPORTS_LOADING_ERROR, SET_REPORT_LEGAL_DOCS, SET_REPORT_COURSES, SHOW_REPORT_LOADING_ERROR } from './actionTypes';
import store from '../store';

// Actions
export const getReportsList = async (candidateList: any[]) => {
  const aggregateReportList: any[] = [];
  try {
    for (const candidate of candidateList) {
      const reports = await listReports(candidate.id);
      if (reports.length > 0) {
        reports.forEach((r: any) => (r.candidate = candidate));
        aggregateReportList.push(...reports);
      }
    }
  } catch (error) {
    store.dispatch({
      type: LIST_REPORTS_LOADING_ERROR,
      payload: true,
    });
  }

  store.dispatch({
    type: LIST_REPORTS,
    payload: aggregateReportList,
  });
};

// getting legal docs that candidate has signed to be show in report view
export const fetchReportViewDocuments = async (candidateId: string, reportId: string) => {
  try {
    const getReportViewDocumentsRes = await getReportViewDocuments(candidateId, reportId);
    store.dispatch({
      type: SET_REPORT_LEGAL_DOCS,
      payload: getReportViewDocumentsRes,
    });
  } catch (err) {
    setControlsError({
      status: 'error',
      message: 'Failed to fetch legal documents',
    });
  }
};

export type GetReportDetailPropsType = {
  candidateId: string;
  reportId: string;
};

export const getReportDetail = async ({
  candidateId,
  reportId
}: GetReportDetailPropsType): Promise<any> => {
  try {
    const showReportResponse = await showReport({ candidateId, reportId });
    fetchReportViewDocuments(candidateId, reportId);
    store.dispatch({
      type: 'SHOW_REPORT',
      payload: showReportResponse,
    });
    const hasCourses = get(showReportResponse, 'course.has_courses', false);

    // if there are courses results we need to fetch them to be used in report view
    if (hasCourses) {
      fetchCourses(candidateId, reportId);
    }
  } catch (e) {
    setControlsError({
      status: 'error',
      message: 'Failed to fetch required data',
    });
    store.dispatch({
      type: 'SHOW_REPORT_LOADING_ERROR',
      payload: true,
    });
    store.dispatch({
      type: 'SET_CANDIDATE_APPLICATION_LOADING',
      payload: false,
    });
  }
};

export const fetchCourses = async (candidateId: string, reportId: string) => {
  try {
    const coursesResponse = await getCourses(candidateId, reportId);
    store.dispatch({
      type: SET_REPORT_COURSES,
      payload: coursesResponse,
    });
  } catch (e) {
    setControlsError({
      status: 'error',
      message: 'Failed to fetch courses',
    });
    store.dispatch({
      type: SHOW_REPORT_LOADING_ERROR,
      payload: true,
    });
    store.dispatch({
      type: 'SET_CANDIDATE_APPLICATION_LOADING',
      payload: false,
    });
  }
};
