import { useSelector } from 'react-redux';
import { Loader } from '@yardstik/core.components';
import type { CandidateType } from '@yardstik/core.components';
import { createContext, useContext, useState } from 'react';

import { usePrint } from './hooks/usePrint';
import { Report } from './ConsumerReport/types';
import { useReportRequest } from './hooks/useReportRequest';

import { RootState } from '../../redux/store';

type ReportViewContextData = {
  report: Report;
  courses: any[];
  isLoading: boolean;
  printReport: () => void;
  candidate: CandidateType;
  forceOpenAccordions: boolean;
  fetchReportDetails: () => void;
  openReportProblemModal: boolean;
  setOpenReportProblemModal: (value: boolean) => void;
};

const ReportViewContext = createContext<ReportViewContextData>({} as ReportViewContextData);

export function useReportView(): ReportViewContextData {
  const context = useContext(ReportViewContext);

  return context;
}

type Props = {
  children: React.ReactNode;
};

export function ReportViewProvider({ children }: Props): JSX.Element {
  const { fetchReportDetails, isLoading } = useReportRequest();

  const { printReport, forceOpenAccordions } = usePrint();

  const [openReportProblemModal, setOpenReportProblemModal] = useState(false);

  const { courses, candidate, report } = useSelector((state: RootState) => ({
    report: state.reports?.reportDetail ?? {},
    candidate: state.reports?.reportDetail?.candidate ?? {},
    courses: state.reports?.reportDetail?.course?.courses ?? []
  }));

  const node = isLoading ? <Loader overlay spinnerColor="primary" /> : children;

  return (
    <ReportViewContext.Provider
      value={{
        report: report as Report,
        courses,
        isLoading,
        candidate,
        printReport,
        fetchReportDetails,
        forceOpenAccordions,
        openReportProblemModal,
        setOpenReportProblemModal
      }}
    >
      {node}
    </ReportViewContext.Provider>
  );
}
