import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@yardstik/core.components';
import { get } from 'lodash';
import CandidateApply from '../../CandidateApply';
import { ReportInfoRequstedModalInterface } from './reportInfoRequestedModalInterface';
import { updateCandidate } from '../../../services/api/candidates';
import { onFileUpload } from './reportInfoRequestedUtils';
import './style.scss';

function ReportInfoRequestedModal(props: ReportInfoRequstedModalInterface): JSX.Element {
  const { setModalOpen, modalOpen, candidateId, reportId, modalError, refetchApplyData } = props;
  const dispatch = useDispatch();

  const candidateApplicationCurrentStep = useSelector(state =>
    get(state, 'candidate_application.controls.currentStep', 0)
  );
  const modalSteps = useSelector(state => get(state, 'candidate_application.formSections.steps', []));
  const onLastStep = candidateApplicationCurrentStep === modalSteps.length - 1;

  return (
    <>
      <Modal
        maxWidth="md"
        primaryAction={{
          color: 'primary',
          disabled: !modalError && !onLastStep,
          onClick: () => setModalOpen(false),
          text: 'Close'
        }}
        title="Correct Your Information"
        open={modalOpen}
        alignActions="center"
      >
        <div className="infoRequestedModal__content">
          {modalError && <div className="infoRequestedModal__instructions">{modalError}</div>}

          {!modalError && (
            <>
              <div className="infoRequestedModal__instructions">
                Review your information and correct anything that doesn't look right. For your security, you'll need to
                re-enter your social security number.
              </div>

              <CandidateApply
                submitForm={(candidateToSend: object) => updateCandidate(candidateId, candidateToSend, dispatch)}
                accountPackageId=""
                candidateId={candidateId}
                reportId={reportId}
                isAdditionalInfo
                onFileUpload={onFileUpload}
                refetchApplyData={refetchApplyData}
                useOnNextStep={true}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ReportInfoRequestedModal;
