import React from 'react';
import { Loader, CircularLogo, Heading, BodyText } from '@yardstik/core.components';

type CandidateApplyUnavailableProps = {
  loading?: boolean,
  logo?: any,
  title?: string,
  body?: any,
}

const CandidateApplyUnavailable = (props: CandidateApplyUnavailableProps): JSX.Element => {
  const { loading, logo, title, body } = props;
  return (
    <div data-testid="candidateApplyUnavailable">
      {loading && (
        <Loader
          overlay={true}
          data-testid="candidateApplyUnavailable--loader"
        />
      )}
      <div className={'stepForm'}>
        {logo && (
          <div
            className={'stepForm__logo'}
            data-testid="candidateApplyUnavailable--logo"
          >
            <CircularLogo logo={logo} />
          </div>
        )}
      </div>
      <Heading size={'title'} title={title} align={'center'} />
      <BodyText body={body} />
    </div>
  );
};

export default CandidateApplyUnavailable;
