import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Theme } from '@yardstik/core.components';
import { styled } from '@mui/material';

export const MonitorContainer = styled('div')({
  '&.MuiPaper-elevation1': {
    border: '1px solid rgba(84, 82, 82, 0.1)',
  },
  marginTop: '12px',
  marginBottom: '12px',
  fontFamily: Theme.fonts.primary,
});

export const AccordionSummary = styled(MuiAccordionSummary)({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    margin: '0px',
  },
});

export const ChipContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '6px',
  '@media(max-width: 600px)': {
    justifyContent: 'center',
    paddingRight: '0px',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
});

export const MonitorAccordionDetails = styled(AccordionDetails)({
  display: 'block',
});


export const MonitorAccordionSummary = styled('div')({
  width: '100%',
});

export const Title = styled('div')({
  display: 'flex',
  justifyContent: 'flexStart',
  paddingLeft: '6px',
  '@media(max-width: 600px)': {
    justifyContent: 'center',
    paddingLeft: '0px',
    paddingTop: '6px',
  },
});

export const MonitorName = styled('div')({
  fontSize: Theme.fontSizes.med,
  padding: Theme.padding.sm,
  color: 'black',
});

export const MonitorNameInactive = styled('div')({
  fontSize: Theme.fontSizes.med,
  padding: Theme.padding.sm,
  color: Theme.colors['gray--60'],
});

export const MonitorStatus = styled('div')({
  display: 'flex',
});
