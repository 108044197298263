import { get } from 'lodash';
import { showCandidate } from '../../services/api/candidates';
import { setDataToStore } from '../../redux/candidateApply/actions';

const URL = process.env.REACT_APP_API_URL;

export const formatLogoUrl = (logo: string): string => {
  const check = /^https?:\/\//i;
  if (logo) {
    return check.test(logo) ? logo : `${URL}${logo}`;
  }
  return '';
};

export const getReportEditInfo = async (response: any, reportId: string, dispatch: any, candidateId: string) => {
  const applicationData = {
    report_id: reportId,
    accountPackageId: '',
    isService: true,
    payFirst: false,
    isAdditionalInfo: true,
  };

  try {
    const candidateResponse = await showCandidate(candidateId);

    const account = get(candidateResponse, 'account', {});
    const logo = get(account, 'logo', '');
    const formattedLogo = formatLogoUrl(logo);
    const reportData = {
      ...candidateResponse,
      candidate: {
        ...candidateResponse,
      },
      styles: {
        ...account.styles,
      },
      workflow_steps: get(response, 'workflow_steps', {}),
      logo: formattedLogo,
      report_id: reportId,
    };
    await setDataToStore(reportData, applicationData, dispatch);
    return true;
  } catch (err) {
    throw err;
  }
};




