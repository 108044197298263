import { createSlice } from '@reduxjs/toolkit';

const initialState = { byAccountId: {} };

const launchDarklySlice = createSlice({
  name: 'launchDarkly',
  initialState,
  reducers: {
    updateByAccountId: (state, action) => {
      state.byAccountId = action.payload;
    },
    clearFeatures: (state) => {
      // clear all keys here
      state.byAccountId = {};
    },
  },
});

export const { updateByAccountId, clearFeatures } = launchDarklySlice.actions;
export default launchDarklySlice.reducer;
