import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import cuid from 'cuid';
import {
  Modal,
  ReportProblemModalBody,
  DefaultFieldProps,
  ReportProblemForm
} from '@yardstik/core.components';
import { createDispute as createDisputeApi } from '../../services/api/dispute';
import { ReportProblemModalProps } from './reportUpdateModalInterface';

function ReportProblemModal(props: ReportProblemModalProps): JSX.Element {
  const {
    open = false,
    monitorRecordId = '',
    setModalOpen,
    refetchPageInfo,
    candidateId,
    reportId,
    accountName = '',
    accountSupportMail = ''
  } = props;

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formValues, setFormValues] = useState({
    disputed_information: '',
    reason: ''
  });

  const [fileObjects, setFileObjects] = useState<any[]>([]);

  const handleOnChange = (
    e: any,
    props: DefaultFieldProps,
    formErrors: any
  ) => {
    const { name } = props;
    setFormValues({ ...formValues, [name]: e });

    if (isEmpty(formErrors)) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const createDispute = async () => {
    const { disputed_information = '', reason = '' } = formValues;
    // setting all values to formData
    const formData = new FormData();

    const resourceType = !!monitorRecordId ? 'monitor_record_id' : 'report_id';
    const resourceValue = !!monitorRecordId ? monitorRecordId : reportId;

    formData.set(resourceType, resourceValue);
    formData.set('disputed_information', disputed_information);
    formData.set('reason', reason);

    // looping over uploaded files to add them
    if (fileObjects?.length) {
      fileObjects.forEach(({ file }) => {
        formData.append('files[]', file);
      });
    }

    createDisputeApi(candidateId, formData).then(() => {
      resetModal();
      !!refetchPageInfo && refetchPageInfo();
    });
  };

  const resetModal = () => {
    setModalOpen(false);
    setSubmitDisabled(true);
    setFileObjects([]);
    setFormValues({ disputed_information: '', reason: '' });
  };

  const handleAddImage = (props: DefaultFieldProps, files: any[]) => {
    const mediaId = cuid();
    setFileObjects(previousFiles => [
      ...previousFiles,
      { ...files[0], mediaId }
    ]);
  };

  const handleRemoveImage = (props: DefaultFieldProps, file: any) => {
    const remainingFiles = fileObjects.filter(
      fileObject => fileObject.mediaId !== file.mediaId
    );
    setFileObjects(remainingFiles);
  };

  return (
    <Modal
      title="Dispute Screening"
      open={open}
      primaryAction={{
        onClick: () => {
          createDispute();
        },
        text: 'Submit',
        color: 'primary',
        disabled: submitDisabled
      }}
      secondaryAction={{
        onClick: () => {
          resetModal();
        },
        text: 'Cancel',
        color: 'danger',
        disabled: false
      }}
    >
      <ReportProblemModalBody
        account={accountName}
        accountSupportMail={accountSupportMail}
      />
      <ReportProblemForm
        onChange={handleOnChange}
        onAddImage={handleAddImage}
        onRemoveImage={handleRemoveImage}
        values={formValues}
        fileObjects={fileObjects}
      />
    </Modal>
  );
}

export default ReportProblemModal;
