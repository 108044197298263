// @ts-nocheck
import { keyBy } from 'lodash';

export const reorderSteps = (input: any[], ordering: string[], payFirst: boolean): any[] => {
  // convert array to object keyed by formName
  const stepsByFormName = keyBy(input, 'reorderName');
  const orderingHasPayment = Boolean(ordering.includes('payment'));

  // get what should go at the beginning
  const beginningSteps = [];
  if (stepsByFormName['landing']) {
    beginningSteps.push(stepsByFormName['landing']);
    delete stepsByFormName['landing'];
  }

  if (stepsByFormName['emailVerification']) {
    beginningSteps.push(stepsByFormName['emailVerification']);
    delete stepsByFormName['emailVerification'];
  }

  // get what should go at the end
  const endSteps = [];

  // put payment endSteps before LegalDocs if not in ordering and not pay first
  if (stepsByFormName['payment'] && !orderingHasPayment && !payFirst) {
    endSteps.push(stepsByFormName['payment']);
    delete stepsByFormName['payment'];
  }

  // put legalDocs right before success
  Object.keys(stepsByFormName).forEach(stepName => {
    const legalDocsPattern = /legalDocs/g;
    if (stepName.match(legalDocsPattern)) {
      endSteps.push(stepsByFormName[stepName]);
      delete stepsByFormName[stepName];
    }
  });

  if (stepsByFormName['success']) {
    endSteps.push(stepsByFormName['success']);
    delete stepsByFormName['success'];
  }

  // correctly organize middle steps
  const middleSteps = [];

  Object.keys(stepsByFormName).forEach(stepName => {
    const legalDocsPattern = /preLegalDocs/g;

    if (stepName.match(legalDocsPattern)) {
      middleSteps.push(stepsByFormName[stepName]);
      delete stepsByFormName[stepName];
    }
  });

  const defaultDataCollection = {
    data: stepsByFormName['dataCollection'],
    used: false
  };
  delete stepsByFormName['dataCollection'];

  if (payFirst && stepsByFormName['payment']) {
    middleSteps.push(stepsByFormName['payment']);
    delete stepsByFormName['payment'];
  }

  for (const orderName of ordering) {
    if (orderName === 'screenings') {
      Object.keys(stepsByFormName).forEach(element => {
        // payment is not a screening, and shouldn't be added here
        // if payment is included, it will be added in the next if clause
        // if payment is not included it is already added above
        if (element !== 'payment') {
          middleSteps.push(stepsByFormName[element]);
          delete stepsByFormName[element];
        }
      });
    }
    if (stepsByFormName[`${orderName}`]) {
      middleSteps.push(stepsByFormName[orderName]);
      delete stepsByFormName[orderName];
    }
    if (orderName === 'default_data_collection') {
      middleSteps.push(defaultDataCollection.data);
      defaultDataCollection.used = true;
    }
  }
  if (!defaultDataCollection.used) {
    middleSteps.push(defaultDataCollection.data);
    defaultDataCollection.used = true;
  }
  if (Object.keys(stepsByFormName).length > 0) {
    Object.keys(stepsByFormName).forEach(element => {
      middleSteps.push(stepsByFormName[element]);
      delete stepsByFormName[element];
    });
  }
  return [...beginningSteps, ...middleSteps, ...endSteps];
};

export default reorderSteps;
