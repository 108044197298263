import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { Box, Grid } from '@mui/material';
import { AlertCardInfo, Button as ButtonComponent, StatusChip } from '@yardstik/core.components';
import * as S from './style';
import { IMonitorRecords } from '../CandidateDetails/candidateUtils';
import { getMappedHits } from './recordItemsUtils';
import ReportProblemContext from '../CandidateDetails/ReportProblem.context';

const RecordItem = (props: { record: IMonitorRecords }): JSX.Element => {
  const { reportProblemCallback } = useContext(ReportProblemContext);
  const record = props?.record || {};
  const results = props?.record?.results || {};
  const { details: recordDetails = [], hits = [] } = results;

  const [recordHits, setRecordHits] = useState<any>([]);

  useEffect(() => {
    if (hits.length) {
      const mappedHits = getMappedHits(hits);
      setRecordHits(mappedHits);
    }
  }, [hits]);

  return (
    <S.Wrapper data-testid="recordItemContainer">
      {!!record.id && (
        <>
          <Box p={1}>
            <Grid container>
              <Grid item xs={12} sm={6} md={8}>
                <S.Title data-testid="recordItemTitle">
                  {moment(record.createdAt).format('MM/DD/YYYY')} - {recordHits.length} Records Found
                </S.Title>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <S.StatusChipContainer>
                  <StatusChip category="report" status={record.decision} />
                </S.StatusChipContainer>
              </Grid>
            </Grid>
          </Box>
          <Box p={2}>
            <Grid container>
              <Grid data-testid="alertCardInfo-recordDetails" item xs={12} lg={6}>
                <AlertCardInfo fields={recordDetails} />
              </Grid>
            </Grid>
            {record.disputable && (
              <Grid container>
                <Grid xs={12}>
                  <Box width={1} display="flex" justifyContent="flex-end" my={1}>
                    <ButtonComponent
                      color="primary"
                      text="Report an Error"
                      onClick={() => reportProblemCallback(record.id)}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              {!!recordHits.length &&
                recordHits.map((recordHit, index) => (
                  <Grid item xs={12} lg={6} key={`record-hit-${index}`}>
                    <AlertCardInfo
                      fields={recordHit}
                      title={`Record #${index + 1}`}
                      backgroundColor="#F4E1C5"
                      showPresenterTooltip={true}
                      presenterTooltipPlacement="top-start"
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </>
      )}
    </S.Wrapper>
  );
};

export default RecordItem;
