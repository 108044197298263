import React from 'react';
import { Route } from 'react-router-dom';

//TODO: Placeholder for future auth0callback logic
  
const CallbackRoute = (props: any): JSX.Element => {
  const { component: Component, path } = props;

  // Set the render to be equal to the component once the user is authenticated, or if we are not requiring auth0 login
  const render = (props: any) => ( <Component {...props} /> || null );

  return (
    <Route path={path} render={render} />
  ) ;
};
  
export default CallbackRoute;
