import { useRef } from 'react';

import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Lightbox, { ZoomRef } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';

import { Document } from '../../../../redux/reports/types';

type Props = {
  index: string | null;
  open: boolean;
  data: Document[];
  setOpen: (open: boolean) => void;
};

export function LightboxViewer({ data, open, index, setOpen }: Props): JSX.Element {
  const zoomRef = useRef<ZoomRef>(null);

  return (
    <Lightbox
      open={open}
      plugins={[Zoom, Captions]}
      zoom={{ ref: zoomRef, maxZoomPixelRatio: 10 }}
      on={{
        click: () => zoomRef.current?.zoomIn()
      }}
      index={index ? parseInt(index) : 0}
      close={() => setOpen(false)}
      slides={
        data?.map(item => ({
          src: item.url,
          title: `${item.document_type.label} | ${item.filename}`,
          description:
            item.content_type === 'application/pdf' ? 'It is not possible to display PDF files in this view. ' : ''
        })) ?? []
      }
    />
  );
}
