import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { get } from 'lodash';
import { TrainingStepper } from '@yardstik/core.components';
import { submitAnswers } from "../../redux/courses/actions";
import './styles.scss';

const CourseViewer = (props: any): JSX.Element => {
  // Get application data from Redux store. When work flow allows access other than through application, may need to be updated
  // or passed in as props from parent.
  const accountName = useSelector((state) => get(state, 'candidate_application.account.account_name', ''));
  const candidateId = useSelector((state) => get(state, 'candidate_application.candidate.id', ''));
  const logo = useSelector((state) => get(state, 'candidate_application.styles.logo', ''));
  
  // Destructure course data from props
  const { candidateCourse, updateCoursesState } = props;
  const score = get(candidateCourse, 'score', 0);
  const videoUrl = get(candidateCourse, 'course.content.video_url', '');
  const videoId = get(candidateCourse, 'course.content.video_id', '');
  const title = get(candidateCourse, 'course.name', '');
  const questions = get(candidateCourse, 'course.questions', []);
  const totalPoints = get(candidateCourse, 'course.total_points', []);
  const passingScore = get(candidateCourse, 'course.passing_score', 80);
  const candidateCourseId = get(candidateCourse, 'candidate_course_id', {allow_to_retry: false, number_attempts: 0});
  const retry = get(candidateCourse, 'course.content.retry', '');

  // get dispatch for relevant store with hook
  const dispatch = useDispatch();
  
  const onSubmit = (answersToSend: string[]) => {
    const submission = {
      'candidate_id': candidateId,
      'answers': answersToSend,
    };
    submitAnswers(candidateCourseId, submission, dispatch);
  };

  // pass as prop videoId or videoUrl, whichever exists
  let video: null | string = null;
  if (videoId){
    video = videoId;
  } else if (videoUrl) {
    video = videoUrl;
  }

  // Ask Wil to design a better error message here
  if (!video) {
    return (
      <div>Error!</div>
    );
  }

  return ( 
    <div className="courseViewerContainer">
      <TrainingStepper 
        updateCoursesState={updateCoursesState} 
        logo={logo}
        title={title} 
        account={accountName}
        videoId={video} 
        questions={questions}
        score={score}
        totalPoints= {totalPoints}
        passingScore={passingScore}
        retry={retry}
        onSubmit={onSubmit} 
      />
    </div>
  );
};

export default CourseViewer;
