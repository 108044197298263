import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Header, InviteCard, StatusChip } from '@yardstik/core.components';

import { RootState } from '../../../redux/store';

export function Invitation() {
  const { invitation, candidateName } = useSelector((state: RootState) => ({
    invitation: state.reports.reportDetail.invitation,
    candidateName: state.reports.reportDetail.candidate?.full_name
  }));

  if (!invitation) return <React.Fragment />;

  return (
    <Box mt={4}>
      <Box>
        <Header.Root>
          <Header.Head>
            <Header.Title title="Invite" />
            <Header.Statuses>
              <StatusChip status={invitation.status as string} category="invitation" data-testid="invite-status" />
            </Header.Statuses>
          </Header.Head>

          <Header.Footer>
            <Header.Description description={`Yardstik invited ${candidateName} to start their report`} />
          </Header.Footer>
        </Header.Root>
      </Box>

      <InviteCard
        status={invitation.status}
        lastSentOn={invitation.sent_at}
        expiresOn={invitation.expires_at}
        invitedOn={invitation.created_at}
        completedOn={invitation.updated_at}
        canceledOn={invitation.canceled_at}
      />
    </Box>
  );
}
