import { InfoRequestTypes, InfoRequestTypesFileUpload } from '../../../../../../redux/infoRequest/types';

type FileUpload = {
  categoryName: string;
  categoryLabel: string;
  infoRequestedId: string;
} & InfoRequestTypesFileUpload;

export type InfoRequestTypeFileUpload = {
  file_upload: FileUpload[];
  wasCategorizedByInfoRequestTypeId?: boolean;
} & Omit<InfoRequestTypes, 'fields'>;

/**
 * @param infoRequestTypes An array of info request types.
 * @returns returns a list of info requests types
 */
export function groupFileUploadsByInfoRequestId(infoRequestTypes: InfoRequestTypes[]): InfoRequestTypeFileUpload[] {
  const infoRequestTypesGrouped = infoRequestTypes.reduce((acc, infoRequestType) => {
    if (infoRequestType.category === 'screening') {
      const infoRequestTypeId = infoRequestType.id;

      if (acc[infoRequestTypeId]) {
        acc[infoRequestTypeId].wasCategorizedByInfoRequestTypeId = true;

        const previousFileUploads = acc[infoRequestTypeId].file_upload;
        const fileUploadsParsed = infoRequestType.file_upload.map(item => ({
          ...item,
          infoRequestedId: infoRequestType.info_requested_id,
          categoryName: infoRequestType.report_screening.name,
          categoryLabel: infoRequestType.report_screening.label
        }));

        acc[infoRequestTypeId].file_upload = [...previousFileUploads, ...fileUploadsParsed];
      }

      if (!acc[infoRequestTypeId]) {
        acc[infoRequestTypeId] = {
          ...infoRequestType,
          file_upload: infoRequestType.file_upload.map(item => ({
            ...item,
            infoRequestedId: infoRequestType.info_requested_id,
            categoryName: infoRequestType.report_screening.name,
            categoryLabel: infoRequestType.report_screening.label
          }))
        };
      }
    } else {
      acc.push(infoRequestType as never);
    }

    return acc;
  }, []);

  return Object.values(infoRequestTypesGrouped);
}
