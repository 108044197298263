/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-self-assign */
// @ts-nocheck
import { get, findIndex } from 'lodash';
import { ReportResponse, CandidateApplyApplicationData } from '@yardstik/core.components';
import { CandidateData, SectionsData } from './candidateApplicationInterfaces';
import { reorderSteps } from './utils/reorderSteps';
import { setCandidateApplicationLoading } from '../../redux/candidateApply/controls/actions';
import { setComponentFile, setFormSections } from './formSections/reducer';
import {
  SET_CANDIDATE_ACCOUNT_DATA,
  SET_CANDIDATE_APPLICATION_DATA,
  SET_CANDIDATE_APPLICATION_STATUS,
  SET_CANDIDATE_APPLICATION_STEP,
  SET_CANDIDATE_DATA,
  SET_CANDIDATE_STYLES_DATA,
  SET_CANDIDATE_FORM_VALUES
} from './actionTypes';
import { getCandidateReportData, updateCandidateReportData } from '../../services/api/candidates';
import { setPageLoading, setControlsError } from '../controls/actions';
import { getLogoUrl } from '../../utils/logo';
import getAutofillMappingsByName from '../../utils/getAutofillMappingByName';
import { setAutofillMappings } from '../documentVerify/documentVerifySlice';
import { setFeedback } from '../../redux/candidateApply/feedback/reducer';

export interface ApplicationData {
  paid: boolean;
  pii: boolean;
  paidBy: string;
}

export const getApplicationData = async (
  applicationData: CandidateApplyApplicationData,
  dispatch: any,
  applyRouteUrl: string
) =>
  getCandidateReportData(applyRouteUrl)
    .then((response: any) => {
      const { status = '', data = {} } = response;

      if (status === 202) {
        setPageLoading(false);
        return Promise.reject({ response, type: 'IntakeTreatmentError' });
      }

      const autoFillMappings = getAutofillMappingsByName(get(data, 'workflow_steps.screenings', []));
      dispatch(setAutofillMappings(autoFillMappings));
      const resApplicationData = {
        ...data,
        logo: getLogoUrl(data.logo)
      };

      dispatch({
        type: SET_CANDIDATE_APPLICATION_STATUS,
        payload: status || 'available'
      });
      setDataToStore(resApplicationData, applicationData, dispatch);

      setPageLoading(false);
      return response;
    })
    .catch(error => {
      const statusText = get(error, 'statusText', 'error');
      const { status = 404, title = '', detail = '' } = error.response?.data || {};
      const httpStatusText = status >= 400 ? `${status} ERROR` : '';

      dispatch({
        type: SET_CANDIDATE_APPLICATION_STATUS,
        payload: statusText
      });

      dispatch(setFeedback({ title, description: detail, httpStatusText }));

      setPageLoading(false);

      throw error;
    });

export const updateApplicationData = async (
  url: string,
  candidateToSend: any,
  currentStep: number,
  formSectionsSteps: Array<object>,
  application: ApplicationData,
  dispatch: any
) => {
  updateCandidateReportData(url, candidateToSend, dispatch)
    .then(res => {
      Promise.resolve(res);
      return res;
    })
    .catch(err => {
      setControlsError({
        status: 'error',
        message: 'Could not update information. Please contact support'
      });
      return err;
    });
};

const applyOverwriteFilesToComponent = ({ componentName, stepName, file }, dispatch) => {
  const mediaId = file.file_reference;

  const newFile = new File([mediaId], `${componentName}--${mediaId}`, {
    // adding the type as a text/plain so the dropzone will generate the paperclip icon automatically
    type: 'text/plain'
  });

  const customFile = {
    mediaId,
    file: newFile
  };

  dispatch(setComponentFile({ componentName, stepName, newFile: customFile }));
};

export const overwriteStepFilesFromFromValues = (stepsInfo, dispatch) => {
  const stepsWithFiles = Object.keys(stepsInfo)
    .filter(stepName => !!stepsInfo[stepName]?.files)
    .map(stepName => ({
      stepName,
      components: stepsInfo[stepName].files
    }));

  stepsWithFiles.forEach(stepData => {
    const { stepName, components } = stepData;
    Object.keys(components).forEach(componentName => {
      if (!!components[componentName].length) {
        components[componentName].forEach(file =>
          applyOverwriteFilesToComponent({ componentName, stepName, file }, dispatch)
        );
      }
    });
  });
};

export const setDataToStore = async (
  res: ReportResponse,
  applicationData: CandidateApplyApplicationData,
  dispatch: any
) => {
  const {
    account_id = '',
    candidate,
    account = {},
    account_name = '',
    application = {},
    styles = {},
    logo = '',
    workflow_steps = {},
    required_fields = [],
    report_id = ''
  } = res;
  const ordering = get(res, 'application_data.ordering', []);

  const {
    reportId = '',
    accountPackageId = '',
    isService = false,
    isAdditionalInfo = false,
    skipEmailVerification = false
  } = applicationData;

  setCandidateApplicationLoading(true, dispatch);

  dispatch({
    type: SET_CANDIDATE_ACCOUNT_DATA,
    payload: { account_id, account_name: account_name, ...account }
  });

  dispatch({
    type: SET_CANDIDATE_DATA,
    payload: { ...candidate }
  });

  dispatch({
    type: SET_CANDIDATE_APPLICATION_STATUS,
    payload: status ? status : 'available'
  });

  dispatch({
    type: SET_CANDIDATE_APPLICATION_DATA,
    payload: {
      ...application,
      report_id,
      account_package_id: accountPackageId,
      is_service: isService,
      ...res
    }
  });

  dispatch({
    type: SET_CANDIDATE_STYLES_DATA,
    payload: {
      ...styles,
      logo: formatLogoUrl(logo)
    }
  });

  const getSectionsData = formatApplicationDataSections(
    workflow_steps,
    application,
    applicationData,
    required_fields,
    isAdditionalInfo,
    skipEmailVerification,
    ordering
  );

  dispatch(setFormSections({ ...getSectionsData }));

  const getDefaultData = getDefaultFormData(candidate, getSectionsData);

  dispatch({
    type: SET_CANDIDATE_FORM_VALUES,
    payload: { ...getDefaultData }
  });

  overwriteStepFilesFromFromValues({ ...getDefaultData }, dispatch);

  const reportCurrentStepName = get(res, 'report.application_data.current_step', '');
  const reportCurrentStepInnerIndex = get(res, 'report.application_data.current_step_inner_index');

  if (reportCurrentStepName) {
    setCurrentStep(reportCurrentStepName, getSectionsData.steps, dispatch, reportCurrentStepInnerIndex, ordering);
  }

  setCandidateApplicationLoading(false, dispatch);
};

export const setCurrentStep = (stepName, formSteps, dispatch, stepInnerIndex?, ordering?: string[] = []) => {
  let currentStepIncrement = findIndex(formSteps, step => {
    return step.formName === stepName;
  });

  currentStepIncrement = stepInnerIndex ? currentStepIncrement + stepInnerIndex : currentStepIncrement;

  // if find increment of current step we are setting it
  if (currentStepIncrement >= 0) {
    return dispatch({
      type: SET_CANDIDATE_APPLICATION_STEP,
      payload: currentStepIncrement + 1
    });
  }

  // if not find current step
  if (ordering.includes(stepName)) {
    const missingStepOrderingIndex = ordering.findIndex(orderingName => orderingName === stepName);
    const orderingListSize = ordering.length - 1;
    const missingStepIsNotLastStep = orderingListSize > missingStepOrderingIndex;
    const formStepsFirstLegalDocsIndex = formSteps.findIndex(step => step.formName === 'legalDocs');
    const formStepsLastStepIndex = formSteps.length - 1;

    // this mapper will be used when the ordering name is different from the formSteps name
    // by example: ordering.default_data_collection x formSteps.dataCollection
    const orderingToFormStepsMappingName = {
      default_data_collection: 'dataCollection'
    };

    if (missingStepIsNotLastStep) {
      const nextStepName = ordering[missingStepOrderingIndex + 1]; // default_data_collection
      const nextStepFormStepsName = orderingToFormStepsMappingName[nextStepName] ?? nextStepName;
      const nextStepIndex = formSteps.findIndex(step => step.formName === nextStepFormStepsName);

      return dispatch({
        type: SET_CANDIDATE_APPLICATION_STEP,
        payload: nextStepIndex
      });
    }

    const possibleNextStepIndex =
      formStepsFirstLegalDocsIndex > -1 ? formStepsFirstLegalDocsIndex : formStepsLastStepIndex;

    return dispatch({
      type: SET_CANDIDATE_APPLICATION_STEP,
      payload: possibleNextStepIndex
    });
  }

  // finally if not find current step and not in ordering list, it will set the first step
  return dispatch({
    type: SET_CANDIDATE_APPLICATION_STEP,
    payload: 0
  });
};

export const formatLogoUrl = logo => {
  const check = /^https?:\/\//i;
  if (logo) {
    return check.test(logo) ? logo : `${URL}${logo}`;
  } else {
    return '';
  }
};

// Responsible for adding and ordering all steps in the stepper form based on form type and payment requirements.
export const formatApplicationDataSections = (
  workflow_steps = {},
  application = {},
  applicationData: CandidateApplyApplicationData,
  required_fields = [],
  isAdditionalInfo = false,
  skipEmailVerification = false,
  order = []
): unknown => {
  const landing = get(workflow_steps, 'landing', {});
  const dataCollection = get(workflow_steps, 'data_collection', {});
  const screenings = get(workflow_steps, 'screenings', []);
  const preLegalDocuments = workflow_steps?.pre_legal_documents ?? [];
  const legalDocuments = get(workflow_steps, 'legal_documents', []);
  const paymentCollection = get(workflow_steps, 'payment_collection', {});
  const paidBy = get(application, 'paid_by', '');
  const applicationPaid = get(application, 'paid', false);
  const payFirst = get(applicationData, 'payFirst', false);
  const steps = [];

  // only want to add email verification and payment section if is NOT requesting additional info
  // so it is the initial data submission
  if (!isAdditionalInfo && !skipEmailVerification) {
    if (landing) {
      const { title = '', body = '' } = landing;
      steps.push({
        formName: 'landing',
        reorderName: 'landing',
        sectionTitle: title,
        sectionBody: body,
        ...landing
      });
    }

    steps.push({
      formName: 'emailVerification',
      reorderName: 'emailVerification',
      sectionTitle: 'Email Verification',
      sectionBody: ''
    });
  }

  if (dataCollection) {
    const { title = '', body = '' } = dataCollection;

    steps.push({
      sectionTitle: title,
      sectionBody: body,
      ...dataCollection,
      data_collection: {
        ...dataCollection
      },
      formName: 'dataCollection',
      reorderName: 'dataCollection'
    });
  }

  if (screenings.length) {
    const sortedScreenings = sortApplicationSections(screenings);
    sortedScreenings.map(screening => {
      const { label = '', landing = {}, data_collection = {} } = screening;

      const { body: landingBody = '', 'mobile-body': sectionBodyMobile = '' } = landing;

      const { components = [], error_modals = [] } = data_collection;

      steps.push({
        formName: screening.name,
        reorderName: screening.name,
        components,
        error_modals,
        sectionBody: landingBody,
        sectionBodyMobile,
        sectionTitle: label,
        ...screening
      });
    });
  }

  // if report is paid by the candidate we add in the payment step.
  if (paidBy === 'candidate' && !applicationPaid) {
    // checking paid first value. If LD flag is paid first we add the payment in the beginging and if not it should be last.
    if (payFirst && !skipEmailVerification) {
      steps.splice(2, 0, {
        formName: 'payment',
        reorderName: 'payment',
        ...paymentCollection
      });
    }
    if (payFirst && skipEmailVerification) {
      steps.unshift({
        formName: 'payment',
        reorderName: 'payment',
        ...paymentCollection
      });
    } else {
      steps.push({
        formName: 'payment',
        reorderName: 'payment',
        ...paymentCollection
      });
    }
  }

  if (preLegalDocuments.length) {
    preLegalDocuments.map((legalDocument, index) => {
      const { type = '', title = '', components = [], require_signature = false } = legalDocument;

      steps.push({
        formName: type,
        reorderName: `preLegalDocs${index}`,
        innerIndex: index,
        components,
        sectionTitle: title,
        requestSignature: require_signature
      });
    });
  }

  if (legalDocuments.length) {
    legalDocuments.map((legalDocument, index) => {
      const { type = '', title = '', components = [] } = legalDocument;

      const isLastItem = index === legalDocuments.length - 1;
      // only set request signature to true if it is the last legalDocuments group
      const requestSignature = !!isLastItem;

      steps.push({
        formName: type,
        reorderName: `${type}${index}`,
        innerIndex: index,
        components,
        sectionTitle: title,
        requestSignature
      });
    });
  }

  steps.push({
    formName: 'success',
    reorderName: 'success',
    title: 'Information Submitted',
    body: ` Thank you for completing the information and authorization needed to begin processing your background
            screening. <br />
            Upon completion of the screening process, you will receive an additional email with a link to your
            background check should you want to have a copy for your records. <br />`
  });

  // reorder steps that need to go before default data collection
  const reorderdSteps = reorderSteps(steps, order, payFirst);

  return { required_fields: required_fields, steps: reorderdSteps };
};

export const sortApplicationSections = (screenings: Array<object>) => {
  return screenings.sort((a, b) => (a.order > b.order ? 1 : -1));
};

export const getDefaultFormData = (candidate: CandidateData, getSectionsData: SectionsData): unknown => {
  const formSections = {};

  const {
    email = '',
    first_name = '',
    middle_name = '',
    last_name = '',
    phone = '',
    date_of_birth = '',
    driver_license_number = '',
    driver_license_state = '',
    additional_data = {},
    no_middle_name = '',
    addresses = [],
    guardian = {}
  } = candidate;

  const { steps = [] } = getSectionsData;

  // the candidate data route for the candidate profile sends address data as
  //an object with an array called data and apply route sends addresses as just an array.
  let addressesToSend = Array.isArray(addresses) ? addresses : get(addresses, 'data', [{}]);

  //checking if empty array
  addressesToSend.length === 0 ? (addressesToSend = [{}]) : (addressesToSend = addressesToSend);

  const { email: guardian_email, first_name: guardian_first_name, last_name: guardian_last_name } = guardian;

  // building out default form data???
  steps &&
    steps.map(section => {
      const { formName = '' } = section;
      if (formName === 'emailVerification') {
        formSections[formName] = { email };
      } else if (formName === 'dataCollection') {
        formSections[formName] = {
          first_name,
          middle_name,
          last_name,
          phone,
          date_of_birth,
          addresses: addressesToSend,
          no_middle_name,
          guardian_email,
          guardian_first_name,
          guardian_last_name
        };
      } else if (formName === 'InstantDriving') {
        formSections[formName] = {
          driver_license_number,
          driver_license_state
        };
      } else {
        if (additional_data[formName]) {
          formSections[formName] = {
            ...additional_data[formName]
          };
        } else {
          formSections[formName] = {};
        }
      }
    });
  return formSections;
};

export const setReportErrorToStore = (
  res: any,
  applicationData: CandidateApplyApplicationData,
  dispatch: any
): unknown => {
  const { status = 404 } = res;

  dispatch({
    type: 'SET_CANDIDATE_ACCOUNT_DATA',
    payload: { account_id, account_name: account_name, ...account }
  });
  dispatch({
    type: 'SET_CANDIDATE_APPLICATION_STATUS',
    payload: status ? status : 'unavailable'
  });

  if (status === 404) {
    updateApplicationData({
      title: 'Oh No! This invitation is not found',
      bodyL: 'It looks like the invitatin you are looking for can not be found.'
    });
  }
};
