import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../services/api/rtk-query';
import { IdentityVerificationType } from '../types';

type getIdentityVerificationType = {
  id: string;
  candidateId: string;
}

export const identityVerificationApi = createApi({
  baseQuery,
  reducerPath: 'identityVerificationApi',
  endpoints: builder => ({
    getIdentityVerification: builder.query<IdentityVerificationType, getIdentityVerificationType>({
      query: ({candidateId, id}) => ({ url: `candidate-profile/identity-verifications/${id}`, headers: { 'candidate-id': candidateId }}),
    }),
  }),
});

export const { useGetIdentityVerificationQuery } = identityVerificationApi;
