import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import { Button, Card, CardContent, CircularLogo, StepFormLogoFooter, useTheme } from '@yardstik/core.components';

import { getLogoUrl } from '../../utils/logo';
import { RootState } from '../../redux/store';

const CandidateApplyFeedbackPage = (): JSX.Element => {
  const theme = useTheme();

  const history = useHistory();

  const feedbackState = useSelector((state: RootState) => state.candidate_application.feedback);

  const { account, pageInfo } = feedbackState;

  const { title = '', description = '', httpStatusText = '' } = pageInfo;

  const accountLogo = account?.logo
    ? getLogoUrl(account?.logo)
    : 'https://yardstik-assets.s3.amazonaws.com/logos/yardstik-wordmark-black.svg';

  const handleViewProfile = () => history.push('/');

  return (
    <Box
      pt={18}
      data-testid="CandidateApplyFeedbackPage"
      sx={{ backgroundColor: theme.palette.grey[100], height: 'inherit' }}
    >
      <Container maxWidth="md">
        <Box sx={{ mb: 8 }}>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                left: 0,
                right: 0,
                width: '200px',
                margin: 'auto',
                position: 'absolute',
                top: 'calc(50% - 100px)'
              }}
              data-testid="CandidateApplyFeedbackPage--logo"
            >
              <CircularLogo customStyle={{ backgroundColor: theme.palette.grey[100] }} logo={accountLogo} />
            </Box>
          </Box>

          <Card>
            <CardContent sx={{ p: 8, pb: `64px !important` }}>
              <Box mt={8}>
                {!!httpStatusText && (
                  <Box sx={{ marginBottom: '12px' }}>
                    <Typography variant="overline">{httpStatusText}</Typography>
                  </Box>
                )}

                <Box component={'div'} style={{ marginBottom: '8px' }}>
                  <Typography variant="h3" mb={1}>
                    {title}
                  </Typography>

                  <Typography
                    mb={4}
                    variant="body1"
                    sx={{
                      '& a': {
                        color: theme.palette.primary.main,
                        [theme.breakpoints.down('md')]: { wordBreak: 'break-all' }
                      }
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Box>

                <Box>
                  <Button text="view profile" color="primary" onClick={handleViewProfile} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <StepFormLogoFooter />
      </Container>
    </Box>
  );
};

export default CandidateApplyFeedbackPage;
