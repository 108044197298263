import React, { Children, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import { Loader } from '@yardstik/core.components';

import { useAuth0 } from '../../contexts/auth0/auth0-context';

const useStyles = makeStyles({
  root: {
    '& .MuiSnackbar-root .MuiAlert-message': {
      paddingRight: '16px'
    },
    '& .MuiSnackbar-root .MuiButtonBase-root': {
      position: 'absolute',
      top: '2px',
      right: '2px'
    }
  }
});

export const Auth0Callback = (props: any): JSX.Element => {

  const { error } = useAuth0();

  const pageLoading = useSelector(state => get(state, 'controls.loading', false));

  const classes = useStyles(props);

  if (pageLoading) {
    return <Loader spinnerColor="primary" />;
  }

  if (error) {
    return (
      <div className={`initialReportApply error ${classes.root}`}>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`initialReportApply ${classes.root}`}></div>
  );
};
