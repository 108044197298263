import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import ReportView from '../ReportView';
import { ReportViewInterface } from '../ReportView/reportViewInterface';
import ReportInfoRequestedModal from './ReportInfoRequestedModal';
import { getReportEditInfo } from '../ReportView/reportViewUtils';
import { setControlsError } from '../../redux/controls/actions';
import { setErrorMessage } from '../../utils/errorHandlingUtils';

function ReportViewInfoRequested(props: ReportViewInterface): JSX.Element {
  const dispatch = useDispatch();

  const candidateId = get(props, 'match.params.candidate_id', '');
  const selectedReportId = get(props, 'match.params.report_id', '');
  const report = useSelector(state => get(state, 'reports.reportDetail', null));
  const reportStatus = get(report, 'status');

  const [modalError, setModalError] = useState<any>(null);

  const fetchApplicationData = useCallback(async () => {
    try {
      await getReportEditInfo(report, selectedReportId, dispatch, candidateId);
      setModalOpen(true);
      dispatch({
        type: 'SET_CANDIDATE_APPLICATION_LOADING',
        payload: false
      });
    } catch (e) {
      setControlsError({
        status: 'error',
        message: 'Failed to fetch required data'
      });
      dispatch({
        type: 'SET_CANDIDATE_APPLICATION_LOADING',
        payload: false
      });
      const errorMessage = setErrorMessage(
        e,
        'Error loading information requested page. Please refresh and try again or contact support@yardstik.com.'
      );
      setModalError(errorMessage);
    }
  }, [candidateId, dispatch, report, selectedReportId]);

  // setting modal open on page load if the status is info requested
  useEffect(() => {
    if (reportStatus === 'info_requested') {
      setModalOpen(true);
      fetchApplicationData();
    }
  }, [candidateId, dispatch, fetchApplicationData, report, reportStatus, selectedReportId]);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ReportView />
      <ReportInfoRequestedModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        candidateId={candidateId}
        reportId={selectedReportId}
        modalError={modalError}
        refetchApplyData={fetchApplicationData}
      />
    </>
  );
}

export default ReportViewInfoRequested;
