import { getCoursesResults, submitCourseAnswers } from '../../services/api/courses';
import { setControlsError } from '../controls/actions';
import { 
  GET_COURSES, 
  GET_COURSES_STATE,
  GET_COURSES_ERROR, 
  UPDATE_COURSES,
  UPDATE_COURSES_STATE,
  UPDATE_COURSES_ERROR,  
} from './actionTypes';

// Actions
export const getCourses = async (
  candidateId: string,
  reportId: string,
  dispatch: any,
) => {
  try {
    dispatch({
      type: GET_COURSES_ERROR,
      payload: false,
    });
    dispatch({
      type: GET_COURSES_STATE,
      payload: 'LOADING',
    });
    const getCoursesResponse = await getCoursesResults(candidateId, reportId);
    dispatch({
      type: GET_COURSES,
      payload: getCoursesResponse,
    });
    dispatch({
      type: GET_COURSES_STATE,
      payload: 'READY',
    });
  } catch (e) {
    setControlsError({
      status: 'error',
      message: 'Failed to fetch required data',
    });
    dispatch({
      type: GET_COURSES_ERROR,
      payload: e,
    });
    dispatch({
      type: GET_COURSES_STATE,
      payload: 'ERROR',
    });
  }
};

export const submitAnswers = async (
  candidateCourseId: string,
  submission: any,
  dispatch: any,
) => {
  dispatch({
    type: UPDATE_COURSES_ERROR,
    payload: false,
  });
  dispatch({
    type: UPDATE_COURSES_STATE,
    payload: 'LOADING',
  });
  try {
    const submitAnswersResponse = await submitCourseAnswers(candidateCourseId, submission);
    dispatch({
      type: UPDATE_COURSES,
      payload: submitAnswersResponse,
    });
    dispatch({
      type: UPDATE_COURSES_STATE,
      payload: "READY",
    });
  } catch (e) {
    setControlsError({
      status: 'error',
      message: 'Failed to send answer responses. Please contact support.',
    });
    dispatch({
      type: UPDATE_COURSES_ERROR,
      payload: e,
    });
    dispatch({
      type: UPDATE_COURSES_STATE,
      payload: "ERROR",
    });
  }
};
