import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../../redux/store';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    let token = (getState() as RootState).auth.token;

    if (!token) {
      token = localStorage.getItem('token');
    }

    headers.set('authorization', `Bearer ${token}`);

    return headers;
  }
});
