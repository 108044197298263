/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux';
import { SET_CANDIDATE_STYLES_DATA } from '../actionTypes';

export type Styles = {
  invitation: {primary_color: string},
  logo: string,
  navigation: {
    primary_color: string, 
    secondary_color: string, 
    accent_color: string
  },
  primary_color: string,
  secondary_color: string,
}

const initialState: Styles | {} = {};

export default (state = initialState, action: AnyAction) => { 
  switch (action.type) {
  case SET_CANDIDATE_STYLES_DATA:
    return {
      ...state,
      ...action.payload,
    };
  default:
    return state;
  }
};
