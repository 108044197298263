import { isEmpty } from 'lodash';
import {
  getTokenExprieAt,
  getTokenIssuedAt,
  getTokenScope,
  getTokenCustomMetadataCandidateIds,
  getTokenAudience
} from './jwt';

import {
  setLocalStorage,
  removeLocalStorage,
  getLocalStorage
} from '../../utils/browserStorage';

type TAuthTokenObj = {
  body:
  {
    access_token: string;
    expires_in: number | undefined;
    token_type: string | undefined,
    scope: string | undefined;
    audience: string | undefined;
  }
  expires_at: number | undefined;
};

//TODO: Write code to handle Refreshing tokens const handleRefreshToken  = ():void => {};
export const getTokenKeyNamespace = (): string => {
  const tokenKeyNamespace = `@@yardstik::${process.env.REACT_APP_AUTH0_AUDIENCE}::@@access`;

  return tokenKeyNamespace;
};

export const getAuth0TokenKeyNamespace = (): string => {
  const tokenKeyNamespace = `@@auth0spajs@@::M0BigVEIKBTNNmtqLjQOcckepZDJ94Ea::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid profile email`;


  return tokenKeyNamespace;
};

const getExpricesInTimeStamp = (issued_at_at, expires_at): number | undefined => {

  const diffTime = Math.abs(expires_at - issued_at_at);

  return diffTime;
};


const createUserAccessToken = (responseAccessToken: string): TAuthTokenObj => {
  const issued_at = getTokenIssuedAt(responseAccessToken);
  const expires_at = getTokenExprieAt(responseAccessToken);
  const scope = getTokenScope(responseAccessToken);
  const expires_in = getExpricesInTimeStamp(issued_at, expires_at);
  const authToken = {
    body: {
      access_token: responseAccessToken,
      scope,
      expires_in,
      token_type: "Bearer",
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`
    },
    expires_at,
  };

  return authToken;
};

const isAuthTokenValid = (): boolean => {
  const currentAccessToken = getLocalStorage(getTokenKeyNamespace());
  const expires_at = currentAccessToken?.expires_at;
  const currentTimeStamp = Math.round(Date.now() / 1000);

  return currentTimeStamp < expires_at;
};

const isAccessTokenSet = (): boolean => {
  const currentAccessToken = getLocalStorage(getTokenKeyNamespace());
  const access_token = currentAccessToken?.body?.access_token;
  const expires_at = currentAccessToken?.expires_at;

  return access_token && expires_at ? true : false;
};

const getCurrentAccessTokenKey = (currentAuthenicationFlow: string): string => {
  let currentAccessTokenKey = '';

  currentAccessTokenKey = currentAuthenicationFlow === 'REDIRECT' ? getAuth0TokenKeyNamespace() : getTokenKeyNamespace();
  return currentAccessTokenKey;
};

export const isAuthTokenIssuedForCurrentUser = (canidateId: string, currentAuthenicationFlow: string): boolean => {

  const currentAccessTokenKey = getCurrentAccessTokenKey(currentAuthenicationFlow);
  const currentAccessToken = getLocalStorage(currentAccessTokenKey);
  const currentJwtToken = currentAccessToken?.body?.access_token;
  const currentAccessTokenCustomMetaDataCandidateIds = getTokenCustomMetadataCandidateIds(currentJwtToken);
  const isTokenIssuedForCurrentUser = currentAccessTokenCustomMetaDataCandidateIds?.includes(canidateId);


  return isTokenIssuedForCurrentUser;
};


export const isAuthTokenIssuedForCurrentAudience = (currentAuthenicationFlow: string): boolean => {

  const currentAccessTokenKey = getCurrentAccessTokenKey(currentAuthenicationFlow);
  const currentAccessToken = getLocalStorage(currentAccessTokenKey);
  const audience = getTokenAudience(currentAccessToken?.body?.access_token);
  const currentAudience = `${process.env.REACT_APP_AUTH0_AUDIENCE}`;

  const isAuthTokenIssuedForCurrentAudience = (audience === currentAudience);


  return isAuthTokenIssuedForCurrentAudience;
};


const setStoreAuthToken = (authToken: TAuthTokenObj) => {

  setLocalStorage(getTokenKeyNamespace(), authToken);
};

const handleAuthentication = (responseAccessToken: string) => {
  if (responseAccessToken) {
    const userAccessToken = createUserAccessToken(responseAccessToken);

    setStoreAuthToken(userAccessToken);
  } else {
    console.log('No Access Token in API response Payload');
  }
};


const hasValidAccessToken = (): boolean => {
  const tokenIsSet = isAccessTokenSet();
  const tokenIsValid = isAuthTokenValid();

  return tokenIsSet && tokenIsValid;
};


export const isLoggedInWithAuth0RedirectFlow = (): boolean => {

  const currentAccessToken = getLocalStorage(getTokenKeyNamespace());

  return isEmpty(currentAccessToken);
};


export const authServiceLogIn = (responseAccessToken: string | null): void => {

  if (!responseAccessToken) {
    return;
  }

  handleAuthentication(responseAccessToken);
};

export const authServiceLogOut = (currentAuthenicationFlow: string): void => {

  const currentAccessTokenKey = getCurrentAccessTokenKey(currentAuthenicationFlow);

  removeLocalStorage(currentAccessTokenKey);
};
export const authServiceIsAuthenticated = (): boolean => {

  return hasValidAccessToken();
};
export const authServiceGetAccessToken = (): string | null => {
  const currentAccessToken = getLocalStorage(getTokenKeyNamespace());

  const access_token = currentAccessToken?.body?.access_token;

  return access_token;
};

export default authServiceIsAuthenticated;

