import { createApi } from '@reduxjs/toolkit/query/react';

import { InfoRequestType } from '../types';
import { baseQuery } from '../../../services/api/rtk-query';

type createDocumentType = {
  formData: FormData;
  candidateId: string;
};

export const documentsApi = createApi({
  baseQuery,
  reducerPath: 'documentsApi',
  endpoints: builder => ({
    createDocument: builder.mutation<InfoRequestType, createDocumentType>({
      query: ({ candidateId, formData }) => ({
        body: formData,
        method: 'POST',
        url: `candidate-profile/documents`,
        headers: { 'candidate-id': candidateId }
      })
    })
  })
});

export const { useCreateDocumentMutation } = documentsApi;
