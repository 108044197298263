import { useEffect, useState } from 'react';

export function usePrint() {
  const [isPrint, setIsPrint] = useState(false);
  const [forceOpenAccordions, setForceOpenAccordions] = useState(false);

  useEffect(() => {
    if (isPrint) {
      window.print();
      setIsPrint(false);
    }
  }, [isPrint]);

  const printReport = () => {
    setForceOpenAccordions(true);

    setTimeout(() => {
      setIsPrint(true);
      setForceOpenAccordions(false);
    }, 1000);
  };

  return { printReport, forceOpenAccordions };
}
