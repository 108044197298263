import { AnyAction } from 'redux';
import { LIST_CANDIDATES, LIST_CANDIDATES_LOADING_ERROR } from './actionTypes';

// Initial State
const initialState = {
  candidates: [],
  loadingError: false,
};

// Reducer
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case LIST_CANDIDATES_LOADING_ERROR:
    return {
      ...state,
      loadingError: action.payload,
    };
  case LIST_CANDIDATES:
    return {
      ...state,
      candidates: action.payload,
    };
  default:
    return state;
  }
};
