import { createApi } from '@reduxjs/toolkit/query/react';

import { InfoRequestType } from '../types';
import { baseQuery } from '../../../services/api/rtk-query';
import { UpdateInfoRequestPayload } from './types';

type getInfoRequestType = {
  candidateId: string;
  infoRequestId: string;
};

export const infoRequestApi = createApi({
  baseQuery,
  reducerPath: 'infoRequestApi',
  endpoints: builder => ({
    getInfoRequest: builder.query<InfoRequestType, getInfoRequestType>({
      query: ({ candidateId, infoRequestId }) => ({
        url: `candidate-profile/info-requests/${infoRequestId}`,
        headers: { 'candidate-id': candidateId }
      })
    }),
    updateInfoRequest: builder.mutation<InfoRequestType, UpdateInfoRequestPayload>({
      query: ({ candidateId, infoRequestId, payload }) => ({
        url: `candidate-profile/info-requests/${infoRequestId}`,
        body: payload,
        method: 'PATCH',
        headers: { 'candidate-id': candidateId }
      })
    })
  })
});

export const { useGetInfoRequestQuery, useUpdateInfoRequestMutation } = infoRequestApi;
