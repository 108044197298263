/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const updateApplicationData = (value, dispatch: any) => {
  dispatch({
    type: 'SET_CANDIDATE_APPLICATION_DATA',
    payload: value,
  });
};
export const setApplicationPaid = (value, dispatch: any) => {
  dispatch({
    type: 'SET_CANDIDATE_APPLICATION_PAID',
    payload: value,
  });
};
export const setPaymentInfoLoading = (value, dispatch: any) => {
  dispatch({
    type: 'SET_PAYMENT_INFO_LOADING',
    payload: value,
  });
};
export default updateApplicationData;
