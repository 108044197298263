import { Theme } from '@yardstik/core.components';
import { Box, styled, Typography } from '@mui/material';

const imageWidth = {
  smaller: '100px',
  bigger: '150px',
};

export const Img = styled('img')({
  width: imageWidth.smaller,
  height: '100px',
  objectFit: 'contain',
  '@media(min-width: 468px)': {
    width: imageWidth.bigger,
  },
});

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  padding: '20px',
});

export const ImgBlock = styled(Box)({
  width: imageWidth.smaller,
  '@media(min-width: 468px)': {
    width: imageWidth.bigger,
  },
});

export const ContentBlock = styled(Box)({
  width: `calc(100% - ${imageWidth.smaller})`,
  display: 'flex',
  paddingLeft: '10px',
  '@media(min-width: 468px)': {
    width: `calc(100% - ${imageWidth.bigger})`,
  },
});

export const CallToAction = styled(Box)({
  alignItems: 'flex-end',
  display: 'flex',
  height: '100%',
  justifyContent: 'flex-end',
});

export const Heading = styled(Typography)({
  fontSize: '20px',
  fontWeight: 'bold',
});

export const TextContent = styled(Typography)({
  fontSize: '18px',
  color: Theme.colors['red--80'],
});

export const Container = styled(Box)({
  padding: '20px',
  maxWidth: '640px',
  margin: '0 auto',
});
