import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';

import { useCreateDocumentMutation } from '../../../../../redux/documents/requests';
import { UpdateInfoRequest } from '../../../../../redux/infoRequest/requests/types';
import { setNotification } from '../../../../../redux/notification/notificationSlice';
import { useUpdateInfoRequestMutation } from '../../../../../redux/infoRequest/requests';
import { InfoRequestType, InfoRequestTypes } from '../../../../../redux/infoRequest/types';
import { InfoRequestType as InfoRequestDocumentType } from '../../../../../redux/documents/types';

import { groupFileUploadsByInfoRequestId, InfoRequestTypeFileUpload } from './utils/groupFileUploadsByInfoRequestId';

type Props = {
  children: ReactNode;
  data: InfoRequestType;
  modal: {
    isOpen: boolean;
    onClose: (event?: object, reason?: string) => void;
  };
};

type FormValues = {
  formValues: any;
  handleSubmit: () => void;
  updateFormValues: Record<string, File[]>;
};

type onChangeProps = {
  files: File[];
  inputName: string;
  documentType: string;
  infoRequestedId: string;
};

type FormContextData = {
  modal: {
    isOpen: boolean;
    onClose: (event?: object, reason?: string) => void;
  };
  form: {
    isLoading: boolean;
    onSubmit: () => void;
    submitError: boolean;
    fileError: Record<string, string>;
    ysFormRef: React.MutableRefObject<{ [key: string]: FormValues } | {}>;
    fileUploadSections: InfoRequestTypeFileUpload[];
    fieldsSections: Omit<InfoRequestTypes, 'file_upload'>[];
    onChangeFiles: ({ inputName, files }: onChangeProps) => void;
  };
};

const InfoRequestFormContext = createContext<FormContextData>({} as FormContextData);

export function useInfoRequestForm(): FormContextData {
  const context = useContext(InfoRequestFormContext);

  return context;
}

export function InfoRequestFormProvider({ data, modal, children }: Props): JSX.Element {
  const ysFormRef = useRef({});

  const dispatch = useDispatch();

  const { candidate_id: candidateId } = useParams<{ candidate_id: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [fileError, setFileError] = useState<Record<string, string>>({});
  const [fileMap, setFileMap] = useState<
    Record<string, { files: File[]; infoRequestedId: string; documentType: string }>
  >({});

  const [createDocument] = useCreateDocumentMutation();
  const [updateInfoRequest] = useUpdateInfoRequestMutation();

  /**
   * Remove errors when a new file is uploaded
   */
  useEffect(() => {
    setFileError(prevFileError => {
      const errors = Object.keys(prevFileError).reduce((acc, key) => {
        if (!fileMap[key]) {
          acc[key] = prevFileError[key];
        }
        return acc;
      }, {} as Record<string, string>);

      return errors;
    });
  }, [fileMap]);

  const onChangeFiles = ({ inputName, files, infoRequestedId, documentType }: onChangeProps) => {
    setFileMap(prevFileMap => ({
      ...prevFileMap,
      [inputName]: { files, infoRequestedId, documentType }
    }));
  };

  const onSubmit = async () => {
    const infoRequested = [] as UpdateInfoRequest[];

    const hasErrors: Array<{ [key: string]: string }> = [];

    const calls = {
      uploadFiles: [] as (() => Promise<InfoRequestDocumentType>)[],
      infoRequestedData: () => ({})
    };

    data.info_request_types.forEach(group => {
      if (ysFormRef.current[group.id]) {
        ysFormRef.current[group.id].handleSubmit();

        const formHasErrors = Object.keys(ysFormRef.current[group.id].formErrors).length;

        if (formHasErrors) hasErrors.push(ysFormRef.current[group.id].formErrors);

        if (!formHasErrors) {
          infoRequested.push({
            info_requested_id: group.info_requested_id,
            fields: Object.entries(ysFormRef.current[group.id].formValues).map(([name, value]) => ({
              name,
              value: value as string
            }))
          });
        }
      }
    });

    const updateInfo = () =>
      updateInfoRequest({ candidateId, infoRequestId: data.id, payload: infoRequested }).unwrap();

    calls.infoRequestedData = updateInfo;

    if (Object.keys(fileMap).length) {
      const errors = Object.keys(fileMap).reduce((acc, key) => {
        if (!fileMap[key].files.length) {
          acc[key] = 'Please upload a file';
        }

        return acc;
      }, {} as Record<string, string>);

      if (Object.keys(errors).length) {
        setFileError(prevFileError => ({
          ...prevFileError,
          ...errors
        }));

        hasErrors.push(errors);

        return;
      }

      for (const [, { files, infoRequestedId, documentType }] of Object.entries(fileMap)) {
        for (const file of files) {
          const createDoc = () => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('resource_id', infoRequestedId);
            formData.append('document_type', documentType);
            formData.append('resource_type', 'InfoRequested');

            return createDocument({
              formData,
              candidateId
            }).unwrap();
          };

          calls.uploadFiles.push(createDoc);
        }
      }
    }

    if (hasErrors.length) return;

    try {
      setIsLoading(true);

      if (calls.uploadFiles.length) {
        await Promise.all(calls.uploadFiles.map(httpCall => httpCall()));
        await calls.infoRequestedData();
      } else {
        await calls.infoRequestedData();
      }

      modal.onClose();

      dispatch(setNotification({ severity: 'success', text: `Information submitted` }));
    } catch (error) {
      setSubmitError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fieldsSections = data?.info_request_types?.filter(group => group?.fields?.length);
  const fileUploadSections = data?.info_request_types?.filter(group => group?.file_upload?.length);

  return (
    <InfoRequestFormContext.Provider
      value={{
        modal,
        form: {
          onSubmit,
          isLoading,
          fileError,
          ysFormRef,
          submitError,
          onChangeFiles,
          fieldsSections,
          fileUploadSections: groupFileUploadsByInfoRequestId(fileUploadSections)
        }
      }}
    >
      {children}
    </InfoRequestFormContext.Provider>
  );
}
