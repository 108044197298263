import { useState } from 'react';
import { DescriptionTwoTone, Print } from '@mui/icons-material';
import { readableDate, Modal } from '@yardstik/core.components';
import { Box, Grid, Typography, Button as MuiButton } from '@mui/material';

type LegalDocs = {
  id: string;
  signed_at: string;
  signed_by: string;
  text: string;
  title: string;
};

type AttachmentsProps = {
  signedDocuments: LegalDocs[];
};

export function LegalDocs({ signedDocuments }: AttachmentsProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState({
    text: '',
    signed_at: '',
    signed_by: ''
  });

  if (!signedDocuments?.length)
    return (
      <Box textAlign="center" py="102px">
        <Typography variant="h6" color="grey.800">
          No legal docs
        </Typography>
        <Typography variant="body1" color="grey.700">
          Once the legal disclosures and agreements have been signed, they'll appear here
        </Typography>
      </Box>
    );

  return (
    <Box p={3}>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          maxWidth="md"
          primaryAction={{
            color: 'primary',
            disabled: false,
            onClick: () => {
              setIsModalOpen(false);
            },
            text: 'OK'
          }}
          title={modalTitle}
        >
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MuiButton
                aria-label="print"
                onClick={() => {
                  window.print();
                }}
              >
                <Print />
              </MuiButton>
            </Box>

            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: modalContent.text ?? ''
              }}
            />

            {modalContent.signed_by ? (
              <Box sx={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
                <Typography variant="body2">Signed: {modalContent.signed_by}</Typography>
                <Typography variant="body2">Timestamp: {readableDate(modalContent.signed_at)}</Typography>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
                <Typography variant="body2">Agreed on: {readableDate(modalContent.signed_at)}</Typography>
              </Box>
            )}
          </Box>
        </Modal>
      )}

      <Grid container>
        {!!signedDocuments?.length &&
          signedDocuments.map(({ signed_at, title, text, signed_by }, index: number) => (
            <Grid container key={index} mb={index < signedDocuments.length - 1 ? 2 : 0}>
              <Grid item sm={2}>
                <Typography variant="body2" color="grey.500">
                  {readableDate(signed_at)}
                </Typography>
              </Grid>

              <Grid item sm={10}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="body2"
                    color="grey.700"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setModalTitle(title);
                      setModalContent({
                        text: text,
                        signed_by: signed_by,
                        signed_at: signed_at
                      });
                      setIsModalOpen(true);
                    }}
                  >
                    {title}
                  </Typography>

                  <DescriptionTwoTone
                    sx={{
                      height: '12px',
                      color: 'grey.700'
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
