import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Loader } from '@yardstik/core.components';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import { getListCandidates } from '../../redux/candidates/actions';
import { getReportsList } from '../../redux/reports/actions';
import LoadingError from '../LoadingError';
import * as S from './style';

const CandidateListing = (): JSX.Element => {
  const candidateList = useSelector(
    (state: { reports: any; candidates: any }) =>
      state.candidates.candidates
  );
  const candidateListLoadingError = useSelector(
    (state: { reports: any; candidates: any }) =>
      state.candidates.loadingError
  );
  const [candidateListState, setCandidateListState] = useState('LOADING');
  const logoBaseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getListCandidates();
  }, []);

  useEffect(() => {
    if (candidateList.length >= 1) {
      setCandidateListState('READY');
    }
  }, [candidateList]);

  useEffect(() => {
    if (candidateList.length >= 1) {
      getReportsList(candidateList);
    }
  }, [candidateList]);

  if (candidateListState === 'LOADING') {
    return (
      <Loader
        logo='https://yardstik-assets.s3.amazonaws.com/logos/yardstik-black.svg'
        spinnerColor='primary'
      />
    );
  }

  if (candidateListLoadingError === true) {
    return <LoadingError />;
  }

  return (
    <S.Container>
      <Grid container spacing={2}>
        <Grid item>
          <h1>
            Hi, {candidateList[candidateList.length - 1].first_name}
            . &nbsp;Welcome to Yardstik!
          </h1>
        </Grid>
        {candidateList.length && candidateList.map((candidate, index) => {
          const { account = {} } = candidate;
          const { account_name = '', logo = '' } = account;
          const accountLogo = !!logo ? `${logoBaseURL}${logo}` : '';

          return (
            <Grid item xs={12} key={`account-item-${index}`}>
              <Paper elevation={1}>
                <S.Wrapper>
                  <S.ImgBlock>
                    {!!accountLogo && (
                      <S.Img src={accountLogo} alt="" />
                    )}
                  </S.ImgBlock>
                  <S.ContentBlock>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <S.Heading>{account_name}</S.Heading>
                      </Grid>

                      <Grid item xs={12}>
                        <S.CallToAction>
                          <Button
                            text="view"
                            color="primary"
                            component={RouterLink}
                            to={`/candidates/${candidate.id}/details`}
                            data-testid="goToCandidateDetailsButton"
                          />
                        </S.CallToAction>
                      </Grid>
                    </Grid>
                  </S.ContentBlock>
                </S.Wrapper>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </S.Container>
  );
};
export default CandidateListing;
