/* eslint-disable no-dupe-keys */
import { Box, styled, Typography } from '@mui/material';
import { Theme } from '@yardstik/core.components';

export const PageHeading = styled('div')({
  color: '#333',
  fontSize: '28px',
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px'
});

export const Heading = styled(Typography)({
  color: '#333',
  fontSize: '20px',
  fontWeight: 700
});

export const ErrorAlert = styled('div')({
  marginTop: '200px',
  padding: '50px',
  textAlign: 'center',
  fontFamily: 'SFProDisplay',
  fontSize: '20px'
});

export const Wrapper = styled(Box)({
  padding: '20px'
});

export const ErrorText = styled('span')({
  color: Theme.colors['red--80']
});

export const PrintableWrapper = styled(Box)({
  width: '100%',
  '@media print': {
    display: 'block',
    fontSize: '12pt!important',
    '@page': {
      margin: '10mm!important'
    },
    '& .MuiGrid-grid-lg-6': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  }
});

export const PrintableFooter = styled(Box)({
  width: '100%',
  display: 'none',
  paddingTop: '20px',
  '@media print': {
    display: 'block',
    fontSize: '12pt!important'
  }
});
