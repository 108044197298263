import { Box } from '@material-ui/core';
import React from 'react';
import * as S from './style';

type SupportContactProps = {
  showCustomerContact?: boolean;
  showYardstikContact?: boolean;
  showReportErrorButton?: boolean;
  reportErrorCallback?: () => void;
  customerEmail?: string;
  customerName?: string;
  supportMailSubject?: string;
};
const SupportContact = (props: SupportContactProps): JSX.Element => {
  const {
    showCustomerContact = true,
    showYardstikContact = true,
    showReportErrorButton = false,
    reportErrorCallback = () => ({}),
    customerEmail = '',
    customerName = '',
    supportMailSubject = ''
  } = props;

  const triggerReportErrorCallback = (event: React.SyntheticEvent) => {
    event.preventDefault();
    reportErrorCallback();
  };

  return (
    <>
      <S.Title>Need Support?</S.Title>

      <S.Wrapper>
        {showCustomerContact && customerEmail && (
          <S.BodyText data-testid="customer-contact">
          If you have questions about {customerName}'s process, please reach out
          to their support team at{' '}
            <S.Link
              href={`mailto:${customerEmail}?subject=${supportMailSubject}`}
            >
              {customerEmail}
            </S.Link>
          .
          </S.BodyText>
        )}

        {showYardstikContact && (
          <S.BodyText>
          If you have questions about your background report, contact {' '}
            <S.Link
              href={`mailto:support@yardstik.com?subject=${supportMailSubject}`}
            >
            support@yardstik.com
            </S.Link>
          .
          </S.BodyText>
        )}

        {showReportErrorButton && (
          <S.BodyText>
          If you believe the results of your report have an error in them,&nbsp;
            <S.Link href="#" onClick={triggerReportErrorCallback}>
            Report an Error
            </S.Link>{' '}
          for further review.
          </S.BodyText>
        )}
      </S.Wrapper>
    </>
  );
};

export default SupportContact;
