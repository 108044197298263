import jwtDecode, { JwtPayload } from "jwt-decode";
import { findKey } from "lodash";

interface JwtTokenPayload extends JwtPayload {
  scope: string
  permissions: Array<any>
  iss: string,
  aud: string,

}

export const validateToken = (token: string): boolean => {
  try {

    jwtDecode<JwtTokenPayload>(token);

    return true;

  } catch (err) {

    console.log('JWT Decode Error', err);

    return false;

  }

};

export const getTokenExprieAt = (token: string): number | undefined => {

  const decoded = jwtDecode<JwtTokenPayload>(token);

  const expires_at = decoded?.exp;


  return expires_at;
};

export const getTokenIssuedAt = (token: string): number | undefined => {

  const decoded = jwtDecode<JwtTokenPayload>(token);

  const issused_at = decoded?.iat;


  return issused_at;
};
export const getTokenScope = (token: string): string => {

  const decoded = jwtDecode<JwtTokenPayload>(token);

  const scope = decoded?.scope;


  return scope;
};

export const getTokenIssuer = (token: string): string => {

  const decoded = jwtDecode<JwtTokenPayload>(token);

  const issuer = decoded?.iss;


  return issuer;
};


export const getTokenCustomMetadataCandidateIds = (token: string): Array<string> => {

  const decoded = jwtDecode<JwtTokenPayload>(token);

  const customMetaDataKey = findKey(decoded, 'candidate_ids');
  if (!customMetaDataKey) return [];

  const customMetadataCandidateIds = decoded[customMetaDataKey]?.candidate_ids;


  return customMetadataCandidateIds;
};
export const getTokenPermissions = (token: string): any[] => {

  const decoded = jwtDecode<JwtTokenPayload>(token);

  const permissions = decoded?.permissions;


  return permissions;
};

export const getTokenAudience = (token: string): string => {

  const decoded = jwtDecode<JwtTokenPayload>(token);

  const audience = decoded?.aud;


  return audience;
};
