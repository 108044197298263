import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  severity: '',
  text: ''
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.severity = action.payload.severity;
      state.text = action.payload.text;
    }
  }
});

export const { setNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
