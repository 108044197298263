import { AnyAction } from 'redux';
import { SET_AUTH_USER, SET_TOKEN } from '../controls/actionTypes';

// Initial State
const initialState = {};

// Reducer
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case SET_AUTH_USER:
    return {
      ...state,
      ...action.payload
    };
  case SET_TOKEN:
    return {
      ...state,
      token: action.payload
    };
  default:
    return state;
  }
};
