import { Theme } from '@yardstik/core.components';
import { Box, Link as MuiLink, styled, Typography } from '@mui/material';

export const Wrapper = styled(Box)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  padding: '15px',
  backgroundColor: Theme.colors['gray--20'],
  borderRadius: '6px',
  boxShadow: '0px 3px 6px rgb(0 0 0 / 20%)',
  width: '100%'
});

export const Title = styled(Typography)({
  fontWeight: 600,
  color: '#333333',
  fontSize: '1.25rem',
  margin: '16px 0'
});

export const BodyText = styled(Typography)({
  color: '#575757',
  lineHeight: '18px',
  fontSize: '0.875rem',
});

export const Link = styled(MuiLink)({
  color: '#8E17BF',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
});
