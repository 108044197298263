/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux';
import { get } from 'lodash';
import {
  SET_CANDIDATE_APPLICATION_LOADING,
  INCREMENT_CANDIDATE_APPLICATION_STEP,
  DECREMENT_CANDIDATE_APPLICATION_STEP,
  SET_CANDIDATE_APPLICATION_STEP,
  SET_CANDIDATE_APPLICATION_STATUS,
} from '../actionTypes';

export type CandidateApplyControlsState = {
  loading: boolean,
  errors: any[],
  currentStep: number,
  submitting: boolean,
  validationErrors: any,
  status: string,
}; 

const initialState: CandidateApplyControlsState = {
  loading: false,
  errors: [],
  currentStep: 0,
  submitting: false,
  validationErrors: {},
  status: 'available',
};

export default (state = initialState, action: AnyAction) => {
  let currentStepCount = get(state, 'currentStep', 0);
  switch (action.type) {
  case SET_CANDIDATE_APPLICATION_LOADING:
    return {
      ...state,
      loading: action.payload,
    };
  case INCREMENT_CANDIDATE_APPLICATION_STEP:
    currentStepCount++;
    return {
      ...state,
      currentStep: currentStepCount,
    };

  case DECREMENT_CANDIDATE_APPLICATION_STEP:
    currentStepCount--;
    return {
      ...state,
      currentStep: currentStepCount,
    };
  case SET_CANDIDATE_APPLICATION_STEP:
    return {
      ...state,
      currentStep: action.payload,
    };
  case SET_CANDIDATE_APPLICATION_STATUS:
    return {
      ...state,
      status: action.payload,
    };
  default:
    return state;
  }
};
