import { AnyAction } from 'redux';
import { SET_ADC_VALUES, INCREMENT_ADC_TRIGGER } from '../actionTypes';

const initialState = {
  values: {},
  triggerKey: 0,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case SET_ADC_VALUES:
    return {
      ...state,
      values: {
        ...state.values,
        ...action.payload,
      },
    };
  case INCREMENT_ADC_TRIGGER:
    return {
      ...state,
      triggerKey: state.triggerKey + 1,
    };
  default:
    return state;
  }
};
