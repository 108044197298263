// account
export const SET_CANDIDATE_ACCOUNT_DATA = 'SET_CANDIDATE_ACCOUNT_DATA';

// adc values
export const INCREMENT_ADC_TRIGGER = 'INCREMENT_ADC_TRIGGER';
export const SET_ADC_VALUES = 'SET_ADC_VALUES';

// application
export const SET_CANDIDATE_APPLICATION_DATA = 'SET_CANDIDATE_APPLICATION_DATA';
export const SET_CANDIDATE_APPLICATION_PAID = 'SET_CANDIDATE_APPLICATION_PAID';
export const SET_PAYMENT_INFO_LOADING = 'SET_PAYMENT_INFO_LOADING';

// candidate
export const SET_CANDIDATE_DATA = 'SET_CANDIDATE_DATA';

// controls
export const DECREMENT_CANDIDATE_APPLICATION_STEP = 'DECREMENT_CANDIDATE_APPLICATION_STEP';
export const INCREMENT_CANDIDATE_APPLICATION_STEP = 'INCREMENT_CANDIDATE_APPLICATION_STEP';
export const SET_CANDIDATE_APPLICATION_LOADING = 'SET_CANDIDATE_APPLICATION_LOADING';
export const SET_CANDIDATE_APPLICATION_STATUS = 'SET_CANDIDATE_APPLICATION_STATUS';
export const SET_CANDIDATE_APPLICATION_STEP = 'SET_CANDIDATE_APPLICATION_STEP';

// form sections
export const SET_CANDIDATE_FORM_SECTIONS = 'SET_CANDIDATE_FORM_SECTIONS';

// form values
export const REMOVE_IMAGE_FROM_SECTION = 'REMOVE_IMAGE_FROM_SECTION';
export const REMOVE_IMAGE_FROM_REPEATER_SECTION = 'REMOVE_IMAGE_FROM_REPEATER_SECTION';
export const UPDATE_CANDIDATE_FORM_REPEATER_VALUE = 'UPDATE_CANDIDATE_FORM_REPEATER_VALUE';
export const UPDATE_CANDIDATE_FORM_VALUE = 'UPDATE_CANDIDATE_FORM_VALUE';
export const ADD_DESTORY_ADDRESS_ID = 'ADD_DESTORY_ADDRESS_ID';
export const SET_CANDIDATE_FORM_VALUES = 'SET_CANDIDATE_FORM_VALUES';
export const ADD_CANDIDATE_FORM_REPEATER_SECTION = 'ADD_CANDIDATE_FORM_REPEATER_SECTION';
export const ADD_IMAGE_TO_SECTION = 'ADD_IMAGE_TO_SECTION';
export const ADD_IMAGE_TO_REPEATER_SECTION = 'ADD_IMAGE_TO_REPEATER_SECTION';
export const REMOVE_CANDIDATE_FORM_REPEATER_SECTION = 'REMOVE_CANDIDATE_FORM_REPEATER_SECTION';
export const CLEAR_DESTORY_ADDRESS_ID = 'CLEAR_DESTORY_ADDRESS_ID';

// legal docs
export const SET_CANDIDATE_LEGAL_DOCUMENTS = 'SET_CANDIDATE_LEGAL_DOCUMENTS';

// styles
export const SET_CANDIDATE_STYLES_DATA = 'SET_CANDIDATE_STYLES_DATA';
