import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Tabs, Card, Header } from '@yardstik/core.components';

import { LegalDocs } from './LegalDocs';
import { Attachments } from './Attachments';

import { RootState } from '../../../redux/store';

export function Documents(): JSX.Element {
  const { reportId, legalDocs } = useSelector((state: RootState) => ({
    reportId: state?.reports?.reportDetail?.id ?? '',
    legalDocs: state?.reports?.reportDetail?.documents ?? []
  }));

  const TABS = [
    {
      value: 'attachments',
      label: 'Attachments',
      component: <Attachments reportId={reportId} />
    },
    {
      value: 'legal-docs',
      label: 'Legal Docs',
      component: <LegalDocs signedDocuments={legalDocs} />
    }
  ];

  return (
    <Box
      sx={{
        mt: 4,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Header.Root sx={{ mb: '12px' }}>
        <Header.Head>
          <Header.Title title="Documents" />
        </Header.Head>
      </Header.Root>

      <Card sx={{ width: '100%' }}>
        <Tabs tabs={TABS} />
      </Card>
    </Box>
  );
}
