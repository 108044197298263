import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import { RootState } from '../../../redux/store';
import { useAuth0 } from '../../../contexts/auth0/auth0-context';
import { getReportDetail } from '../../../redux/reports/actions';

interface UrlParams {
  candidate_id: string;
  report_id: string;
}

export function useReportRequest() {
  const { apiToken } = useAuth0();

  const { candidate_id: candidateId, report_id: reportId } = useParams<UrlParams>();

  const { report, applicationLoading, notification } = useSelector((state: RootState) => ({
    notification: state.notification,
    report: state.reports.reportDetail ?? {},
    applicationLoading: state.candidate_application?.controls?.loading ?? false
  }));

  const fetchReportDetails = useCallback(() => {
    if (reportId && candidateId)
      getReportDetail({
        reportId,
        candidateId
      });
  }, [candidateId, reportId]);

  useEffect(() => {
    if (notification.severity === 'success') {
      (async () => {
        await fetchReportDetails();
      })();
    }
  }, [notification, fetchReportDetails]);

  useEffect(() => {
    if (apiToken) fetchReportDetails();
  }, [candidateId, reportId, apiToken, fetchReportDetails]);

  const isLoading = applicationLoading || !report.id || report.id !== reportId;

  return { fetchReportDetails, isLoading };
}
