import { Typography } from '@mui/material';
import React from 'react';

type Props = {
  title?: string;
  subTitle: string;
};

export function UploadFieldDescription({ title, subTitle }: Props): JSX.Element {
  return (
    <React.Fragment>
      {title && (
        <Typography variant="subtitle1" mb={2} color="grey.800">
          {title}
        </Typography>
      )}
      <Typography variant="body1">{subTitle}</Typography>
      <Typography variant="caption" sx={{ color: 'grey.700' }}>
        Upload a .png or .jpg or .pdf that is 10MB or smaller
      </Typography>
    </React.Fragment>
  );
}
