import React from 'react';
import { useSelector } from 'react-redux';
import { CandidateTrainingResults } from '@yardstik/core.components';

import { useReportView } from '../index.context';

import { RootState } from '../../../redux/store';

export function TrainingResults() {
  const { forceOpenAccordions } = useReportView();

  const { courses, candidate } = useSelector((state: RootState) => ({
    candidate: state.reports.reportDetail.candidate ?? {},
    courses: state.reports.reportDetail.course.courses ?? []
  }));

  if (!courses.length) return <React.Fragment />;

  return (
    <div className="reportSummaryComponent__container" data-testid="courses-container">
      <CandidateTrainingResults courses={courses} candidate={candidate} forceOpenAccordions={forceOpenAccordions} />
    </div>
  );
}
