/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// @ts-nocheck
import { get } from 'lodash';
import {
  REMOVE_IMAGE_FROM_SECTION,
  REMOVE_IMAGE_FROM_REPEATER_SECTION,
  UPDATE_CANDIDATE_FORM_REPEATER_VALUE,
  UPDATE_CANDIDATE_FORM_VALUE,
  ADD_DESTORY_ADDRESS_ID,
} from '../actionTypes';

export const removeImage = (
  dispatch,
  mediaId = '',
  propsSent: {
    repeaterSectionIndex?: number;
    repeaterSectionName?: string;
  } = {}
) => {
  const { repeaterSectionIndex = null, repeaterSectionName = null } = propsSent;
  const type =
    repeaterSectionIndex !== null && repeaterSectionName
      ? REMOVE_IMAGE_FROM_REPEATER_SECTION
      : REMOVE_IMAGE_FROM_SECTION;
  dispatch({
    type: type,
    payload: { propsSent, mediaId },
  });
};

export const removeImageFromForm = (dispatch, fileName = '', formName = '') => {
  dispatch({
    type: REMOVE_IMAGE_FROM_SECTION,
    payload: { propsSent: { name: fileName, formName } },
  });
};

type FieldProps = {
  repeaterSectionIndex?: number;
  repeaterSectionName?: string;
  dependent_show_if_value?: {
    fields?: string[];
    values?: string[];
  };
  fieldsToNull?: string[];
  multi_dependent_show_if_value?: {
    conditions: Array<any>;
  };
};

export const handleUpdateAppLevelFormData = (
  value = null,
  fieldProps: FieldProps,
  dispatch: any
) => {
  const {
    repeaterSectionIndex = null,
    repeaterSectionName = null,
    dependent_show_if_value = {},
    multi_dependent_show_if_value,
  } = fieldProps;

  const dependentShowIfValueFields = dependent_show_if_value.fields || [];
  const dependentShowIfValueValues = dependent_show_if_value.values || [];
  const multiDependentShowIfValueConditions = get(
    multi_dependent_show_if_value,
    'conditions',
    []
  );

  const payload = { fieldValue: value, ...fieldProps };

  // setting dependent fields to null
  if (
    dependentShowIfValueFields &&
    dependentShowIfValueFields.length > 0 &&
    dependentShowIfValueValues &&
    dependentShowIfValueValues.length > 0
  ) {
    const dependentFieldsValueIndex = dependentShowIfValueValues.findIndex(
      (showifValue) => showifValue === value
    );

    // checking if the dependent show if value true fields should be nulled out.
    if (!(dependentFieldsValueIndex >= 0)) {
      payload.fieldsToNull = dependentShowIfValueFields;
    }
  }

  if (
    multiDependentShowIfValueConditions &&
    multiDependentShowIfValueConditions.length > 0
  ) {
    // finding which condition matches the current value of the field value
    const conditionIndex = multiDependentShowIfValueConditions.findIndex(
      (condition: { fields: Array<string>; values: Array<string> }) => {
        return condition.values.includes(value);
      }
    );

    if (conditionIndex >= 0) {
      payload.fieldsToNull =
        multiDependentShowIfValueConditions[conditionIndex].fields;
    }
  }

  if (repeaterSectionIndex !== null && repeaterSectionName !== null) {
    dispatch({
      type: UPDATE_CANDIDATE_FORM_REPEATER_VALUE,
      payload,
    });
  } else {
    dispatch({
      type: UPDATE_CANDIDATE_FORM_VALUE,
      payload,
    });
  }
};

export const updateFieldValue = (
  name,
  fieldValue,
  updateFieldFormName,
  dispatch: any
) => {
  const payload = {
    name,
    fieldValue,
    formName: updateFieldFormName,
  };
  dispatch({
    type: UPDATE_CANDIDATE_FORM_VALUE,
    payload,
  });
};

export const addAddressToDestroy = (dispatch: any, addressID: string) => {
  dispatch({
    type: ADD_DESTORY_ADDRESS_ID,
    payload: addressID,
  });
};
