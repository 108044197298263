import React, { useEffect, useState, useRef } from 'react';
import mqtt from 'mqtt';
import { useSelector, useDispatch } from 'react-redux';
import { get, merge } from 'lodash';
import { updateFieldValue, removeImageFromForm } from '../../redux/candidateApply/formValues/actions';
import { setAdcValues } from '../../redux/candidateApply/adcValues/actions';
import { setFileReferences, setResetCallbacks, setProcessErrors, setProccessingStatus, setResetPayloads, setLoading } from '../../redux/documentVerify/documentVerifySlice';
import splitByDocumentAndFacematch from './splitByDocumentAndFacematch';
import getDocumentErrors from './getDocumentErrors';
import getADCFields from './getADCFields';
import getFieldValuesToADC from './getFieldValuesToADC';

const MQTT_URL = process.env.REACT_APP_MQTT_URL;
const MQTT_AUTHORIZER = process.env.REACT_APP_MQTT_AUTHORIZER;

type ToUpdateItemType = {
  step: string;
  fieldName: string;
  value: string;
}

type ToUpdateType = ToUpdateItemType[];

const DocumentProcessing = () => {
  const dispatch = useDispatch();
  const [connectionStatus, setConnectionStatus] = React.useState(false);
  const accessToken = useSelector((state) => get(state, 'documentVerify.token'));
  const MQTT_TOPIC = useSelector((state) => get(state, 'documentVerify.topic'));
  const resetCallbacks = useSelector((state) => get(state, 'documentVerify.resetCallbacks', {}));
  const resetPayloads = useSelector((state) => get(state, 'documentVerify.resetPayloads', []));
  const documentRegex = useSelector((state) => get(state, 'documentVerify.documentRegexTest'));
  const formName = useSelector((state) => get(state, 'documentVerify.formName', ''));
  const formValues = useSelector((state) => get(state, 'candidate_application.formValues'));
  const formSteps = useSelector((state) => get(state, 'candidate_application.formSections.steps', []));
  const stepsInOrder = formSteps.map((formStep:any) => get(formStep, 'formName'));
  const autofillMappings = useSelector((state) => get(state, 'documentVerify.autofillMappings'));
  const currentAutofillMappings = get(autofillMappings, formName);

  const url = `${MQTT_URL}?x-amz-customauthorizer-name=${MQTT_AUTHORIZER}&token=${accessToken}`;
  const subscribe = `${MQTT_TOPIC}`;

  // timer vars
  const timeFrame = 45000; // 45 seconds
  const withinTimeframe = useRef(true);
  const lastMessage = useRef();
  let timer: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (accessToken && MQTT_TOPIC) {
      // timeout handler
      const handleTimeout = () => {
        console.log('handleTimeout');
        withinTimeframe.current = false;
        handleSuccess(undefined);
        clearTimeout(timer);
      };
      // reset withinTimeFrame and initialize timer
      withinTimeframe.current = true;
      timer = setTimeout(handleTimeout, timeFrame);

      // set up mqtt stuff
      const client = mqtt.connect(url);
      client.on('connect', () => {
        dispatch(setProccessingStatus('initializing'));
        setConnectionStatus(true);

        client.subscribe(subscribe, { qos: 1, nl: false }, (err, grant) => {});
      });

      client.on('message', (topic, payload, packet) => {
        clearTimeout(timer);
        if (payload.length > 0) {
          const message = JSON.parse(payload.toString());
          // we sometimes get a message back from base64 provider... we need to ignore this message in that case
          const dontProcede = get(message, 'data.status');

          // I think the empty string clears out the retained messages
          client.publish(topic, '', {retain: true, qos: 1});

          // only do processing if within timeframe, we don't want to clear images
          // or set/reset values after leaving the `step`
          if (withinTimeframe.current && !dontProcede) {

            // split the message by documents and facematch
            const { documents: documentsResults, facematch: facematchResults } = splitByDocumentAndFacematch(message);

            const adcFields = getADCFields(documentsResults);

            // this is for non-default screening steps (not dataCollection)
            const toUpdate = getFieldValuesToADC(formName, adcFields, stepsInOrder, formValues, currentAutofillMappings);
            // this is for default dataCollection
            const adcObject = toUpdate.reduce(
              (acc:any, curr:any) => (
                merge(acc, {
                  [`${curr.step}`]: {
                    [`adc_${curr.fieldName}`]: curr.value,
                  }
                })
              ), {}
            );
            // need to dispatch to save adc for defaultCollection
            // also need to prefix the field names becaause something in
            // redux is clobbering the values
            setAdcValues(get(adcObject, 'dataCollection'), dispatch);
            // iterate over toUpdate and dispatch update
            toUpdate.forEach((tu:ToUpdateItemType) => {
              updateFieldValue(tu.fieldName, tu.value, tu.step, dispatch);
            });

            const documentErrors = getDocumentErrors(documentsResults, documentRegex);
            // other errors could have documents that had errors
            const otherErrors = getDocumentErrors(facematchResults, '');

            const allErrors = [...documentErrors.errors, ...otherErrors.errors];
            const allErrorKeys = [...documentErrors.errorFields, ...otherErrors.errorFields];
            const hasErrors = allErrors && allErrors.length && allErrors.length > 0;

            // for now only check if valid license
            if (!hasErrors) {
              handleSuccess(undefined);
            } else {
              // no facematch error for now
              handleFailure(allErrors, allErrorKeys);
            }
          }

        }

      });

      client.on('close', () => {
        setConnectionStatus(false);
        // need to end or we won't be able to re-connect
        client.end();
      });
      return () => clearTimeout(timer);
    }
  }, [accessToken, MQTT_TOPIC]);

  const clearReferencesAndCallbacks = () => {
    dispatch(setFileReferences({}));
    dispatch(setResetCallbacks({}));
    dispatch(setResetPayloads([]));
  };

  // clear out images from forValues (set from useFileUpload)
  const processCallbacks = () => {
    try {
      resetPayloads.forEach((payload: any) => {
        removeImageFromForm(dispatch, payload.
          fieldName, payload.formName);
      });

    } catch (error) {

    }
  };

  const handleSuccess = (records: any) => {
    dispatch(setProccessingStatus('success'));
    clearReferencesAndCallbacks();
    dispatch(setLoading({ isLoading: false, loadingText: ''}));
    // I think the code below can get taken out. This was the code that use to do the simple ADC.
    if (records) {
      try {
        Object.keys(records).forEach(record => {
          updateFieldValue(record, records[record], 'InstantDriving', dispatch);
        });
      } catch (error) {

      }
    }
  };

  const handleFailure = (errMessages: string[], errKeys: string[]) => {
    dispatch(setLoading({ isLoading: false, loadingText: ''}));
    errKeys.forEach((ek: string) => {
      removeImageFromForm(dispatch, ek, formName);
    });
    dispatch(setProcessErrors(errMessages));
    dispatch(setProccessingStatus('error'));
  };

  return (<></>);
};

export default DocumentProcessing;
