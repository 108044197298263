import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { ClientContext, useQuery } from 'graphql-hooks';
import { useParams, useHistory } from 'react-router-dom';
import { Button, CardInfo, Loader } from '@yardstik/core.components';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { useReactToPrint } from 'react-to-print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getQuery, ICandidateInfo, ICandidateAddresses } from './candidateUtils';
import LoadingError from '../LoadingError';
import ReportList from './ReportList';
import MonitorList from '../MonitorList';
import SupportContact from '../SupportContact';
import ReportProblem from '../ReportProblem';
import ReportProblemContext from './ReportProblem.context';
import * as S from './style';

interface ICandidateDetailsRouteParams {
  candidate_id: string;
}

interface ICardInfo {
  label: string;
  value: string;
}

const CandidateDetails = (): JSX.Element => {
  const client = useContext(ClientContext);
  const history = useHistory();
  const { candidate_id: candidateId } = useParams<ICandidateDetailsRouteParams>();
  const [candidateCardInfo, setCandidateCardInfo] = useState<Array<ICardInfo>>([]);
  const [candidateInfo, setCandidateInfo] = useState<ICandidateInfo>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    reports: [],
    monitors: [],
    account: {}
  });

  const [reportErrorOpen, setReportErrorOpen] = useState(false);
  const [monitorRecordId, setMonitorRecordId] = useState('');
  const [expandItems, setExpandItems] = useState(false);

  const backToHome = () => history.push('/');

  const componentRef = useRef(null);

  const setTimeoutAsync = (callback, delay) =>
    new Promise(resolve => {
      callback();
      setTimeout(() => {
        resolve({});
      }, delay);
    });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: async () => {
      return setTimeoutAsync(() => setExpandItems(true), 1000);
    }
  });

  const query = useMemo(() => {
    if (candidateId && client) {
      client.setHeader('Candidate-Id', candidateId);
      return getQuery(candidateId);
    }
    return '';
  }, [candidateId, client]);

  const { data, error, loading, refetch } = useQuery(query);

  useEffect(() => {
    if (data) {
      const {
        candidates: {}
      } = data;
      const { edges = [] } = data.candidates;

      if (edges.length) {
        const { node: candidateDetails = {} } = edges[0];
        setCandidateInfo(candidateDetails);
      }
    }
  }, [data]);

  useEffect(() => {
    const getAddress = (addresses: Array<ICandidateAddresses>) => {
      const currentAddress = addresses.filter(address => !!address.current);
      const address = currentAddress.length ? currentAddress[0] : addresses[0];
      if (address?.id) {
        const { line1 = '', line2 = '', city = '', state = '', zipCode = '' } = address;
        return [line1, line2, city, `${state} ${zipCode}`].filter(value => !!value).join(', ');
      }
      return '';
    };

    if (candidateInfo.id) {
      const addresses = candidateInfo.addresses?.length
        ? [{ label: 'Address', value: getAddress(candidateInfo.addresses) }]
        : [];

      const info = [
        { label: 'Type', value: candidateInfo.personSubType || '' },
        { label: 'Email Address', value: candidateInfo.email || '' },
        {
          label: 'Driver`s License',
          value: candidateInfo.driverLicenseNumber || ''
        },
        { label: 'Phone Number', value: candidateInfo.phone || '' },
        ...addresses
      ];
      setCandidateCardInfo(info);
    }
  }, [candidateInfo]);

  useEffect(() => {
    if (!reportErrorOpen) {
      setMonitorRecordId('');
    }
  }, [reportErrorOpen]);

  const reportProblemCallback = currentMonitorRecordId => {
    setReportErrorOpen(true);
    setMonitorRecordId(currentMonitorRecordId);
  };

  if (loading) {
    return (
      <div>
        <Loader logo="https://yardstik-assets.s3.amazonaws.com/logos/yardstik-black.svg" spinnerColor="primary" />
      </div>
    );
  }

  // show error message if graphQL error or graphQL response is unexpected
  if (error) {
    return (
      <LoadingError text="Error loading details. Please refresh page or contact support at support@yardstik.com." />
    );
  }

  return (
    <S.Wrapper>
      <Typography variant="subtitle2">
        <Link
          underline="hover"
          component="button"
          onClick={backToHome}
          sx={{ display: 'flex', alignItems: 'center' }}
          data-testid="backToHome-button"
        >
          <ArrowBackIcon fontSize="small" sx={{ mr: 0.5 }} />
          Back To Home
        </Link>
      </Typography>

      <S.PrintableWrapper ref={componentRef}>
        <S.PageHeading>
          <div>
            {candidateInfo.firstName} {candidateInfo.lastName}
          </div>
          <div>
            <Button
              color="primary"
              size="medium"
              text={<PrintOutlinedIcon fontSize="small" />}
              type="button"
              onClick={handlePrint}
              aria-label="print button"
            />
          </div>
        </S.PageHeading>
        <br />
        <Box display="flex" alignItems="flex-start" flexDirection="column" mb={2}>
          <Box width={1} mb={2}>
            <S.Heading variant="h6">Personal Information</S.Heading>
          </Box>
          <Box width={1}>
            <CardInfo fields={candidateCardInfo} />
          </Box>
        </Box>
        <br />
        {candidateInfo.monitors.length > 0 && (
          <ReportProblemContext.Provider
            value={{
              reportProblemCallback,
              forceExpand: expandItems,
              setForceExpand: setExpandItems
            }}
          >
            <MonitorList monitors={candidateInfo.monitors} candidateId={candidateId} />
          </ReportProblemContext.Provider>
        )}
        <br />
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <S.Heading variant="h6">Reports</S.Heading>
        </Box>
        <Box mb={4}>
          <ReportList reports={candidateInfo.reports} candidateId={candidateId} />
        </Box>

        <S.PrintableFooter>
          WARNING: Based on the information provided Yardstik searched for public records in the sources referenced
          herein for criminal history information as permitted by federal and state law.
          <br />
          'Records Found' means that our researchers found a record(s) in that jurisdiction that matched the personal
          identifiers (i.e., Name, SSN, Date of Birth, Address) listed for the subject in the above abstract.
          <br />
          Yardstik does not guarantee the accuracy or truthfulness of the information as to the subject of the
          investigation, but only that it is accurately copied from public records. Information generated as a result of
          identity theft, including evidence of criminal activity, may be inaccurately associated with the consumer who
          is the subject of this report. Further investigation into additional jurisdictions, or utilization of
          additional identifying information, may be warranted.
          <br />
          Disclaimer
          <br />
          This report is furnished to you pursuant to the Agreement for Service between the parties and in compliance
          with the Fair Credit Reporting Act. This report is furnished based upon your certification that you have a
          permissible purpose to obtain the report. The information contained herein was obtained in good faith from
          sources deemed reliable, but the completeness or accuracy is not guaranteed.
        </S.PrintableFooter>
      </S.PrintableWrapper>

      <Grid item xs={12}>
        <SupportContact
          showReportErrorButton={false}
          customerName={candidateInfo.account?.accountName}
          customerEmail={candidateInfo.account?.supportEmailAddress}
          supportMailSubject={`Questions about report: ${candidateInfo?.firstName} ${candidateInfo?.lastName} from ${candidateInfo.account?.accountName}`}
        />
      </Grid>

      <ReportProblem
        isOpen={reportErrorOpen}
        candidateId={candidateInfo.id}
        monitorRecordId={monitorRecordId}
        refetchPageInfo={refetch}
        setModalOpen={isOpen => setReportErrorOpen(isOpen)}
        accountName={candidateInfo.account?.accountName || ''}
        accountSupportMail={candidateInfo.account?.supportEmailAddress || ''}
      />
    </S.Wrapper>
  );
};
export default CandidateDetails;
