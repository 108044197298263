import { CandidateInformation, Header, StatusChip } from '@yardstik/core.components';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useSelector } from 'react-redux';

import { Box, Link, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import { useReportView } from '../index.context';
import { RootState } from '../../../redux/store';

export function ReportHeader() {
  const { printReport } = useReportView();

  const { report, candidate } = useSelector((state: RootState) => ({
    report: state.reports.reportDetail,
    candidate: state.reports.reportDetail.candidate
  }));

  const theme = useTheme();

  const history = useHistory();

  const backToHome = () => history.push('/');

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2">
          <Link
            underline="hover"
            component="button"
            onClick={backToHome}
            sx={{ display: 'flex', alignItems: 'center' }}
            data-testid="backToHome-button"
          >
            <ArrowBackIcon fontSize="small" sx={{ mr: 0.5 }} />
            Back To Home
          </Link>
        </Typography>
      </Box>

      <Box sx={{ mt: '36px' }}>
        <Box
          sx={{
            mb: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Header.Root>
            <Header.Head>
              <Header.Title title={`${candidate?.full_name}’s Report`} variant="h3" />
              <Header.Statuses>
                <StatusChip status={report.status} category="report" />
              </Header.Statuses>
            </Header.Head>
          </Header.Root>

          <Button
            size="medium"
            variant="text"
            color="secondary"
            onClick={() => printReport()}
            aria-label="print button"
          >
            Print
          </Button>
        </Box>

        <CandidateInformation
          unmaskedData
          reportData={{
            ...report,
            candidate: {
              ...report.candidate,
              ssn: report.candidate.ssn_masked ?? ''
            }
          }}
        />
      </Box>
    </Box>
  );
}
