import { useState, useCallback } from "react";
import { useDispatch } from 'react-redux';
import { emailVerification } from '../../api/auth';
import { setCandidateApplicationLoading } from '../../../redux/candidateApply/controls/actions';

export type TsendVerificationEmail = {
  sendVerificationEmail: Function;
  error: any;
  loading: Boolean;
};


export const useSendVerificationEmail = ():TsendVerificationEmail => {

  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  

  const sendVerificationEmail = useCallback(async (accountId: string, email: string) => {
    setCandidateApplicationLoading(true, dispatch);
    
    try {
      await emailVerification(accountId, email);
    
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCandidateApplicationLoading(false, dispatch);
      setLoading(false);
    }
  }, []);


  return {sendVerificationEmail, loading, error };
};
