import { get, isEmpty } from 'lodash';
import { CLEAR_DESTORY_ADDRESS_ID } from '../../redux/candidateApply/actionTypes';
import { CandidateToSend } from '../../components/CandidateApply/candidateApplyInterfaces';
import api from './index';
import { setControlsError } from '../../redux/controls/actions';

export type Candidate = {
  id: string;
  // Add a type definition for the account object
  account: any;
  full_name: string;
  first_name: string;
  middle_name: string;
  no_middle_name: boolean;
  last_name: string;
  email: string;
  phone_masked: string;
  ssn_masked: string;
  additional_data: any;
  date_of_birth: any;
  gender: any;
  driver_license_number: any;
  driver_license_state: any;
  previous_driver_license_number: any;
  external_reference: any;
  created_at: string;
  updated_at: string;
  addresses: any[];
};

export interface UpdateCandidateProp extends CandidateToSend {
  email_verified?: boolean;
  phone_verified?: boolean;
  skip_middle_name_validation?: boolean;
}

export const listCandidates = async (): Promise<any> =>
  api.get(`/candidate-profile/candidates/`).then(response => response.data);

export const showCandidate = (candidateId: string): Promise<Candidate> =>
  api
    .get(`/candidate-profile/candidates/${candidateId}`)
    .then(res => res.data)
    .catch(err => {
      throw err;
    });

export const updateCandidate = async (
  candidateId: string,
  candidateToSend: UpdateCandidateProp,
  dispatch: any,
  additionalDataWithPii?: object,
): Promise<any> => {
  let candidateData = { ...candidateToSend };

  // remove file from additional_data because the API will be handling the files
  if (candidateData.additional_data) {
    const screeningName = Object.keys(candidateData.additional_data)[0];
    candidateData = {
      ...candidateData,
      additional_data: {
        ...candidateData.additional_data,
        [screeningName]: {
          ...candidateData.additional_data[screeningName],
          files: undefined
        }
      }
    };
  }

  if (additionalDataWithPii) {
    if (isEmpty(additionalDataWithPii) === false){ 
      candidateData = {
        ...candidateData,
        ...additionalDataWithPii
      };
    }
  }

  return api
    .patch(`/candidate-profile/candidates/${candidateId}`, candidateData)
    .then(res => {
      dispatch({
        type: CLEAR_DESTORY_ADDRESS_ID
      });
      return res;
    })
    .catch(err => {
      console.error('Error while patching a candidate', err);
      const errorMessage = get(err, 'response.data.detail', 'Could not update information. Please contact support');

      setControlsError({
        status: 'error',
        message: errorMessage
      });
      throw err;
    });
};

export const getCandidateReportData = async (url: string): Promise<any> => api.get(url).then(res => res);

export const updateCandidateReportData = async (
  url: string,
  candidateToSend: CandidateToSend,
  dispatch: any,
  additionalDataWithPii?: object,
): Promise<any> => {
  let candidateData = { ...candidateToSend };

  // remove file from additional_data because the API will be handling the files
  if (candidateData.additional_data) {
    const screeningName = Object.keys(candidateData.additional_data)[0];
    candidateData = {
      ...candidateData,
      additional_data: {
        ...candidateData.additional_data,
        [screeningName]: {
          ...candidateData.additional_data[screeningName],
          files: undefined
        }
      }
    };
  }

  if (additionalDataWithPii) {
    if (isEmpty(additionalDataWithPii) === false){ 
      candidateData = {
        ...candidateData,
        ...additionalDataWithPii
      };
    }
  }

 
  return api
    .patch(url, candidateData)
    .then(res => {
      dispatch({
        type: CLEAR_DESTORY_ADDRESS_ID
      });
      return res;
    })
    .catch(error => {
      console.error('Error while patching a candidate', error);
      throw { message: get(error, 'response.data.detail', 'Could not submit data') };
    });
};
