import { format } from 'date-fns';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Box, Grid, Link, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Card, CardContent, CardMedia } from '@yardstik/core.components';

import { useGetReportDocumentsQuery } from '../../../../redux/reports/requests';

import { LightboxViewer } from './LightboxViewer';

type AttachmentsProps = {
  reportId: string;
};

export function Attachments({ reportId }: AttachmentsProps): JSX.Element {
  const { candidate_id: candidateId } = useParams<{ candidate_id: string }>();
  const { data: response } = useGetReportDocumentsQuery({ candidateId, reportId });
  const { search, pathname, hash } = useLocation();

  const currentUrl = new URL(window.location.origin + pathname + search + hash);

  const { attachment, index } = useMemo(() => {
    const attachment = new URLSearchParams(search).get('attachment');
    const index = new URLSearchParams(search).get('index');

    return {
      index,
      attachment
    };
  }, [search]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (attachment) setOpen(true);
  }, [attachment]);

  if (!response?.data?.length)
    return (
      <Box textAlign="center" py="102px">
        <Typography variant="h6" color="grey.800">
          No attachments
        </Typography>
        <Typography variant="body1" color="grey.700">
          Documents attached to this report will appear here
        </Typography>
        <Typography variant="caption" color="grey.700" fontStyle="italic">
          Documents attached to a specific screening will appear in the screening section
        </Typography>
      </Box>
    );

  return (
    <Box p={3}>
      {attachment && <LightboxViewer data={response.data} index={index} open={open} setOpen={setOpen} />}

      <Grid container rowGap={2}>
        {!!response?.data?.length &&
          response?.data.map((item, index) => {
            currentUrl.searchParams.set('attachment', item.url);
            currentUrl.searchParams.set('index', index.toString());

            return (
              <Grid key={item.id} item xs={12} md={6} gap={1}>
                <Card elevation={0}>
                  <Box
                    component={Link}
                    href={item.content_type === 'application/pdf' ? item.url : currentUrl.toString()}
                    display="flex"
                    target="_blank"
                    rel="noreferrer"
                    alignItems="center"
                    aria-label="open attachment"
                    sx={{ textDecoration: 'none' }}
                  >
                    {item.content_type === 'application/pdf' ? (
                      <Box sx={{ width: 151, display: 'flex', justifyContent: 'center' }}>
                        <PictureAsPdfIcon sx={{ color: 'grey.600', fontSize: 50 }} />
                      </Box>
                    ) : (
                      <CardMedia component="img" sx={{ width: 151 }} image={item.url} alt={item.filename} />
                    )}

                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="overline" color="text.secondary">
                        {item.document_type.label}
                      </Typography>

                      <Typography variant="body1">{item.filename}</Typography>

                      <Typography sx={{ mb: 1.5 }} variant="caption" color="text.secondary">
                        {(item.file_size / (1024 * 1024)).toFixed(2)}MB | uploaded{' '}
                        {format(new Date(item.created_at), "MMM d, yyyy 'at' h:mma")}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}
