import { AnyAction } from 'redux';
import { get } from 'lodash';
import {
  LIST_REPORTS_LOADING_ERROR,
  LIST_REPORTS,
  SHOW_REPORT_LOADING_ERROR,
  SHOW_REPORT,
  SET_REPORT_COURSES,
  SET_REPORT_LEGAL_DOCS
} from './actionTypes';
import { ReportType } from './types';

// Initial State
const initialState = {
  listReportsLoadingError: false,
  reports: [] as ReportType[],
  showReportLoadingError: false,
  reportDetail: {}
};

// Reducer
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case LIST_REPORTS_LOADING_ERROR:
    return {
      ...state,
      listReportsLoadingError: action.payload
    };
  case LIST_REPORTS: {
    return {
      ...state,
      reports: action.payload
    };
  }
  case SHOW_REPORT_LOADING_ERROR: {
    return {
      ...state,
      showReportLoadingError: action.payload
    };
  }
  case SHOW_REPORT: {
    return {
      ...state,
      reportDetail: action.payload
    };
  }
  case SET_REPORT_COURSES: {
    const course = get(state, 'reportDetail.course', {});
    return {
      ...state,
      reportDetail: {
        ...state.reportDetail,
        course: {
          ...course,
          courses: action.payload
        }
      }
    };
  }
  case 'SET_REPORT_DATA': {
    return {
      ...state,
      ...action.payload
    };
  }
  case SET_REPORT_LEGAL_DOCS: {
    return {
      ...state,
      reportDetail: {
        ...state.reportDetail,
        documents: action.payload
      }
    };
  }
  default:
    return state;
  }
};
