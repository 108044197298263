import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import ReportView from '../ReportView';
import { ReportViewInterface } from '../ReportView/reportViewInterface';
import ReportViewScreeningUploadModal from './ReportViewScreeningUploadModal';
import { setControlsError } from '../../redux/controls/actions';
import { ReportScreeningProps } from './ReportViewScreeningUploadModal/reportScreeningUploadModalInterface';
import { getCandidateScreeningData } from '../../redux/screeningUpload/actions';
import { setErrorMessage } from '../../utils/errorHandlingUtils';

const ReportViewScreeningUpload = (props: ReportViewInterface): JSX.Element => {
  const candidateId = get(props, 'match.params.candidate_id', '');
  const selectedReportId = get(props, 'match.params.report_id', '');
  const selectedScreeningId = get(props, 'match.params.screening_id', '');
  const report = useSelector(state => get(state, 'reports.reportDetail', null));
  const reportScreenings = useSelector(state => get(state, 'reports.reportDetail.report_screenings', []));

  const reportStatus = get(report, 'status');

  const [modalOpen, setModalOpen] = useState(false);
  const [screeningName, setScreeningName] = useState('');
  const [modalError, setModalError] = useState<any>(null);

  const dispatch = useDispatch();

  // setting screening name to use for file uploads
  useEffect(() => {
    const screeningDetails = reportScreenings.filter(
      (screening: ReportScreeningProps) => screening.id === selectedScreeningId
    ) as ReportScreeningProps[];
    if (screeningDetails.length === 1) {
      setScreeningName(screeningDetails[0].name);
    }
  }, [reportScreenings, selectedScreeningId]);

  // getting screening data from db so it all remains on update
  useEffect(() => {
    if (candidateId && screeningName) {
      getCandidateScreeningData(candidateId, screeningName, dispatch).catch(err => {
        setControlsError({
          status: 'error',
          message: 'Failed to fetch required data'
        });
        const errorMessage = setErrorMessage(
          err,
          'Error loading information requested page. Please refresh and try again or contact support@yardstik.com.'
        );
        setModalError(errorMessage);
      });
    }
  }, [candidateId, dispatch, screeningName]);

  // setting modal open if status is info requested
  useEffect(() => {
    // wrap the function call in the if, instead of putting line 47 in the function call
    async function fetchApplicationData() {
      dispatch({
        type: 'SET_CANDIDATE_APPLICATION_LOADING',
        payload: true
      });
      try {
        dispatch({
          type: 'SET_CANDIDATE_APPLICATION_LOADING',
          payload: false
        });
      } catch (error) {
        setControlsError({
          status: 'error',
          message: 'Failed to fetch required data'
        });
        dispatch({
          type: 'SET_CANDIDATE_APPLICATION_LOADING',
          payload: false
        });
        const errorMessage = setErrorMessage(
          error,
          'Error loading information requested page. Please refresh and try again or contact support@yardstik.com.'
        );
        setModalError(errorMessage);
      }
    }
    if (reportStatus === 'info_requested') {
      setModalOpen(true);
      fetchApplicationData();
    }
  }, [dispatch, reportStatus]);

  return (
    <>
      <ReportView />
      <ReportViewScreeningUploadModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        candidateId={candidateId}
        screeningId={selectedScreeningId}
        reportId={selectedReportId}
        screeningName={screeningName}
        modalError={modalError}
      />
    </>
  );
};

export default ReportViewScreeningUpload;
