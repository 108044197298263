import api from './index';

import { Candidate } from './candidates';

export type Report = {
  id: string;
  status: any;
  submitted_at: string;
  completed_at: string;
  created_at: string;
  updated_at: string;
  candidate: Candidate;
  package_name: string;
  account_package_name: string;
  account: any;
};

export type ShowReportPropsType = {
  candidateId: string;
  reportId: string;
  searchDetails?: boolean;
};

export type UpdateReportStepType = {
  candidateId: string;
  reportId: string;
  stepName: string;
  stepInnerIndex?: number;
};

export const listReports = (candidateId: string) =>
  api.get(`/candidate-profile/candidates/${candidateId}/reports`).then(response => response.data);

export const showReport = ({ candidateId, reportId }: ShowReportPropsType) => {
  return api
    .get(`candidate-profile/candidates/${candidateId}/reports/${reportId}?search_details=true`)
    .then(response => response.data);
};

export const updateReportStep = ({
  candidateId,
  reportId,
  stepName,
  stepInnerIndex
}: UpdateReportStepType): Promise<any> => {
  const payload = {
    application_data: {
      current_step: stepName,
      current_step_inner_index: stepInnerIndex
    }
  };

  return api
    .patch(`candidate-profile/candidates/${candidateId}/reports/${reportId}`, payload)
    .then(response => response.data);
};

export const getCourses = async (candidateId: string, reportId: string) =>
  await api.get(`/candidates/${candidateId}/courses/${reportId}`).then(res => res.data);

export type ProcessPayloadProps = {
  candidate_id: string;
  screening_id: string;
  file_references: string[];
};

export const processFiles = async (reportId: string, payload: ProcessPayloadProps) =>
  await api.post(`/reports/${reportId}/process-files/`, payload).then(res => res.data);

export const getApplyDetails = (reportId: string, candidateId: string, authType: string) =>
  api.get(`/reports/${reportId}/apply_details/${candidateId}?auth_type=${authType}`).then(response => response.data);
