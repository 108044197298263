import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '@yardstik/core.components/build/index.css';
import { AlertSnackbar as YSAlertSnackbar } from '@yardstik/core.components';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash';
import AlertSnackbar from './components/AlertSnackbar';
import { AlertProps } from './components/AlertSnackbar/alertSnackbarInterface';
import RouterLinks from './Router';
import { RootState } from './redux/store';
import { setNotification } from './redux/notification/notificationSlice';

const processEnv = process.env || {};
const darklyClientId = processEnv.REACT_APP_DARKLY_CLIENT_ID || '';

const context = {
  kind: 'user',
  key: ' 00000'
};

function App(): JSX.Element {
  const dispatch = useDispatch();

  const error = useSelector(state => get(state, 'controls.error', {})) as AlertProps;
  const notification = useSelector((state: RootState) => state.notification);

  const handleNotification = (payload: { severity: string; text: string }) => {
    dispatch(setNotification(payload));
  };

  return (
    <div className="App">
      <RouterLinks />
      {error && error.status && <AlertSnackbar error={error} />}
      {notification.severity && <YSAlertSnackbar notification={notification} setNotification={handleNotification} />}
    </div>
  );
}

export default withLDProvider({
  clientSideID: darklyClientId,
  context: context
})(App);
