import { setFormSections } from "./reducer";

export const reOrderPayFirst = (
  formSections: { steps?: any[] } = {},
  dispatch: any,
  skipEmailVerification = false
): void => {
  const { steps = [] } = formSections;

  if (steps.length > 0) {
    let newSteps = steps.slice();
    const paymentStep = newSteps.filter((step) => step.formName === 'payment');
    // check this; was throwing an error
    newSteps =
      newSteps.length > 0 ?
        newSteps.filter((step) => step.formName !== 'payment') : [];
    if (!skipEmailVerification) {
      newSteps.splice(2, 0, paymentStep[0]);
    } else {
      newSteps.unshift(paymentStep[0]);
    }
    dispatch(setFormSections({ ...formSections, steps: newSteps }));
  }
};

export default reOrderPayFirst;
