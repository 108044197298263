import { useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { authServiceLogOut, isAuthTokenIssuedForCurrentUser, isAuthTokenIssuedForCurrentAudience } from '../../services/auth/authTokenService';
import { useAuth0 } from '../../contexts/auth0/auth0-context';


type TuseAuthHelper = {
    isAuthenicationError: (candidateId:string) => boolean
    handleAuthenicationError: () => void
}
  


export const useAuthHelper = ():TuseAuthHelper => {

  const { currentAuthenicationFlow, logout, isAuthenticated, setIsAuthenticated } = useAuth0();
  const history = useHistory();


  const isAuthenicationError =  useCallback((candidateId:string) => {

    if(!isAuthenticated){
      return false;
    }

    const isAuthenicatedAsCurrentUser = isAuthTokenIssuedForCurrentUser(candidateId, currentAuthenicationFlow);

    const isAuthenicatedForCurrentAudience = isAuthTokenIssuedForCurrentAudience(currentAuthenicationFlow);

    if(!isAuthenicatedAsCurrentUser || !isAuthenicatedForCurrentAudience){
      return true;
    }

    return false;

  }, []);


  const handleAuthenicationError =  useCallback(() => {

    switch(currentAuthenicationFlow){
    case 'REDIRECT':
      logout({logoutParams:{returnTo: window.location.href}});
      setIsAuthenticated(false);
      break;
    case 'EMBEDDED':
      authServiceLogOut(currentAuthenicationFlow);
      setIsAuthenticated(false);
      history.go(0);
      break;
    default:
      console.log('handleAuthenicationError: currentAuthenicationFlow not recognised');
      break;
    } 

  }, []);




  return { isAuthenicationError, handleAuthenicationError  };
};
