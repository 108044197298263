import { Box, Grid } from '@mui/material';

import { Notes } from './Notes';
import { Support } from './Support';
import { Documents } from './Documents';
import { Invitation } from './Invitation';
import { ReportHeader } from './ReportHeader';
import { ConsumerReport } from './ConsumerReport';
import { TrainingResults } from './TrainingResults';
import { ReportProblemModal } from './ReportProblemModal';
import { IdentityVerification } from './IdentityVerification';

import { ReportViewProvider } from './index.context';

function ReportView(): JSX.Element {
  return (
    <ReportViewProvider>
      <Box
        sx={{
          p: '20px',
          display: 'flex',
          margin: '0 auto',
          maxWidth: '960px',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <ReportHeader />
          </Grid>

          <Grid item xs={12}>
            <Notes />
          </Grid>

          <Grid item xs={12}>
            <Invitation />
          </Grid>

          <Grid item xs={12}>
            <IdentityVerification />
          </Grid>

          <Grid item xs={12}>
            <ConsumerReport />
          </Grid>

          <Grid item xs={12}>
            <TrainingResults />
          </Grid>

          <Grid item xs={12}>
            <Documents />
          </Grid>

          <Grid item xs={12}>
            <Support />
          </Grid>

          <ReportProblemModal />
        </Grid>
      </Box>
    </ReportViewProvider>
  );
}

export default ReportView;
