import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Loader } from '@yardstik/core.components';
import { getCourses } from "../../redux/courses/actions";
import { CoursesStateType } from "../../redux/courses/reducer";
import LoadingError from '../LoadingError';
import CourseViewer from '../CourseViewer';
import { CandidateCourse } from '../../services/api/courses';

const Courses = (props: any): JSX.Element => {

  // Get candidate id from params
  const params = get(props, 'match.params', {});
  const { candidate_id: candidateId = '' } = params;
  
  // Get Redux store data
  const reportId = useSelector((state) => get(state, 'candidate_application.application.report_id', null));
  const courses = useSelector((state: { courses: CoursesStateType } ) => state.courses.courses);
  const getCoursesState = useSelector((state: { courses: CoursesStateType }) => state.courses.getCoursesState);
  const updateCoursesState = useSelector((state: { courses: CoursesStateType }) => state.courses.updateCoursesState);
	
  // For MVP assumption of only one course
  const [currentCourse, setCurrentCourse] = useState<null | CandidateCourse >(null);

  // Use hook to get dispatch to relevant store
  const dispatch = useDispatch();

  // Get courses when candidate id and report id are available
  useEffect( () => {
    if (candidateId && reportId){
      getCourses(candidateId, reportId, dispatch);
    }
  }, [candidateId, reportId, dispatch]);

  useEffect(() => {
    if (getCoursesState === 'READY') {
      // For MVP assumption of only one course. To be handled by course stepper in enhanced version.
      setCurrentCourse(courses[0]);
    }
  }, [getCoursesState]);

  // checks that you reached the route from the PII stepper. Otherwise, state page not found since unauthorized.
  if (!reportId) {
    return <div>Page not found.</div>;
  }
  
  if (getCoursesState === "LOADING") {
    return (
      <Loader
        logo='https://yardstik-assets.s3.amazonaws.com/logos/yardstik-black.svg'
        spinnerColor='primary'
      />
    );
  } 
  
  if ( (getCoursesState === 'ERROR' ) || !currentCourse) {
    return <LoadingError />;
  } 
	
  return ( 
    <div>
      <CourseViewer candidateCourse={currentCourse} updateCoursesState={updateCoursesState} />
    </div>
  );
	
};

export default Courses;
