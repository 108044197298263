import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Header } from '@yardstik/core.components';

import { RootState } from '../../../redux/store';

import { Screenings } from './Screenings';
import { InfoRequestAlert } from './InfoRequestAlert';

export function ConsumerReport() {
  const { report_screenings: reportScreenings = [], governance_type } = useSelector(
    (state: RootState) => state.reports.reportDetail ?? {}
  );

  if (!reportScreenings.length || governance_type === 'non_fcra') return <React.Fragment />;

  return (
    <Box sx={{ mt: 4 }}>
      <Header.Root>
        <Header.Head>
          <Header.Title title="Consumer Report" />
        </Header.Head>
      </Header.Root>

      <InfoRequestAlert />

      <Screenings />
    </Box>
  );
}
