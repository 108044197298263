import { combineReducers } from 'redux';
import accountReducer from './candidateApply/account/reducer';
import adcValuesReducer from './candidateApply/adcValues/reducer';
import applicationReducer from './candidateApply/application/reducer';
import candidateReducer from './candidateApply/candidate/reducer';
import controlsReducer from './candidateApply/controls/reducer';
import formSectionsReducer from './candidateApply/formSections/reducer';
import formValuesReducer from './candidateApply/formValues/reducer';
import legalDocumentsReducer from './candidateApply/legalDocs/reducer';
import formStylesReducer from './candidateApply/styles/reducer';
import candidates from './candidates/reducer';
import controls from './controls/reducer';
import courses from './courses/reducer';
import reports from './reports/reducer';
import documentVerify from './documentVerify/documentVerifySlice';
import screeningUpload from './screeningUpload/reducer';
import auth from './auth/reducer';
import launchDarkly from './launchDarkly/launchDarklySlice';
import notification from './notification/notificationSlice';
import { identityVerificationApi } from './identityVerification/requests';
import feedback from './candidateApply/feedback/reducer';
import { notesApi } from './notes/requests';
import { infoRequestApi } from './infoRequest/requests';
import { documentsApi } from './documents/requests';
import { reportsApi } from './reports/requests';

const candidate_application = combineReducers({
  account: accountReducer,
  application: applicationReducer,
  candidate: candidateReducer,
  controls: controlsReducer,
  feedback,
  formSections: formSectionsReducer,
  formValues: formValuesReducer,
  legalDocuments: legalDocumentsReducer,
  styles: formStylesReducer
});

export default combineReducers({
  candidates,
  candidate_application,
  controls,
  courses,
  reports,
  documentVerify,
  screening_upload: screeningUpload,
  auth,
  adcValues: adcValuesReducer,
  launchDarkly,
  notification,
  [notesApi.reducerPath]: notesApi.reducer,
  [infoRequestApi.reducerPath]: infoRequestApi.reducer,
  [identityVerificationApi.reducerPath]: identityVerificationApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer
});
