import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../services/api/rtk-query';
import { NotesType } from '../types';

type getNotesType = {
  noteableId: string;
  candidateId: string;
  noteableType: string;
};

type getNotesResponseType = {
  data: NotesType[];
  meta: {
    page: number;
    per_page: number;
    total_count: number;
    total_pages: number;
  };
};

export const notesApi = createApi({
  baseQuery,
  reducerPath: 'notesApi',
  endpoints: builder => ({
    getNotes: builder.query<getNotesResponseType, getNotesType>({
      query: ({ noteableId, noteableType, candidateId }) => ({
        url: `candidate-profile/candidates/${candidateId}/notes`,
        params: {
          noteable_id: noteableId,
          noteable_type: noteableType
        },
        headers: { 'candidate-id': candidateId }
      })
    })
  })
});

export const { useGetNotesQuery } = notesApi;
