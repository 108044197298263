import { Box, Link as MuiLink, styled, Typography } from '@mui/material';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  marginTop: '120px',
  maxWidth: '640px',
  marginBottom: '100px',
  background: '#fff',
  borderRadius: '6px',
  boxShadow: '0px 3px 6px rgb(0 0 0 / 20%)',
  padding: '20px',
});

export const Link = styled(MuiLink)({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
});

export const BodyText = styled(Typography)({
  lineHeight: '1.8',
});
