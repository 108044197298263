import { get } from 'lodash';

type ResultsType = {
  documents: {
    [n: string]: unknown
  },
  facematch: {
    [n: string]: unknown
  }
}

const splitByDocumentAndFacematch = (input:any) => {

  const results:ResultsType = {
    documents: {},
    facematch: {}
  };
  
  try {
    const objKeys = Object.keys(input);
    objKeys.forEach(k => {
    // documents
      if (get(input, `${k}[0].model.type`)) {
        results.documents = {
          ...results.documents,
          [k]: input[k]
        }; 
      }
      // facematch

      // confidence could be 0, we need to allow that to be a valid value
      // and check against undefined for when the key isn't there
      const confidence = get(input, `${k}[0].confidence`, undefined);
      if (confidence !== undefined) {
        results.facematch = {
          ...results.facematch,
          [k]: input[k]
        }; 
      }
    });
    return results;  
  } catch (error) {
    return results;
  }
};

export default splitByDocumentAndFacematch;
