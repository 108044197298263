
import { AnyAction } from 'redux';
import {SET_CONTROLS_ERROR, SET_PAGE_LOADING} from './actionTypes';   


// Initial State
const initialState = {
  loading: false,
  loadingError: false,
  error: {
    status: '',
    message: ''
  }
};

// Reducer
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case SET_CONTROLS_ERROR:
    return {
      ...state,
      error: action.payload,
    };
  case SET_PAGE_LOADING:
    return {
      ...state,
      loading: action.payload,
    };   
  default:
    return state;    
  }   
};
