import React from 'react';
import { Box, Grid } from '@mui/material';
import { Screening } from '@yardstik/core.components';
import { useScreening } from './index.hook';
import { useReportView } from '../../index.context';

export function Screenings() {
  const { forceOpenAccordions } = useReportView();
  const { reportUrl, candidate, reportScreenings, hasScreenings, isScreenDetailsEnabled } = useScreening();

  if (!hasScreenings) return <React.Fragment />;

  return (
    <React.Fragment>
      {reportScreenings.map((screen: any, index: number) => (
        <Grid key={index} item xs={12} data-testid="screening-list">
          <Box sx={{ mt: '12px', mb: index === reportScreenings.length - 1 ? 0 : 3 }}>
            <Screening
              screen={screen}
              candidateProfileView
              candidate={candidate}
              reportURL={reportUrl}
              showSearchDetails={true}
              forceOpenAccordions={forceOpenAccordions}
              searchDetailsEnabled={isScreenDetailsEnabled(screen.name)}
            />
          </Box>
        </Grid>
      ))}
    </React.Fragment>
  );
}
