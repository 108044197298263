import { AnyAction } from 'redux';
import { SET_CANDIDATE_DATA } from '../actionTypes';
import { Candidate } from '../../../services/api/candidates';

const initialState: Candidate | {} = {};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case SET_CANDIDATE_DATA:
    return {
      ...state,
      ...action.payload,
    };
  default:
    return state;
  }
};
