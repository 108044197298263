import { useSelector } from 'react-redux';
import { get } from 'lodash';
import ReportProblemModal from './../ReportProblemModal';
import { createDispute } from '../../services/api/dispute';
import './styles.scss';

interface ReportProblemData {
  reason: string;
  disputed_information: string;
  report_id: string;
  files?: Array<object>;
}

export type ReportProblemProps = {
  isOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  monitorRecordId?: string;
  candidateId?: string;
  accountName: string;
  accountSupportMail: string;
  refetchPageInfo?: () => void;
};

function ReportProblem({
  isOpen = false,
  monitorRecordId = '',
  candidateId,
  accountName = '',
  accountSupportMail = '',
  setModalOpen,
  refetchPageInfo
}: ReportProblemProps): JSX.Element {
  const reportCandidateId = useSelector(state => get(state, 'reports.reportDetail.candidate_id', ''));

  const currentCandidateId = candidateId || reportCandidateId;

  const reportId = useSelector(state => get(state, 'reports.reportDetail.id', ''));

  const reportProblemValues = useSelector(state => get(state, 'report_problem.data', {})) as ReportProblemData;

  const submitForm = () => {
    const { disputed_information = '', reason = '', files = [] } = reportProblemValues;
    const values: ReportProblemData = {
      disputed_information,
      reason,
      files,
      report_id: reportId
    };
    createDispute(currentCandidateId, values);
  };

  return (
    <ReportProblemModal
      open={isOpen}
      onSubmit={submitForm}
      setModalOpen={setModalOpen}
      candidateId={currentCandidateId}
      reportId={reportId}
      monitorRecordId={monitorRecordId}
      accountName={accountName}
      accountSupportMail={accountSupportMail}
      data-testid="reportProblemModal"
      refetchPageInfo={refetchPageInfo}
    />
  );
}

export default ReportProblem;
