import {IRecordHits } from '../CandidateDetails/candidateUtils';

export const getMappedHits = (hits: IRecordHits) => {
  const mappedHits = hits.map((hitItem) => {
    return hitItem.map((hitDataItem) => {
      return {
        label: hitDataItem.label,
        value: hitDataItem.value,
        presenterType: hitDataItem?.presenter?.alert || '',
      };
    });
  });
  return mappedHits; 
};
