/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { get, findIndex } from 'lodash';
import { FormDSLScreening, FormElements } from '@yardstik/core.components';
import {
  CandidateApplyNextStep,
  CandidateToSend,
  CandidateApplyDefaultDataCandidateSubmit,
  CandidateApplyCandidateAddress
} from './candidateApplyInterfaces';
import { incrementStep, setStep } from '../../redux/candidateApply/controls/actions';
import { incrementAdcTrigger } from '../../redux/candidateApply/adcValues/actions';

export const parseCandidateSubmit = (
  formName: string,
  candidateApplication: any
): CandidateApplyDefaultDataCandidateSubmit => {
  const { candidate = {}, formValues = {}, application: { report_id: reportId = '' } = {} } = candidateApplication;
  let candidateToSend: CandidateApplyDefaultDataCandidateSubmit = {};
  candidateToSend.email = get(candidate, 'email', '');
  // in order to enable re-ordering of steps before dataCollection we need
  // to allow patching the candidate without middle name
  if (formName !== 'dataCollection') {
    candidateToSend = {
      ...candidateToSend,
      skip_middle_name_validation: true
    };
  }

  if (formName === 'dataCollection') {
    const dataCollectionData = formValues.dataCollection;
    const destroyAddresses = get(formValues, 'destroyAddresses', []);
    const {
      first_name = '',
      middle_name = '',
      last_name = '',
      ssn = '',
      phone = '',
      date_of_birth = '',
      no_middle_name = '',
      addresses = [],
      guardian_email = '',
      guardian_first_name = '',
      guardian_last_name = '',
      copy_requested = false
    } = dataCollectionData;

    // setting destroyed addresses to they're removed from DB
    const addressesToSend = setDestroyedAddresses(addresses, destroyAddresses);

    candidateToSend = {
      ...candidateToSend,
      first_name,
      middle_name,
      last_name,
      ssn,
      phone,
      date_of_birth,
      no_middle_name,
      addresses: addressesToSend,
      guardian: {
        email: guardian_email,
        first_name: guardian_first_name,
        last_name: guardian_last_name
      },
      copy_requested,
      report_id: reportId
    };
  } else if (formName === 'InstantDriving') {
    candidateToSend = {
      ...candidateToSend,
      ...formValues[formName],
      files: undefined
    };
  } else if (formName === 'legalDocs') {
    const esignature = get(candidateApplication, 'formValues.legalDocs.esignature');

    if (!esignature) {
      return candidateToSend;
    }

    const legalDocuments = {};

    const legalDocumentsGroups = get(candidateApplication, 'application.workflow_steps.legal_documents', []);

    legalDocumentsGroups.forEach((group: any) => {
      group.components.forEach((legalDoc: any) => {
        const legalDocId = legalDoc.id;
        legalDocuments[legalDocId] = esignature;
      });
    });

    candidateToSend = {
      ...candidateToSend,
      signed_documents: {
        report_id: reportId ? reportId : '',
        legal_documents: legalDocuments
      }
    };
  } else if (formName === 'preLegalDocs') {
    const preLegalDocs = get(candidateApplication, 'formValues.preLegalDocs');
    const legalDocumentsGroups = get(candidateApplication, 'application.workflow_steps.pre_legal_documents', []);

    const preLegalSigned = Object.keys(preLegalDocs);

    const legalDocsToSign = legalDocumentsGroups
      .map((group: any) => group.components)
      .flat()
      .filter((legalDoc: any) => preLegalSigned.includes(legalDoc.name));

    if (!legalDocsToSign.length) return candidateToSend;

    const legalDocuments = {};

    legalDocsToSign.forEach((legalDoc: any) => {
      const legalDocId = legalDoc.id;
      legalDocuments[legalDocId] = '';
    });

    candidateToSend = {
      ...candidateToSend,
      signed_documents: {
        report_id: reportId ? reportId : '',
        legal_documents: legalDocuments
      }
    };
  } else {
    candidateToSend = {
      ...candidateToSend,
      additional_data: {
        [formName]: {
          ...formValues[formName]
        }
      }
    };
  }
  return candidateToSend;
};

export const getRemovedAddressId = (value: Array<CandidateApplyCandidateAddress>, index: number): string => {
  const addressData = value[index] || null;
  if (addressData) {
    const { id = '' } = addressData;
    return id;
  }

  return '';
};

// when an address gets removed from the candidate address array we need to mark it as destroy so it gets deleted from backend on save
export const setDestroyedAddresses = (
  addresses: Array<CandidateApplyCandidateAddress>,
  destroyAddresses: Array<string>
) => {
  if (destroyAddresses.length > 0) {
    const destroyedAddresses = destroyAddresses.map(address => {
      return { id: address, destroy: true };
    });
    return Array.isArray(addresses) ? addresses.concat(destroyedAddresses) : destroyedAddresses;
  }

  return addresses;
};

// on next step determine which if any steps should be skipped
export const onNextStep = (
  currentStep: number,
  formSectionSteps: Array<FormElements>,
  paid: boolean,
  pii: boolean,
  paidBy: 'candidate' | 'account',
  screenings: Array<FormDSLScreening>,
  dispatch: any
) => {
  const currentStepData = formSectionSteps[currentStep] || {};
  const currentStepId = get(currentStepData, 'id');

  // if it is the landing step you always just increment the step
  if (currentStepId === 'landing') {
    incrementStep(dispatch);
  } else {
    skipToStep(currentStep, formSectionSteps, paid, pii, paidBy, screenings, dispatch);
  }
};

// checking what step to increment to
export const skipToStep = (
  currentStep: number,
  formSectionSteps: Array<FormElements>,
  paid: boolean,
  pii: boolean,
  paidBy: 'candidate' | 'account',
  screenings: Array<FormDSLScreening>,
  dispatch: any
) => {
  const nextStep = formSectionSteps[currentStep + 1] || false;
  // if pii and payment (if payment is required) is completed skip to success page

  if (nextStep) {
    // al info is completed to take to success step
    if (allInformationCompleted(paid, pii, paidBy)) {
      const successStep = formSectionSteps.findIndex(step => step.formName === 'success');
      setStep(successStep, dispatch);
    } else if (isNextStepPayment(nextStep) && paid) {
      // if next step is payment but is already paid skip the payment step
      setStep(currentStep + 2, dispatch);
    } else if (isNextStepPii(nextStep, screenings) && pii) {
      // if the next step is the data collection step but the pii has already been completed skip to correct step
      skipPii(formSectionSteps, paid, paidBy, dispatch);
    } else {
      incrementStep(dispatch);
    }
  }
};

export const isNextStepPayment = (nextStep: CandidateApplyNextStep) => {
  const nextStepId = nextStep.formName || '';

  if (nextStepId === 'payment') {
    return true;
  }

  return false;
};

// checking to see if the next step is a screening "pii" step for the purpose of skipping pii if they have already completed it.
export const isNextStepPii = (nextStep: CandidateApplyNextStep, screenings: Array<FormDSLScreening>) => {
  const nextStepId = nextStep.formName || '';

  const nextStepIsScreening = screenings.filter(screening => {
    return screening.name === nextStepId;
  });

  if ((nextStepIsScreening && nextStepIsScreening.length > 0) || nextStepId === 'dataCollection') {
    return true;
  }

  return false;
};

export const isPaymentCompleted = (paid = false, paidBy: 'account' | 'candidate') => {
  if (paidBy === 'candidate' && paid) {
    return true;
  } else if (paidBy === 'account') {
    return true;
  }
  return false;
};

export const hasPaymentStep = (formSectionSteps: Array<FormElements>) => {
  if (Array.isArray(formSectionSteps)) {
    if (formSectionSteps.findIndex(step => step.formName === 'payment') >= 0) {
      return true;
    }
    return false;
  }

  return false;
};

export const allInformationCompleted = (paid: boolean, pii: boolean, paidBy: 'account' | 'candidate') => {
  if (isPaymentCompleted(paid, paidBy) && pii) {
    return true;
  }
  return false;
};

export const skipPii = (
  formSectionSteps: Array<FormElements>,
  paid: boolean,
  paidBy: 'account' | 'candidate',
  dispatch
) => {
  if (hasPaymentStep(formSectionSteps) && !isPaymentCompleted(paid, paidBy)) {
    if (Array.isArray(formSectionSteps)) {
      const paymentStep = formSectionSteps.findIndex(step => step.formName === 'payment');

      setStep(paymentStep, dispatch);
    }
  } else {
    if (Array.isArray(formSectionSteps)) {
      const successStep = formSectionSteps.findIndex(step => step.formName === 'success');
      successStep >= 0 ? setStep(successStep, dispatch) : setStep(formSectionSteps.length - 1, dispatch);
    }
  }
};

export const updateDefaultDataFromRes = (
  candidateToSend: CandidateToSend,
  res: { data: CandidateApplyDefaultDataCandidateSubmit },
  dispatch: any
) => {
  const ssn = get(candidateToSend, 'ssn', '');
  const copy_requested = get(candidateToSend, 'copy_requested', false);
  const candidateData = get(res, 'data');
  const {
    first_name = '',
    middle_name = '',
    last_name = '',
    phone = '',
    date_of_birth = '',
    no_middle_name = '',
    addresses = [],
    guardian = {}
  } = candidateData;

  // Here we need to replace the defaultData in the redux store with the data from our response.
  // This is because we need the address to have their address id to prevent duplicate entries and also allow for deletion
  // when the res comes back it doesn't have the ssn info provided so if we update he redux data the ssn value will be cleared out
  // this adds it back in the form values redux store so if you hit back it repopulates the ssn info
  const defaultCandidateDataWithSSN = {
    first_name,
    middle_name,
    last_name,
    phone,
    date_of_birth,
    addresses,
    no_middle_name,
    guardian_email: guardian.email,
    guardian_first_name: guardian.first_name,
    guardian_last_name: guardian.last_name,
    ssn: ssn,
    ssn2: ssn,
    copy_requested
  };
  dispatch({
    type: 'SET_CANDIDATE_FORM_VALUES',
    payload: {
      dataCollection: {
        ...defaultCandidateDataWithSSN
      }
    }
  });
  incrementAdcTrigger(dispatch);
};

export const getStepIncrementByName = (stepName: string, formSteps: Array<FormElements>) => {
  const stepIndex = findIndex(formSteps, step => step.formName === stepName);

  // if step index exists return step index. If not return 0;
  if (stepIndex >= 0) {
    return stepIndex;
  } else {
    return 0;
  }
};

export default parseCandidateSubmit;
