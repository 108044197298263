import { AnyAction } from 'redux';
import { CandidateCourse } from '../../services/api/courses';
import {
  GET_COURSES,
  GET_COURSES_STATE,
  GET_COURSES_ERROR,
  UPDATE_COURSES,
  UPDATE_COURSES_STATE,
  UPDATE_COURSES_ERROR,
} from './actionTypes';

export type CoursesStateType = {
  courses: CandidateCourse[],
  getCoursesState: "LOADING" | "READY" | "ERROR",
  getCoursesError: false | Error,
  updateCoursesState: "LOADING" | "READY" | "ERROR",
  updateCoursesError: false | Error,
}

// Initial State
const initialState: CoursesStateType = {
  courses: [],
  getCoursesState: "LOADING",
  getCoursesError: false,
  updateCoursesState: "LOADING",
  updateCoursesError: false,
};

// Reducer
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case GET_COURSES_STATE:
    return {
      ...state,
      getCoursesState: action.payload,
    };
  case GET_COURSES_ERROR:
    return {
      ...state,
      getCoursesError: action.payload,
    };
  case GET_COURSES: {
    return {
      ...state,
      courses: action.payload,
    };
  }
  case UPDATE_COURSES_STATE:
    return {
      ...state,
      updateCoursesState: action.payload,
    };
  case UPDATE_COURSES_ERROR:
    return {
      ...state,
      updateCoursesError: action.payload,
    };
  case UPDATE_COURSES: {
    const allCourses = [...state.courses];
    const updatedCourse: Partial<CandidateCourse> & { id: string } = action.payload;
    for (let i = 0; i < allCourses.length; i++) {
      const current = allCourses[i];
      if (current.candidate_course_id === updatedCourse.id){
        if (updatedCourse.status) {
          current.status = updatedCourse.status;
        }
        if (updatedCourse.score) {
          current.score = updatedCourse.score;
        }
        if (updatedCourse.completed_at) {
          current.completed_at = updatedCourse.completed_at;
        }
      }
    }
    return {
      ...state,
      courses: allCourses,
    };
  }
  default:
    return state;
  }
};
