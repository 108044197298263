import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Header, NoteCard } from '@yardstik/core.components';

import { RootState } from '../../../redux/store';
import { useGetNotesQuery } from '../../../redux/notes/requests';

export function Notes() {
  const { reportId, candidateId } = useSelector((state: RootState) => ({
    reportId: state.reports?.reportDetail?.id ?? '',
    candidateId: state.reports.reportDetail.candidate.id ?? ''
  }));

  const { data: notes } = useGetNotesQuery(
    { candidateId, noteableType: 'Report', noteableId: reportId },
    { skip: !reportId || !candidateId }
  );

  if (!notes?.data?.length) return <React.Fragment />;

  return (
    <Box sx={{ mt: 4 }}>
      <Header.Root mb="12px">
        <Header.Head>
          <Header.Title title="Notes" />
        </Header.Head>
      </Header.Root>

      <NoteCard listNotesOnly notes={notes.data} />
    </Box>
  );
}
