import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
  },
});
export const attachAuthToken = (accessToken: string | null) => {
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};
export default api;
