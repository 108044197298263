import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { withLDConsumer, useLDClient } from 'launchdarkly-react-client-sdk';
import CandidateHome from './components/CandidateHome';
import CandidateDetails from './components/CandidateDetails';
import ReportView from './components/ReportView';
import ReportViewInfoRequested from './components/ReportViewInfoRequested';
import ReportViewScreeningUpload from './components/ReportViewScreeningUpload';
import Courses from './components/Courses';
import InitialReportApply from './components/InitialReportApply';
import AlertSnackbar from './components/AlertSnackbar';
import Auth0Callback from './components/Auth0Callback';
import PrivateRoute from './routes/PrivateRoute';
import ApplyRoute from './routes/ApplyRoute';
import CallbackRoute from './routes/CallbackRoute';
import { AlertProps } from './components/AlertSnackbar/alertSnackbarInterface';
import CandidateApplyIDVerificationError from './components/CandidateApplyIDVerificationError';

import { updateByAccountId } from './redux/launchDarkly/launchDarklySlice';
import { useGoogleAnalytics } from './hooks/google-analytics/useGoogleAnalytics';

const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS || '';

const NotFound = () => <div>Page not found.</div>;

const Router = (props: any): JSX.Element => {
  const ldClient = useLDClient();
  const location = useLocation();
  const { logAnalyticsPageView } = useGoogleAnalytics(googleAnalyticsId);
  const error = useSelector(state => get(state, 'controls.error', {})) as AlertProps;

  const dispatch = useDispatch();
  const [ldLoading, setLdLoading] = useState(true);
  //If no Account
  const accountId = useSelector(state => get(state, 'auth.accountId', ''));

  useEffect(() => {
    if (ldClient && ldLoading) {
      ldClient
        .identify({ key: 'context-key-profile' })
        .then(res => {
          dispatch(updateByAccountId(res));
          setLdLoading(false);
        })
        .catch(() => setLdLoading(false));
    }
  }, [ldClient, ldLoading, accountId, dispatch]);

  // set up a page view for each new path / route
  useEffect(() => {
    const pageUrl = location.pathname + location.search;

    logAnalyticsPageView(pageUrl);
  }, [location]);

  if (ldLoading) {
    return <></>;
  }

  return (
    <>
      <Switch>
        <PrivateRoute
          path="/candidate/apply/id-verification-error"
          exact
          component={CandidateApplyIDVerificationError}
        />
        <PrivateRoute path="/candidates/:candidate_id/reports/:report_id" exact component={ReportView} />
        <PrivateRoute
          path="/candidates/:candidate_id/reports/:report_id/info-requested"
          exact
          component={ReportViewInfoRequested}
        />
        <PrivateRoute
          path="/candidates/:candidate_id/reports/:report_id/screening/:screening_id/upload"
          exact
          component={ReportViewScreeningUpload}
        />
        <ApplyRoute path="/candidates/:candidate_id/apply" exact component={InitialReportApply} />
        <CallbackRoute path="/callback" exact component={Auth0Callback} />
        <Route path="/candidates/:candidate_id/courses" exact component={Courses} />
        <PrivateRoute path="/candidates/:candidate_id/details" exact component={CandidateDetails} />
        <PrivateRoute path="/" component={CandidateHome} exact />
        <Route component={NotFound} />
      </Switch>
      {error && error.status && <AlertSnackbar error={error} />}
    </>
  );
};

export default withLDConsumer()(Router);
