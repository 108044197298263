/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, configureStore, createImmutableStateInvariantMiddleware, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducerWrapper from './reducer';

import { notesApi } from './notes/requests';
import { infoRequestApi } from './infoRequest/requests';
import { identityVerificationApi } from './identityVerification/requests';
import { documentsApi } from './documents/requests';
import { reportsApi } from './reports/requests';

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
  ignoredPaths: ['payload.propsSent.incrementKey']
});

const middleware = [immutableInvariantMiddleware, thunk]
  .concat(identityVerificationApi.middleware)
  .concat(infoRequestApi.middleware)
  .concat(notesApi.middleware)
  .concat(documentsApi.middleware)
  .concat(reportsApi.middleware);

const store = configureStore({
  devTools: {
    name: 'Yardstik Profile',
    maxAge: 200,
    trace: true,
    traceLimit: 20
  },
  reducer: rootReducerWrapper,
  middleware
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
