import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from '@yardstik/core.components';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash';
import { Route } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import LoadingError from '../components/LoadingError';
import { getApplyDetails } from '../services/api/reports';
import { useAuth0 } from '../contexts/auth0/auth0-context';
import { setAccount } from '../redux/candidateApply/feedback/reducer';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string');

const logoBaseURL = process.env.REACT_APP_API_URL;
const LAUNCH_DARKLY_TIMEOUT = 1500; // millisecond

type ApplyDetails = {
  id: string;
  candidate?: {
    email?: string;
    phone?: string;
    phone_country_code?: string;
    phone_without_country_code?: string;
  };
  account: {
    id: string;
    account_name: string;
    logo: string;
    styles: {
      invitation: any;
      primary_color: string;
      secondary_color: string;
    };
  };
};

type auth0Payload = {
  appState: {
    targetUrl: string;
  };
  audience: string;
  primaryColor?: string;
  logo?: string;
  title: string;
  login_hint?: string;
  lockInput?: boolean;
  client_id?: string;
  country_code?: string;
};

const ApplyRoute = (props: any): JSX.Element => {
  const { component: Component, path } = props;
  const dispatch = useDispatch();

  // Get auth0 context
  const { isLoading, loginWithRedirect, user, isAuthenticated, logout } = useAuth0();

  // Set up launchdarkly
  const ldClient = useLDClient();

  // Set up local state variables
  const [applyDetails, setApplyDetails] = useState<ApplyDetails | null>(null);
  const [applyDetailsLoading, setApplyDetailsLoading] = useState<boolean>(true);
  const [applyDetailsLoadingError, setApplyDetailsLoadingError] = useState<boolean>(false);
  const [applyWithAuth0, setApplyWithAuth0] = useState<boolean>(false);
  const [applyWithAuthentication, setApplyWithAuthentication] = useState<boolean>(true);
  const [applyWithAuth0Loading, setApplyWithAuth0Loading] = useState<boolean>(true);
  const [payFirst, setPayFirst] = useState<boolean>(false);
  const [payFirstLoading, setPayFirstLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useState({ account_package_id: '', report_id: '', auth_type: 'email' });

  const [userEmail, setUserEmail] = useState<string | null>(null);

  const search = get(props, 'location.search', '');

  // Setting user email to local state
  useEffect(() => {
    if (user) {
      setUserEmail(user?.email || null);
    }
  }, [user]);

  // Setting applyWithAuthenication false if applyWithAuth0 is true
  useEffect(() => {
    if (applyWithAuth0) {
      setApplyWithAuthentication(false);
    }
  }, [applyWithAuth0]);

  // setting user email on datadog
  useEffect(() => {
    if (isAuthenticated) {
      datadogRum.setUser({
        email: user?.email || null
      });
    }
  }, [isAuthenticated, user]);

  // getting search params and setting to local state
  useEffect(() => {
    const parsedSearchParams = search ? queryString.parse(search) : {};
    setSearchParams(parsedSearchParams);
  }, [search]);

  // Fetching apply details once params and search params have been identified
  // re-run when userEmail is set (i.e., after returning from auth0 login)
  useEffect(() => {
    const { report_id: reportId = '', auth_type: authType = 'email' } = searchParams;
    const params = get(props, 'computedMatch.params', {});
    const { candidate_id: candidateId = '' } = params;
    if (candidateId && reportId && authType) {
      fetchApplyDetails(reportId, candidateId, authType);
    }
  }, [searchParams, userEmail]);

  // Function to fetch apply details and set on state
  const fetchApplyDetails = async (reportId: string, candidateId: string, authType: string) => {
    await getApplyDetails(reportId, candidateId, authType)
      .then(res => {
        setApplyDetails(res);
        dispatch(setAccount(res.account));
        setApplyDetailsLoading(false);
      })
      .catch(() => {
        setApplyDetailsLoadingError(true);
        setApplyDetailsLoading(false);
      });
  };

  // checking for auth LD flag once accountId has been set on state
  useEffect(() => {
    const fetchData = async (accountId: string) => {
      if (ldClient) {
        try {
          const launchDarkly = ldClient.identify({ key: accountId });
          const timeout = new Promise<never>((_, reject) =>
            setTimeout(() => {
              reject(new Error('Launch Darkly Timeout'));
            }, LAUNCH_DARKLY_TIMEOUT)
          );
          const response = await Promise.race([launchDarkly, timeout]);
          setApplyWithAuth0(!!response.auth0_apply_route);
        } catch (e) {
          setApplyWithAuth0(false);
        } finally {
          setApplyWithAuth0Loading(false);
        }
      }
    };

    if (applyDetails) {
      const accountId = applyDetails?.account?.id || '';
      if (accountId) {
        fetchData(accountId);
      }
    }
  }, [ldClient, applyDetails]);

  // checking for payment LD flag once accountPackageId has been set on state
  useEffect(() => {
    const fetchPaymentData = async (accountPackageId: string) => {
      if (ldClient) {
        try {
          const launchDarkly = ldClient.identify({ key: accountPackageId });
          const timeout = new Promise<never>((_, reject) =>
            setTimeout(() => {
              reject(new Error('Launch Darkly Timeout'));
            }, LAUNCH_DARKLY_TIMEOUT)
          );
          const response = await Promise.race([launchDarkly, timeout]);
          setPayFirst(response['pay-first-process']);
        } catch (e) {
          setPayFirst(false);
        } finally {
          setPayFirstLoading(false);
        }
      }
    };
    const { account_package_id: accountPackageId = '' } = searchParams;
    if (accountPackageId) {
      fetchPaymentData(accountPackageId);
    }
  }, [ldClient, searchParams]);

  useEffect(() => {
    // Don't go to login page if auth0 is loading or user is authenticated already
    // Don't go to login page if still loading apply details or error getting account details
    // Don't go to login page if in the launch darkly half
    if (isLoading || isAuthenticated || applyDetailsLoading || applyDetailsLoadingError || !applyWithAuth0) {
      return;
    }
    let url = '';
    let primaryColor = '';
    let email = '';
    let phone;
    let phoneCountryCode = '';
    if (applyDetails) {
      url = applyDetails?.account?.logo || '';
      primaryColor = applyDetails?.account?.styles?.invitation?.primary_color || '';
      email = applyDetails?.candidate?.email || '';
      phone = applyDetails?.candidate?.phone_without_country_code || '';
      phoneCountryCode = applyDetails?.candidate?.phone_country_code || '';
    }
    // Set default payload for email auth
    const payload: auth0Payload = {
      appState: { targetUrl: '/' },
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      title: 'Verify Your Email',
      login_hint: email,
      lockInput: true
    };
    // if under SMS flow, reset payload for SMS
    if (!email && phone) {
      payload.title = 'Verify Your Phone Number';
      payload.login_hint = phone;
      payload.country_code = phoneCountryCode;
      payload.client_id = `${process.env.REACT_APP_AUTH0_CLIENT_SMS}`;
    }
    if (url) {
      payload.logo = `${logoBaseURL}${url}`;
    }
    if (primaryColor) {
      payload.primaryColor = primaryColor;
    }
    // New authorizationParams during migration to auth0 2
    loginWithRedirect({ authorizationParams: payload });
  }, [
    isLoading,
    isAuthenticated,
    applyWithAuth0,
    applyDetailsLoading,
    applyDetailsLoadingError,
    loginWithRedirect,
    applyDetails
  ]);

  const candidateEmail = applyDetails?.candidate?.email || null;
  const candidateSMS = applyDetails?.candidate?.phone || null;

  // Set the render to be equal to the component once the user is authenticated, or if we are not requiring auth0 login
  const render = (props: any) =>
    isAuthenticated === true || applyWithAuth0 === false ? (
      <Component
        applyWithAuthentication={applyWithAuthentication}
        applyWithAuth0={applyWithAuth0}
        applyWithSMS={!!candidateSMS}
        payFirst={payFirst}
        {...props}
      />
    ) : null;

  if (applyDetailsLoadingError) {
    return <LoadingError />;
  }

  // If the LD flag to require login is true, and auth0 is loading or the user is not authenticated
  if (
    applyDetailsLoading ||
    payFirstLoading ||
    applyWithAuth0Loading ||
    (applyWithAuth0 && (isLoading || !isAuthenticated || (candidateEmail === null && candidateSMS === null)))
  ) {
    return <Loader logo="https://yardstik-assets.s3.amazonaws.com/logos/yardstik-black.svg" spinnerColor="primary" />;
  }

  // Checks that email of logged in user matches email of candidate;
  // By locking the email in auth0 this should be true, but this is a safe guard.
  // Automatically logs you out and redirects
  // New logoutParams during migration to auth0 2
  if (applyWithAuth0 && userEmail && candidateEmail && userEmail !== candidateEmail) {
    logout({
      logoutParams: {
        returnTo: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}`
      }
    });
    return <LoadingError text="Incorrect Login." />;
  }

  return <Route path={path} render={render} />;
};

export default ApplyRoute;
