import api from './index';

type RemoveFilePayloadType = {
  screening_name: string,
  screening_file_group: string,
  report_id: string,
};

type RemoveFilePropType = {
  candidateId: string;
  mediaId: string;
  payload: RemoveFilePayloadType;
};

export const uploadFile = async (candidateId: string, payload: object): Promise<any> => api
  .post(`/candidates/${candidateId}/media`, payload)
  .then((response) => response.data);

export const removeFile = async ({ candidateId, mediaId, payload }: RemoveFilePropType): Promise<any> => api
  .delete(`/candidates/${candidateId}/media/${mediaId}`, { data: payload })
  .then((response) => response.data);

export const signedUploadFile = async (candidateId: string, payload: object): Promise<any> => api
  .post(`/candidate-profile/candidates/${candidateId}/media`, payload)
  .then((response) => response.data);

export const signedRemoveFile = async ({ candidateId, mediaId, payload }: RemoveFilePropType): Promise<any> => api
  .delete(`/candidate-profile/candidates/${candidateId}/media/${mediaId}`, { data: payload })
  .then((response) => response.data);
