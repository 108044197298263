import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../services/api/rtk-query';
import { DocumentsResponse, ReportType } from '../types';

export const reportsApi = createApi({
  baseQuery,
  reducerPath: 'reportsApi',
  endpoints: builder => ({
    getReport: builder.query<ReportType, { candidateId: string; reportId: string }>({
      query: ({ candidateId, reportId }) => ({
        url: `candidate-profile/candidates/${candidateId}/reports/${reportId}?search_details=true`
      })
    }),
    getReportDocuments: builder.query<DocumentsResponse, { candidateId: string; reportId: string }>({
      query: ({ candidateId, reportId }) => ({
        url: `candidate-profile/reports/${reportId}/documents`,
        headers: { 'candidate-id': candidateId }
      })
    })
  })
});

export const { useGetReportDocumentsQuery, useGetReportQuery } = reportsApi;
