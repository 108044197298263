import isValidDocument from './isValidDocument';

type ResultsType = {
  results: {
    [n: string]: unknown
  },
  errors: string[],
  errorFields: string[]
}

const getDocumentErrors = (input:any, regexString?: string) => {
  const returnObj:ResultsType = {
    results: {},
    errors: [],
    errorFields: []
  };
  try {
    const objKeys = Object.keys(input);
    objKeys.forEach((k:string) => {
      const r = isValidDocument(input[k], regexString);
      const { isValid, error } = r;
      returnObj.results[k] = r;
      returnObj.errors = error ? [...returnObj.errors, error] : returnObj.errors; 
      returnObj.errorFields = error ? [...returnObj.errorFields, k] : returnObj.errorFields;
    });
  } catch (error) {
    
  }
  
  return returnObj;
};

export default getDocumentErrors;
