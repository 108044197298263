const URL = process.env.REACT_APP_API_URL;

export const getLogoUrl = (logo: string) => {
  const check = /^https?:\/\//i;
  if (logo) {
    return check.test(logo) ? logo : `${URL}${logo}`;
  } 
  return '';
  
};
