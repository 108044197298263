import React, { useState, createContext, useContext, useEffect } from 'react';
import {
  Auth0Client,
  Auth0ClientOptions,
  createAuth0Client,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions
} from '@auth0/auth0-spa-js';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { attachAuthToken } from '../../services/api';
import { addAuthUser, setToken } from '../../redux/auth/actions';

import { authServiceIsAuthenticated, authServiceGetAccessToken } from '../../services/auth/authTokenService';


export type AppState = {
  returnTo?: string;
  [key: string]: any;
};

export interface ContextValueType {
  isAuthenticated?: boolean;
  user?: any;
  isLoading?: boolean;
  getIdTokenClaims?: () => Promise<IdToken | undefined>;
  loginWithRedirect?: (opts?: RedirectLoginOptions) => Promise<void>;
  logout?: (opts?: LogoutOptions) => void;
  apiToken: string | null;
}

// create the context
export const Auth0Context = createContext<ContextValueType | null>(null);
export const useAuth0: any = () => useContext(Auth0Context);

export const getRedirectUri = ():string => {
  const redirectUri = new URL('/callback', window.location.origin);
  const params = new URLSearchParams(window.location.search);
  // don't add the return_url query param if it is already included
  if (params.has('return_url')) {
    return redirectUri.toString();
  }
  // add the current url as query param to be used to redirect after authenticated
  if (!window.location.search.includes('code=') && window.location.pathname) {
    const returnUrl = window.location.href.replace(window.location.origin, '');
    redirectUri.searchParams.set('return_url', encodeURIComponent(returnUrl));
  }
  if (params.has('auth_type') && params.get('auth_type') === 'sms') {
    redirectUri.searchParams.set('auth_type', 'sms');
  }
  return redirectUri.toString();
};

export const getConfig = () => {
  const configEmail: Auth0ClientOptions = {
    domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`, //look here for cookie issue
    clientId: `${process.env.REACT_APP_AUTH0_CLIENT}`,
    cacheLocation: 'localstorage',
    authorizationParams: {
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: getRedirectUri()
    }
  };

  const configSMS: Auth0ClientOptions = {
    ...configEmail,
    clientId: `${process.env.REACT_APP_AUTH0_CLIENT_SMS}`
  };

  const params = new URLSearchParams(window.location.search);
  if (params.has('auth_type') && params.get('auth_type') === 'sms') {
    return configSMS;
  }
  return configEmail;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Auth0Provider = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [apiToken, setApiToken] = useState<string | null>(null);
  const [auth0Client, setAuth0Client] = useState<Auth0Client | null>(null);
  const [currentAuthenicationFlow, setCurrentAuthenicationFlow] = useState<string>('');

  const history = useHistory();

  React.useEffect(() => {
    createAuth0Client(getConfig()).then(client => setAuth0Client(client));
  }, []);

  // initialize the auth0 library
  const initializeAuth0 = React.useCallback(async (): Promise<void> => {
    if (!auth0Client) {
      return;
    }
    setIsLoading(true);

    // if the user has been redirected by auth0; apply this additional logic
    // determine if return_url has been included as a query parameter, and decode
    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
      try {
        await auth0Client.handleRedirectCallback();

        // re-directing to the return_url if the query param exists
        const params = new URLSearchParams(window.location.search);
        const returnUrl = decodeURIComponent(params.get('return_url') || '');
        if (returnUrl) {
          history.push(returnUrl);
        }
      } catch (e) {
        history.push('/');
        return;
      }
    }

    const isAuth0Authenticated = await auth0Client.isAuthenticated();

    let isAuthenticated: boolean;

    if (isAuth0Authenticated) {
      const user = await auth0Client.getUser();
      const apiToken = await auth0Client.getTokenSilently();

      setUser(user);
      if (user) {
        addAuthUser(user);
      }
      attachAuthToken(apiToken);
      setApiToken(apiToken);
      setToken(apiToken);
      setCurrentAuthenicationFlow('REDIRECT');
      isAuthenticated = isAuth0Authenticated;
    } else {
      if (authServiceIsAuthenticated()) {
        const apiToken = await authServiceGetAccessToken();
        if (apiToken) {
          attachAuthToken(apiToken);
          setApiToken(apiToken);
          setToken(apiToken);
          setCurrentAuthenicationFlow('EMBEDDED');
        }
        isAuthenticated = authServiceIsAuthenticated();
      } else {
        setUser(null);
        setApiToken(null);
        isAuthenticated = false;
      }
    }
    setIsAuthenticated(isAuthenticated);
    setIsLoading(false);
  }, [auth0Client, history]);

  useEffect(() => {
    initializeAuth0();
  }, [initializeAuth0]);

  const throwClientUndefinedError = (): never => {
    throw new console.error('Auth0 client undefined');
  };

  const getIdTokenClaims = async () => {
    if (!auth0Client) {
      return throwClientUndefinedError();
    }

    try {
      return await auth0Client?.getIdTokenClaims();
    } catch (error) {
      console.error('Unexpected error occurred while signing in', error);
    }
  };

  const logout = async (opts: LogoutOptions = {}): Promise<void> => {
    if (!auth0Client) {
      return throwClientUndefinedError();
    }

    try {
      return await auth0Client?.logout(opts);
    } catch (error) {
      console.error('Unexpected error occurred while signing in', error);
    }
  };
  const loginWithRedirect = async (opts?: RedirectLoginOptions): Promise<void> => {
    if (!auth0Client) {
      return throwClientUndefinedError();
    }

    try {
      return auth0Client?.loginWithRedirect(opts);
    } catch (error) {
      console.error('Unexpected error occurred while signing in', error);
    }
  };

  const contextValue = {
    isLoading,
    isAuthenticated,
    user,
    redirectUri: getRedirectUri(),
    getIdTokenClaims,
    loginWithRedirect,
    logout,
    setIsAuthenticated,
    currentAuthenicationFlow,
    apiToken,
    setApiToken
  };

  return <Auth0Context.Provider value={contextValue}>{props.children}</Auth0Context.Provider>;
};

export default Auth0Provider;

Auth0Provider.propTypes = {
  children: PropTypes.node.isRequired
};
