import { Alert as YSAlert } from '@yardstik/core.components';

export function InfoRequestSuccessAlert() {
  return (
    <YSAlert sx={{ my: '12px' }} severity="info" variant="standard">
      Thanks for providing the information we requested. We'll review it and let you know once your report is complete,
      or if we need anything else from you in the meantime.
    </YSAlert>
  );
}
